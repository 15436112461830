export const ResetIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.9998 6H15.9998V1M15.7091 14.3569C14.7772 15.7918 13.4099 16.8902 11.8079 17.4907C10.2059 18.0913 8.45336 18.1624 6.80791 17.6937C5.16246 17.225 3.71091 16.2413 2.66582 14.8867C1.62073 13.5321 1.03759 11.878 1.00176 10.1675C0.965926 8.45695 1.47903 6.78001 2.46648 5.38281C3.45392 3.98561 4.86334 2.942 6.48772 2.40479C8.1121 1.86757 9.86609 1.86499 11.4919 2.39795C13.1177 2.93091 14.5298 3.97095 15.5209 5.36556"
        stroke="#2E2E2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
