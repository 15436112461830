import { cn } from '@/utils'
export const SectionTranslateIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M7.05515 6.42846C6.7395 5.84572 6.0112 5.6292 5.42846 5.94485C4.84572 6.2605 4.6292 6.9888 4.94485 7.57154L7.05515 6.42846ZM5.75735 9.07154C6.073 9.65428 6.8013 9.8708 7.38404 9.55515C7.96678 9.2395 8.1833 8.5112 7.86765 7.92846L5.75735 9.07154ZM9.49265 10.9285C9.177 10.3457 8.4487 10.1292 7.86596 10.4448C7.28322 10.7605 7.0667 11.4888 7.38235 12.0715L9.49265 10.9285ZM9.00735 15.0715C9.323 15.6543 10.0513 15.8708 10.634 15.5552C11.2168 15.2395 11.4333 14.5112 11.1177 13.9285L9.00735 15.0715ZM12.7427 16.9285C12.427 16.3457 11.6987 16.1292 11.116 16.4448C10.5332 16.7605 10.3167 17.4888 10.6323 18.0715L12.7427 16.9285ZM4.94485 7.57154L5.75735 9.07154L7.86765 7.92846L7.05515 6.42846L4.94485 7.57154ZM7.38235 12.0715L9.00735 15.0715L11.1177 13.9285L9.49265 10.9285L7.38235 12.0715ZM10.6323 18.0715L11.4448 19.5715L13.5552 18.4285L12.7427 16.9285L10.6323 18.0715Z"
        fill="black"
      />
      <path
        d="M1.5 19L22.5 19"
        stroke="black"
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M16.5 19C16.5 17.6739 16.0259 16.4021 15.182 15.4645C14.3381 14.5268 13.1935 14 12 14C10.8065 14 9.66193 14.5268 8.81802 15.4645C7.97411 16.4021 7.5 17.6739 7.5 19"
        stroke="black"
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M4.0062 2.32213L8.46358 5.15864C8.69965 5.30887 8.68158 5.65921 8.4313 5.78435L3.5687 8.21565C3.31842 8.34079 3.0273 8.14505 3.04877 7.86604L3.45398 2.59823C3.47483 2.32726 3.77691 2.17622 4.0062 2.32213Z"
        fill="black"
      />
    </svg>
  )
}
