import { useMemo } from 'react'
import { useListNotes } from '@/services/hooks/template_attributes'

export const useDocPageNote = ({
  documentPageId,
  notesId,
}: {
  documentPageId: string
  notesId?: string
}) => {
  const notesTemplates = useListNotes(documentPageId)
  const notesTemplate = useMemo(() => {
    if (notesId) {
      return notesTemplates?.find(
        (template) =>
          template.document_page === documentPageId && template.id === notesId,
      )
    }

    return notesTemplates?.filter(
      (template) => template.document_page === documentPageId,
    )[0]
  }, [notesTemplates, documentPageId, notesId])

  return notesTemplate
}
