import { Button } from '@/components/ui/button'

import { useDocumentPageParams } from '@/pages/DocumentPage/hooks'
import { getDocument, getDocumentVersion } from '@/lib/api/client'
import { getLatestDocumentVersion } from '@/services/queries/document_versions'

export const CSVDownloadButton = () => {
  const { documentId, dv: selectedDocumentVersionId } = useDocumentPageParams()

  const onDownload = async () => {
    const latestDocument = await getDocument(documentId)

    const docVersion = selectedDocumentVersionId
      ? await getDocumentVersion(selectedDocumentVersionId)
      : await getLatestDocumentVersion({
          documentId: latestDocument.id as string,
        })

    try {
      const link = document.createElement('a')
      link.href = docVersion.bom_csv_url
      link.click()
    } catch (error) {
      console.error('Error downloading BOM:', error)
    }
  }

  return (
    <Button
      variant="ghost"
      className="flex items space-x-2"
      onClick={onDownload}
    >
      <span>Download BOM (.csv)</span>
    </Button>
  )
}
