import { useCallback, useEffect, useMemo } from 'react'
import { useDocumentState } from '@/state'
import { DocumentTemplate } from '../DocumentTemplate'
import { PartsTable } from '../PartsTable'
import { ToolsTable } from '../ToolsTable'
import type { DocumentPage, View } from '@/lib/api/client'
import { useDocumentPageQuery } from '../../queries'
import { useAnnotationsQuery } from '../Annotations/queries'
import {
  useListImageTemplates,
  useListNotes,
  useGenericTables,
  useListViewsTemplates,
  useManualPartsTables,
  useAutoPartsTable,
  useManualToolsTables,
  useAutoToolsTable,
  useListLabels,
} from '@/services/hooks/template_attributes'
import { useListImages } from '@/services/queries/images'
import { OperationNotesEditor } from '../OperationNotesEditor/OperationNotesEditor'
import DraggableImage from '../DraggableImage'
import { AnnotationsContainer } from '../Annotations/AnnotationsContainer'
import { cleanAndFilterViews } from '@/utils/document_preview'
import { cn } from '@/utils'
import GenericTable from '../GenericTable'
import { useStepBOM } from './hooks/useBOM'
import { OperationLabel } from '../OperationLabel'

const OPERATION_DOCUMENTS_OFFSET = 1000000

export const OperationDocument = ({
  documentPage,
  views,
  versionName,
  createdAt,
  orderNumber,
  isReadOnly,
  isLatestDocumentVersion,
}: {
  documentPage: DocumentPage
  views: View[]
  versionName: string
  createdAt: string
  orderNumber: number
  isReadOnly: boolean
  isLatestDocumentVersion?: boolean
}) => {
  const { data: docData } = useDocumentPageQuery()
  const { isAddingAnnotation } = useAnnotationsQuery()
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const partsInOperation = useStepBOM({
    documentPageId: documentPage.id,
  })
  const { data: images } = useListImages({
    documentVersionId: documentPage.document_version,
  })
  const notesTemplates = useListNotes(documentPage.id)
  const labelTemplates = useListLabels(documentPage.id)

  const imageTemplates = useListImageTemplates(documentPage.id)
  const viewTemplates = useListViewsTemplates(documentPage.id)

  const genericTables = useGenericTables(documentPage.id)
  const manualPartsTables = useManualPartsTables(documentPage.id)
  const autoPartsTable = useAutoPartsTable(documentPage.id)
  const manualToolsTables = useManualToolsTables(documentPage.id)
  const autoToolsTable = useAutoToolsTable(documentPage.id)

  const unselectElement = useCallback(() => {
    setSelectedElement(null)
  }, [setSelectedElement])

  useEffect(() => {
    if (selectedElement) {
      document.addEventListener('click', unselectElement)
    } else {
      document.removeEventListener('click', unselectElement)
    }

    return () => {
      document.removeEventListener('click', unselectElement)
    }
  }, [unselectElement, selectedElement])

  const imageIds = useMemo(
    () =>
      imageTemplates.map(
        (imageTemplate) => imageTemplate.template_values.imageId,
      ),
    [imageTemplates],
  )
  const documentImages = useMemo(
    () =>
      (images || []).filter(
        (image) => imageIds.findIndex((id) => id === image.id) >= 0,
      ),
    [imageIds, images],
  )

  const filteredImages = cleanAndFilterViews({
    images: [],
    documentImages: [],
    viewData: views as View[],
    documentType: 'work_instructions',
    documentPageId: documentPage.id as string,
  })

  if (
    !docData ||
    !docData.project ||
    !docData.version ||
    !docData.documentPages
  ) {
    return null
  }

  return (
    <DocumentTemplate
      id={`${documentPage.id}`}
      title={`Operation ${orderNumber}`}
      createdAt={createdAt}
      versionName={versionName}
      isLatestDocumentVersion={isLatestDocumentVersion}
      order={OPERATION_DOCUMENTS_OFFSET + orderNumber}
      documentPageId={documentPage.id as string}
    >
      <AnnotationsContainer documentPage={documentPage} isReadOnly={isReadOnly}>
        {partsInOperation.length > 0 && (
          <div className="flex justify-between">
            {manualPartsTables.length === 0 && (
              <PartsTable
                documentPage={documentPage}
                isReadOnly={isReadOnly}
                partsTableTemplate={autoPartsTable}
              />
            )}
            {manualToolsTables.length === 0 && (
              <ToolsTable
                documentPage={documentPage}
                isReadOnly={isReadOnly}
                toolsTableTemplate={autoToolsTable}
              />
            )}
          </div>
        )}
        <div>
          {manualPartsTables.map((tableTemplate) => (
            <PartsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              partsTableTemplate={tableTemplate}
              key={tableTemplate.id}
            />
          ))}
          {manualToolsTables.map((tableTemplate) => (
            <ToolsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              toolsTableTemplate={tableTemplate}
              key={tableTemplate.id}
            />
          ))}
          {manualPartsTables.length > 0 && autoPartsTable && (
            <PartsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              partsTableTemplate={autoPartsTable}
            />
          )}
          {manualToolsTables.length > 0 && autoToolsTable && (
            <ToolsTable
              documentPage={documentPage}
              isReadOnly={isReadOnly}
              toolsTableTemplate={autoToolsTable}
            />
          )}
        </div>
        <div>
          {genericTables.map((table) => (
            <GenericTable
              key={table.id}
              isReadOnly={isReadOnly}
              documentPage={documentPage}
              tableId={table.id as string}
              tableTemplate={table}
            />
          ))}
        </div>
        <div
          className="flex flex-1 justify-between"
          style={{
            maxHeight: 360,
          }}
        >
          <div className="flex flex-col gap-2 flex-1">
            <div
              className="flex gap-2"
              style={{
                height: '50%',
              }}
            >
              {filteredImages.map((view, i) => {
                const downloadUrl = view.download_url
                const userFacingDownloadUrl = `${view.name}.jpg`
                const imageTemplates = viewTemplates.filter(
                  (template) => template.template_values.viewId === view.id,
                )

                if (view.type === 'empty') {
                  return (
                    <div
                      className={cn('flex flex-col flex-1', {
                        absolute: i >= 2,
                      })}
                      key={`${i}-${view.assembly_group_id}`}
                      style={{
                        maxWidth: '49%',
                      }}
                    />
                  )
                }

                return (
                  downloadUrl &&
                  !!imageTemplates.length &&
                  imageTemplates.map((imageTemplate) => (
                    <div
                      key={view.id}
                      className={cn(
                        'flex flex-col justify-between top-0 left-0 flex-1',
                        {
                          [imageTemplate.template_values.imagePosition ||
                          'relative']: true,
                        },
                      )}
                      style={{
                        maxWidth: '49%',
                        maxHeight: 180,
                      }}
                    >
                      <DraggableImage
                        documentPageId={documentPage?.id as string}
                        userFacingDownloadUrl={userFacingDownloadUrl}
                        isAddingAnnotation={isAddingAnnotation}
                        downloadUrl={downloadUrl}
                        imageTemplate={imageTemplate as any}
                        type="view"
                        isReadOnly={isReadOnly}
                      />
                    </div>
                  ))
                )
              })}
            </div>
            <div className="flex gap-2 flex-1" style={{ height: '50%' }}>
              {notesTemplates.map((note) => (
                <div
                  className={cn('flex flex-1 max-w-[50%]', {
                    'max-w-[50%]':
                      note.template_values.notesPosition === 'relative',
                    [note.template_values.notesPosition || 'relative']: true,
                    'top-0': note.template_values.notesPosition === 'absolute',
                    'left-0': note.template_values.notesPosition === 'absolute',
                  })}
                  key={note.id}
                >
                  <OperationNotesEditor
                    documentId={docData.documentId ?? ''}
                    projectId={docData?.project.id ?? ''}
                    documentPageId={documentPage.id ?? ''}
                    isAddingAnnotation={isAddingAnnotation}
                    isReadOnly={isReadOnly}
                    type="note"
                    notesTemplate={note}
                  />
                </div>
              ))}
            </div>
            <div className="flex gap-2 flex-1">
              {labelTemplates.map((label) => (
                <OperationLabel
                  documentId={docData.documentId ?? ''}
                  projectId={docData?.project.id ?? ''}
                  documentPageId={documentPage.id ?? ''}
                  isAddingAnnotation={isAddingAnnotation}
                  isReadOnly={isReadOnly}
                  labelTemplate={label}
                  key={label.id}
                />
              ))}
            </div>
          </div>
          {documentImages.map((image) => {
            const downloadUrl = image.download_url as string
            const userFacingDownloadUrl = `${image.name}.jpg`
            const templates = imageTemplates.filter(
              (template) => template.template_values.imageId === image.id,
            )

            if (templates.length) {
              return templates.map((imageTemplate) => (
                <div
                  key={image.id}
                  className="flex flex-1 flex-col justify-between space-y-2 absolute top-0 left-0 h-full"
                  style={{
                    maxWidth: '49%',
                    maxHeight: 180,
                  }}
                >
                  <DraggableImage
                    documentPageId={documentPage?.id as string}
                    userFacingDownloadUrl={userFacingDownloadUrl}
                    isAddingAnnotation={isAddingAnnotation}
                    downloadUrl={downloadUrl}
                    imageTemplate={imageTemplate as any}
                    type="image"
                    isReadOnly={isReadOnly}
                  />
                </div>
              ))
            }
          })}
        </div>
      </AnnotationsContainer>
    </DocumentTemplate>
  )
}
