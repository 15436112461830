import { Link, useParams, useSearch } from 'wouter'
import {
  ChevronLeftIcon,
  CubeIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline'

import { cn } from '@/utils'
import { ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
import { ShareActionsDropdown } from '@/components/collaborating/ShareActionsDropdown'
import { DocumentVersionDropdown } from '@/components/versions/DocumentVersionDropdown'

import { useGetProject } from '@/services/queries/projects'

import { NavHomeButton } from './NavHomeButton'
import { NavbarMenu } from './NavbarMenu'

interface EditorNavbarProps {
  active: 'document' | 'cad'
}

export const EditorNavbar = ({ active }: EditorNavbarProps) => {
  const searchParams = useSearch()
  const { projectId, documentId } = useParams<{
    projectId: string
    documentId: string
  }>()
  const { isLoading, data } = useGetProject({ projectId })
  const uuid = data?.id

  if (isLoading || !uuid) {
    return <div>Loading...</div>
  }

  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="border-b bg-primary-40 text-white"
    >
      <ResizablePanel
        defaultSize={22}
        minSize={22}
        maxSize={22}
        style={{
          minWidth: 320,
          width: 320,
          maxWidth: 320,
        }}
      >
        <div className="flex items-center justify-between w-full h-full px-8">
          <NavHomeButton hardReload logoWidth={50} />
          <Link href="/">
            <div className="flex items-center space-x-2 justify-end">
              <ChevronLeftIcon className="h-4 w-4" />
              <h1 className="text-md font-semibold">Projects</h1>
            </div>
          </Link>
        </div>
      </ResizablePanel>
      <div
        className="w-0.5 h-6 bg-gray-200 mt-7"
        style={{
          marginTop: '20px',
          width: '1.5px',
        }}
      ></div>

      <ResizablePanel>
        <div className="flex items-center space-x-4 w-full h-full px-8 text-sm">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center h-16 w-full">
              <Link
                href={`/p/${projectId}/document/${documentId}/cad?${searchParams}`}
              >
                <div
                  className={cn(
                    'p-2 px-4 flex items-center space-x-2 font-semibold rounded-full',
                    {
                      'text-white bg-primary-45': active === 'cad',
                    },
                  )}
                >
                  <CubeIcon className="h-4 w-4" />
                  <span>CAD View</span>
                </div>
              </Link>
              <Link
                href={`/p/${projectId}/document/${documentId}/edit?${searchParams}`}
              >
                <div
                  className={cn(
                    'p-2 px-4 flex items-center space-x-2 font-semibold rounded-full',
                    {
                      'text-white bg-primary-45': active === 'document',
                    },
                  )}
                >
                  <DocumentTextIcon className="h-4 w-4" />
                  <span>Document Editor</span>
                </div>
              </Link>
            </div>
            <div className="flex items-center space-x-4">
              {active === 'document' && <DocumentVersionDropdown />}
              <ShareActionsDropdown />
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <NavbarMenu />
          </div>
        </div>
      </ResizablePanel>
    </ResizablePanelGroup>
  )
}
