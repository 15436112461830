import { useEffect } from 'react'
import { useSearch } from 'wouter'
import queryString from 'query-string'

import { Spinner } from '@/components/ui/spinner'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useKickViewWorker } from '@/services/hooks/view_worker'

import { WorkInstructions } from './WorkInstructions'
import { ProjectTracker } from './ProjectTracker'

export const Documents = () => {
  useKickViewWorker()

  const { isLoading, data } = useDocumentPageQuery()
  const search = useSearch()
  const searchParams = queryString.parse(search) as {
    cv?: string // cad version
    dv?: string // document version
    page_id?: string // document page id
  }
  const pageId = searchParams.page_id

  useEffect(() => {
    if (!isLoading && pageId) {
      setTimeout(() => {
        const page = document.getElementById(pageId)

        if (page) {
          page.scrollIntoView({
            block: 'center',
            inline: 'start',
          })
        }
      }, 100)
    }
  }, [isLoading, pageId])

  if (isLoading) {
    return <Spinner />
  }

  return data?.documentType === 'project_tracker' ? (
    <ProjectTracker />
  ) : (
    <WorkInstructions />
  )
}
