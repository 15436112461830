import {
  ONE_MINUTE,
  ONE_HOUR,
  ONE_DAY,
  ONE_WEEK,
} from '@/pages/DocumentPage/constants'
export const calculateTimeAgo = (time: Date) => {
  const timeDiff = Date.now() - time.getTime()
  let timeAgo

  if (timeDiff <= ONE_MINUTE) {
    timeAgo = 'Less than a minute ago'
  } else if (timeDiff <= ONE_HOUR) {
    timeAgo = `${Math.floor(timeDiff / ONE_MINUTE)} minute(s) ago`
  } else if (timeDiff <= ONE_DAY) {
    timeAgo = `${Math.floor(timeDiff / ONE_HOUR)} hour(s) ago`
  } else if (timeDiff <= ONE_WEEK) {
    timeAgo = `${Math.floor(timeDiff / ONE_DAY)} day(s) ago`
  } else {
    timeAgo = `${time.toLocaleDateString()}`
  }

  return timeAgo
}
