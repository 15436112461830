import { Link } from 'wouter'
import logo from '@/assets/q20-logo4.png'

export const NavHomeButton = ({
  hardReload,
  logoWidth,
}: {
  hardReload?: boolean
  logoWidth?: number
}) => {
  return (
    <Link
      href="/"
      onMouseUp={() => {
        if (hardReload) {
          window.location.href = '/'
        }
      }}
    >
      <img
        src={logo}
        style={{ width: logoWidth ?? 50, maxWidth: logoWidth ?? 50 }}
      />
    </Link>
  )
}
