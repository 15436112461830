import { useQuery, useMutation } from '@tanstack/react-query'

import {
  createTemplateAttribute,
  updateTemplateAttribute,
  deleteTemplateAttribute,
  listTemplateAttributes,
  getTemplateAttribute,
  TemplateAttribute,
  TemplateAttributeInput,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'

type mutationTemplateAttributeProps = {
  shouldOptimisticUpdate?: boolean
  onSuccess?: (templateAttribute?: TemplateAttribute) => void
}

const onCreateTemplateAttributeSuccess = ({
  props,
  templateAttribute,
}: {
  props?: mutationTemplateAttributeProps
  templateAttribute: TemplateAttribute
}) => {
  const shouldOptimisticUpdate =
    props === undefined ? true : props.shouldOptimisticUpdate !== false
  if (shouldOptimisticUpdate) {
    queryClient.setQueriesData(
      {
        queryKey: [
          QUERY_KEYS.TEMPLATE_ATTRIBUTES,
          { documentPageId: templateAttribute?.document_page },
        ],
      },
      (templateAttributes: Array<TemplateAttribute> | undefined) => {
        if (templateAttributes) {
          return [...templateAttributes, templateAttribute]
        }
        return []
      },
    )

    queryClient.setQueryData(
      [
        QUERY_KEYS.TEMPLATE_ATTRIBUTE,
        { templateAttributeId: templateAttribute?.id },
      ],
      templateAttribute,
    )
  }
  if (props?.onSuccess) props.onSuccess(templateAttribute)
}

export const useCreateTemplateAttribute = (
  props?: mutationTemplateAttributeProps,
) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      documentPageId,
      payload,
    }: {
      documentPageId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await createTemplateAttribute(documentPageId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onCreateTemplateAttributeSuccess({ props, templateAttribute }),
  })
}

export const createTemplateAttributeMutation = () => {
  return queryClient.getMutationCache().build(queryClient, {
    mutationKey: [MUTATION_KEYS.CREATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      documentPageId,
      payload,
    }: {
      documentPageId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await createTemplateAttribute(documentPageId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onCreateTemplateAttributeSuccess({ templateAttribute }),
  })
}

const onUpdateTemplateAttributeSuccess = ({
  props,
  templateAttribute,
}: {
  props?: mutationTemplateAttributeProps
  templateAttribute: TemplateAttribute
}) => {
  queryClient.setQueriesData(
    {
      queryKey: [
        QUERY_KEYS.TEMPLATE_ATTRIBUTES,
        { documentPageId: templateAttribute?.document_page },
      ],
    },
    (templateAttributes: Array<TemplateAttribute> | undefined) => {
      if (templateAttributes) {
        return templateAttributes.map((currentTemplateAttribute) => {
          return currentTemplateAttribute.id === templateAttribute?.id
            ? templateAttribute
            : currentTemplateAttribute
        })
      }
      return []
    },
  )

  queryClient.setQueryData(
    [
      QUERY_KEYS.TEMPLATE_ATTRIBUTE,
      { templateAttributeId: templateAttribute?.id },
    ],
    templateAttribute,
  )
  if (props?.onSuccess) props.onSuccess(templateAttribute)
}

export const updateTemplateAttributeMutation = () => {
  return queryClient.getMutationCache().build(queryClient, {
    mutationKey: [MUTATION_KEYS.UPDATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      templateAttributeId,
      payload,
    }: {
      templateAttributeId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await updateTemplateAttribute(templateAttributeId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onUpdateTemplateAttributeSuccess({ templateAttribute }),
  })
}

export const useUpdateTemplateAttribute = (
  props?: mutationTemplateAttributeProps,
) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      templateAttributeId,
      payload,
    }: {
      templateAttributeId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await updateTemplateAttribute(templateAttributeId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onUpdateTemplateAttributeSuccess({ props, templateAttribute }),
  })
}

export const useDeleteTemplateAttribute = (
  props?: mutationTemplateAttributeProps,
) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      templateAttributeId,
    }: {
      templateAttributeId: string
    }) => {
      await deleteTemplateAttribute(templateAttributeId)
      return templateAttributeId
    },
    onSuccess: (templateAttributeId) => {
      queryClient.setQueriesData(
        {
          queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTES],
        },
        (templateAttributes: Array<TemplateAttribute> | undefined) => {
          if (templateAttributes) {
            return templateAttributes.filter(
              (currentTemplateAttribute) =>
                currentTemplateAttribute.id !== templateAttributeId,
            )
          }
          return []
        },
      )

      queryClient.removeQueries({
        queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTE, { templateAttributeId }],
      })

      if (props?.onSuccess) props.onSuccess()
    },
  })
}

export const listTemplateAttributesQuery = ({
  documentPageId,
}: {
  documentPageId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTES, { documentPageId }],
    queryFn: () => listTemplateAttributes(documentPageId),
  })
}

export const useListTemplateAttributes = ({
  documentPageId,
}: {
  documentPageId?: string | null
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTES, { documentPageId }],
    queryFn: () => {
      if (!documentPageId) return []
      return listTemplateAttributes(documentPageId)
    },
    enabled: Boolean(documentPageId),
  })
}

const getTemplateAttributeQueryFn = async ({
  templateAttributeId,
}: {
  templateAttributeId: string
}) => {
  const templateAttribute = await getTemplateAttribute(templateAttributeId)

  queryClient.setQueriesData(
    {
      queryKey: [
        QUERY_KEYS.TEMPLATE_ATTRIBUTES,
        { documentPageId: templateAttribute?.document_page },
      ],
    },
    (templateAttributes: Array<TemplateAttribute> | undefined) => {
      if (templateAttributes) {
        return [...templateAttributes, templateAttribute]
      }
      return []
    },
  )
}
export const getTemplateAttributeQuery = ({
  templateAttributeId,
}: {
  templateAttributeId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTE, { templateAttributeId }],
    queryFn: () => getTemplateAttributeQueryFn({ templateAttributeId }),
  })
}

export const useGetTemplateAttribute = ({
  templateAttributeId,
}: {
  templateAttributeId: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTE, { templateAttributeId }],
    queryFn: () => getTemplateAttributeQueryFn({ templateAttributeId }),
    enabled: Boolean(templateAttributeId),
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
