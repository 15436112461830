import { cn } from '@/utils'
export const WandOutlineIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M3.98004 1.13527C3.98608 1.11895 3.99297 1.11337 3.99734 1.1105C4.00361 1.10638 4.01385 1.10262 4.02693 1.10262C4.04001 1.10262 4.05025 1.10638 4.05653 1.1105C4.06089 1.11337 4.06779 1.11895 4.07383 1.13527L4.72249 2.88826C4.79846 3.09355 4.96031 3.25541 5.1656 3.33137L6.9186 3.98004C6.93492 3.98608 6.9405 3.99297 6.94336 3.99734C6.94748 4.00361 6.95125 4.01385 6.95125 4.02693C6.95125 4.04001 6.94748 4.05025 6.94336 4.05653C6.9405 4.06089 6.93492 4.06779 6.9186 4.07383L5.1656 4.72249C4.96031 4.79846 4.79846 4.96031 4.72249 5.1656L4.07383 6.9186C4.06779 6.93492 4.06089 6.9405 4.05653 6.94336C4.05025 6.94748 4.04001 6.95125 4.02693 6.95125C4.01385 6.95125 4.00361 6.94748 3.99734 6.94336C3.99297 6.9405 3.98608 6.93492 3.98004 6.9186L3.33137 5.1656C3.25541 4.96031 3.09355 4.79846 2.88826 4.72249L1.13527 4.07383C1.11895 4.06779 1.11337 4.06089 1.1105 4.05653C1.10638 4.05025 1.10262 4.04001 1.10262 4.02693C1.10262 4.01385 1.10638 4.00361 1.1105 3.99734C1.11337 3.99297 1.11895 3.98608 1.13526 3.98004L2.88826 3.33137C3.09355 3.25541 3.25541 3.09355 3.33137 2.88826L3.98004 1.13527Z"
        stroke="black"
        strokeWidth="0.7"
      />
      <path
        d="M5.27541 14.5398C5.30762 14.4527 5.43076 14.4527 5.46298 14.5398L5.7491 15.313L6.03045 15.2089L5.7491 15.313C5.82 15.5046 5.97107 15.6557 6.16267 15.7266L6.9359 16.0127C7.02296 16.0449 7.02296 16.1681 6.9359 16.2003L6.16267 16.4864C5.97107 16.5573 5.82 16.7084 5.7491 16.9L5.46298 17.6732C5.43076 17.7603 5.30762 17.7603 5.27541 17.6732L4.98929 16.9C4.91839 16.7084 4.76732 16.5573 4.57571 16.4864L3.80248 16.2003C3.71542 16.1681 3.71542 16.0449 3.80248 16.0127L4.57571 15.7266L4.4716 15.4452L4.57572 15.7266C4.76732 15.6557 4.91839 15.5046 4.98929 15.313L5.27541 14.5398Z"
        stroke="black"
        strokeWidth="0.6"
      />
      <path
        d="M14.0941 3.69604L14.6243 5.129C14.6901 5.30692 14.8304 5.4472 15.0083 5.51303L16.4413 6.04328L15.0083 6.57352C14.8304 6.63936 14.6901 6.77963 14.6243 6.95755L14.0941 8.39052L13.5638 6.95755C13.498 6.77963 13.3577 6.63936 13.1798 6.57352L11.7468 6.04328L13.1798 5.51303C13.3577 5.4472 13.498 5.30692 13.5638 5.129L14.0941 3.69604Z"
        stroke="black"
        strokeWidth="0.7"
      />
      <rect
        x="6.70711"
        y="9.125"
        width="2"
        height="15"
        rx="1"
        transform="rotate(-45 6.70711 9.125)"
        stroke="black"
      />
    </svg>
  )
}
