import { useMemo } from 'react'

import { useCadPageParams } from '@/pages/CADPage/hooks'
import { DocumentVersion } from '@/lib/api/client'
import { useListDocumentVersions } from '../document_versions'
import { useListDocumentPagesQuery } from '../document_pages'
import { convertDocumentToStep } from './convertDocumentPageToStep'

export const useOperationSteps = () => {
  const { documentId, dv: documentVersionId } = useCadPageParams()
  const { isLoading: isLoadingDocumentVersions, data: documentVersions } =
    useListDocumentVersions({ documentId })

  const selectedDocumentVersion = documentVersions?.find(
    (v) => v.id === documentVersionId,
  )
  const latestDocumentVersions =
    Array.isArray(documentVersions) && documentVersions.length > 0
      ? (documentVersions.sort(
          (a, b) => (b?.version_number || 0) - (a?.version_number || 0),
        )[0] as DocumentVersion)
      : []

  const documentVersion = (
    documentVersionId && selectedDocumentVersion
      ? selectedDocumentVersion
      : latestDocumentVersions
  ) as DocumentVersion

  const { isLoading: isDocumentPagesLoading, data: pages } =
    useListDocumentPagesQuery({
      documentVersionId: documentVersion.id,
    })

  const steps = useMemo(() => (pages || []).map(convertDocumentToStep), [pages])

  const isLoading = useMemo(
    () => isLoadingDocumentVersions || isDocumentPagesLoading,
    [isLoadingDocumentVersions, isDocumentPagesLoading],
  )

  return {
    isLoading,
    steps,
  }
}
