import { useMutation } from '@tanstack/react-query'
import { reorderStepPages } from '@/lib/api/client'

import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages/queryKeys'
import queryClient from '@/queryClient'

import type { Step } from '@/services/queries/operation_steps/types'

export const useReorderSteps = () => {
  return useMutation({
    mutationFn: async ({
      documentVersionId,
      steps,
    }: {
      documentVersionId: string
      steps: Step[]
    }) => {
      const documentIds = steps.map((step) => step.id as string)
      await reorderStepPages(documentVersionId, documentIds)
      return { documentVersionId, steps }
    },
    onMutate: async ({ documentVersionId, steps }) => {
      await queryClient.cancelQueries({
        queryKey: [
          DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId },
        ],
      })

      const previousSteps = queryClient.getQueryData<Step[]>([
        DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
        { documentVersionId },
      ])

      const updatedSteps = steps.map((step, i) => ({
        ...step,
        order_number: i + 1,
      }))

      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        updatedSteps,
      )

      return { previousSteps, documentVersionId }
    },
    onError: (_, __, context) => {
      const documentVersionId = context?.documentVersionId
      const previousSteps = context?.previousSteps
      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        previousSteps,
      )
    },
  })
}
