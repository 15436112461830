import { cn } from '@/utils'
export const ExplodeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M1 15.2459V8.11459C1 7.82841 1.29742 7.63926 1.5566 7.76061L6.73721 10.1863C7.08062 10.3471 7.3 10.692 7.3 11.0712V18.3674C7.3 18.6579 6.99423 18.8469 6.73435 18.717L1.54015 16.1199C1.20911 15.9544 1 15.616 1 15.2459Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M16.75 15.2469V7.94003C16.75 7.64783 16.4411 7.45895 16.181 7.59212L11.0283 10.2305C10.7037 10.3967 10.4987 10.7298 10.4965 11.0945L10.4537 18.3642C10.452 18.6558 10.7586 18.8465 11.0194 18.7161L16.2098 16.1209C16.5409 15.9553 16.75 15.617 16.75 15.2469Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M2.20819 4.06581L8.36457 1.19284C8.62649 1.07061 8.92909 1.07061 9.19101 1.19284L15.3474 4.06581C15.6481 4.20617 15.6481 4.63383 15.3474 4.77419L9.19101 7.64716C8.92909 7.76939 8.62649 7.76939 8.36457 7.64716L2.20819 4.77419C1.90743 4.63383 1.90743 4.20617 2.20819 4.06581Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  )
}
