import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import { Spinner } from '@/components/ui/spinner'

const formSchema = z.object({
  name: z.string(),
  comment: z.string(),
})

interface CreateDocumentVersionFormFieldsProps {
  isSubbmitting: boolean
  onSubmit: (values: z.infer<typeof formSchema>) => void
}

export const CreateDocumentVersionFormFields = ({
  isSubbmitting,
  onSubmit,
}: CreateDocumentVersionFormFieldsProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      comment: '',
    },
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="V2 (Optional)"
                  data-testid="version-name-field"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="comment"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  placeholder="What changes where made?"
                  data-testid="comment-field"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Button
            type="submit"
            disabled={isSubbmitting}
            data-testid="create-version-button"
          >
            {isSubbmitting ? <Spinner /> : 'Create Version'}
          </Button>
        </div>
      </form>
    </Form>
  )
}
