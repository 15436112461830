import { useMemo } from 'react'

interface AddPartFormProps {
  query: string
  updateBomOnSelect: boolean
}

export const AddToolForm = ({ query, updateBomOnSelect }: AddPartFormProps) => {
  const content = useMemo(
    () =>
      updateBomOnSelect ? (
        <>
          Add&nbsp;
          <span
            style={{
              padding: '2px 6px',
              borderRadius: '5px',
              backgroundColor: '#E5D5FF',
              color: 'rgb(30, 30, 30)',
            }}
          >
            {query}
          </span>
          &nbsp;to Tools BOM?
        </>
      ) : (
        <span
          style={{
            padding: '2px 6px',
            borderRadius: '5px',
            backgroundColor: '#E5D5FF',
            color: 'rgb(30, 30, 30)',
          }}
        >
          {query}
        </span>
      ),
    [updateBomOnSelect, query],
  )

  return (
    <div>
      <div className="px-2 py-2 break-all">{content}</div>
    </div>
  )
}
