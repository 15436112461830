import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useLogout } from '@/services/queries/authentication'
import { UserIcon } from '@heroicons/react/24/solid'

const LogoutButton = () => {
  const { isLogginOut, handleLogout } = useLogout()

  return (
    <DropdownMenuItem onClick={() => handleLogout()} disabled={isLogginOut}>
      Log out
    </DropdownMenuItem>
  )
}

export const NavbarMenu = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar className="bg-white text-black">
          <AvatarImage src="" />
          <AvatarFallback className="bg-white text-black">
            <UserIcon className="h-3.5 w-3.5" />
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <LogoutButton />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
