import { Vector3, Plane } from 'three'
import { useFrame } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'
import { TransformControls } from '@react-three/drei'
import { TransformControls as TransformControlsImpl } from 'three-stdlib'
import { useMemo, useEffect, useCallback, useState, useRef } from 'react'

import { GLTFObject } from '@/lib/cad/GLTFObject'
import { PlaneVisualizer } from '@/lib/cad/PlaneVisualizer'
import { SectionState, useCADPageStore } from '@/pages/CADPage/state'

export const CrossSectionControls = ({ gltf }: { gltf: GLTFObject }) => {
  const state = useCADPageStore(useShallow((state) => state.sectionState))
  const boundsMax = useCADPageStore(useShallow((state) => state.boundsMax))
  const planes = useCADPageStore(useShallow((state) => state.clippingPlanes))
  const storePlane = useCADPageStore(useShallow((state) => state.planeObject))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const translate = useCADPageStore((state) => state.translateEnabled)
  const rotate = useCADPageStore((state) => state.rotateEnabled)

  const { INIT, ENABLED, DISABLED } = SectionState
  const [active, setActive] = useState<'rotate' | 'translate' | null>(null)

  const rotateControlsRef = useRef<TransformControlsImpl>(null)
  const translateControlsRef = useRef<TransformControlsImpl>(null)

  const activeClippingPlane = 0

  // Instantiate clippingPlane and planeObject
  const clippingPlanes = useMemo(() => {
    if (planes.length) return [...planes]

    const normal = new Vector3(0, 0, -1)
    const constant = -new Vector3(0, 0, 0).dot(normal)
    return [new Plane(normal, constant)]
  }, [planes])

  const [planeObject] = useState(() => {
    return storePlane ?? new PlaneVisualizer(0x0000ff)
  })

  // Add planeObject to the scene
  useEffect(() => {
    if (state && (state === INIT || state === ENABLED)) {
      gltf.scene.add(planeObject)
      gltf.setClippingPlanes(clippingPlanes)
      if (boundsMax) {
        const scale = 0.5 * boundsMax
        planeObject.scale.set(scale, scale, scale)
      }
    } else if (state === DISABLED) {
      gltf.scene.remove(planeObject)
    }
  }, [state, boundsMax, gltf, clippingPlanes, planeObject])

  // Handle planeObject being added or removed from the scene
  const onAddPlaneObject = useCallback(() => {
    const { sectionState } = getCadPageState()
    if (sectionState === INIT) {
      const offset = planeObject.worldToLocal(new Vector3(0, 0, 0))
      planeObject.position.copy(offset)
      planeObject.lookAt(new Vector3(0, 0, 1))
      setCadPageState({ sectionState: ENABLED })
    }
    if (sectionState === DISABLED) {
      setCadPageState({ sectionState: ENABLED })
    }
  }, [getCadPageState, setCadPageState])

  useEffect(() => {
    planeObject.addEventListener('added', onAddPlaneObject)
    if (!storePlane) setCadPageState({ planeObject })

    return () => {
      planeObject.removeEventListener('added', onAddPlaneObject)
    }
  }, [onAddPlaneObject, planeObject, setCadPageState, storePlane])

  const updateClippingPlane = useCallback(() => {
    if (!planeObject || !clippingPlanes[activeClippingPlane]) return

    const planeNormal = new Vector3(0, 0, -1)
      .applyQuaternion(planeObject.quaternion)
      .normalize()

    const globalPosition = new Vector3()
    planeObject.getWorldPosition(globalPosition)
    const updatedPlane = new Plane()
    updatedPlane.setFromNormalAndCoplanarPoint(planeNormal, globalPosition)
    clippingPlanes[activeClippingPlane] = updatedPlane
    setCadPageState({ clippingPlanes: [...clippingPlanes] })
  }, [clippingPlanes, planeObject])

  useFrame(() => {
    if (state && state !== DISABLED) updateClippingPlane()
  })

  if (!state || state === DISABLED) return null

  return (
    <>
      {translate && (
        <TransformControls
          ref={translateControlsRef}
          size={1.2}
          mode={'translate'}
          object={planeObject}
          space="local"
          showX={false}
          showY={false}
          onMouseDown={() => {
            if (active === 'rotate') return
            setActive('translate')
            if (rotateControlsRef.current) {
              rotateControlsRef.current['enabled'] = false
            }
          }}
          onMouseUp={() => {
            setActive(null)
            if (rotateControlsRef.current) {
              rotateControlsRef.current['enabled'] = true
            }
          }}
        />
      )}
      {rotate && (
        <TransformControls
          ref={rotateControlsRef}
          mode={'rotate'}
          object={planeObject}
          space="local"
          showZ={false}
          onMouseDown={() => {
            if (active === 'translate') return
            setActive('rotate')
            if (translateControlsRef.current) {
              translateControlsRef.current['enabled'] = false
            }
          }}
          onMouseUp={() => {
            setActive(null)
            if (translateControlsRef.current) {
              translateControlsRef.current['enabled'] = true
            }
          }}
        />
      )}
    </>
  )
}
