import { TableCell, TableRow, TableBody, Table } from '@/components/ui/table'
import { Skeleton } from '@/components/ui/skeleton'

export const SkeletonTableRows = () => {
  return (
    <Table>
      <TableBody>
        {Array.from({ length: 5 }).map((_, i) => {
          return (
            <TableRow key={i}>
              <TableCell>
                <Skeleton className="h-4 w-8/12 bg-gray-200" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-full bg-gray-200" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-full bg-gray-200" />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
