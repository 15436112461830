import { useEffect } from 'react'
import { usePDF, Page, Image, Document, View } from '@react-pdf/renderer'
import { cn } from '@/utils'
import { usePDFifyStore } from './state'

export const PDFDocument = ({ imageUrls }: { imageUrls: string[] }) => {
  return (
    <Document>
      {imageUrls.map((url, i) => (
        <Page
          key={`page-${i}`}
          size="A4"
          orientation="landscape"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <View style={{ width: '100%', height: '100%', padding: 5 }}>
            <Image
              src={url}
              style={{
                objectFit: 'fill',
              }}
            />
          </View>
        </Page>
      ))}
    </Document>
  )
}

export const DocumentPDFPreview = ({
  isVisible,
  onRender,
}: {
  isVisible?: boolean
  onRender: (props: { pdfUrl: string; pdfBlob: Blob }) => void
}) => {
  const imageUrls = usePDFifyStore((state) => state.imageUrls)

  if (imageUrls.length === 0) {
    return null
  }

  return (
    <PDFViewer
      className={cn('h-full w-full', {
        hidden: !isVisible,
      })}
      onUrlChange={(pdfData) => {
        if (pdfData.pdfUrl) {
          onRender(pdfData)
        }
      }}
    >
      <PDFDocument imageUrls={imageUrls} />
    </PDFViewer>
  )
}

export const PDFViewer = ({
  title,
  style,
  className,
  children,
  innerRef,
  showToolbar = true,
  onUrlChange,
  ...props
}: any) => {
  const [instance, updateInstance] = usePDF()

  useEffect(() => updateInstance(children), [children])

  const pdfUrl = instance.url
  const pdfBlob = instance.blob

  useEffect(() => {
    if (typeof onUrlChange === 'function' && typeof pdfUrl === 'string') {
      setTimeout(() => {
        onUrlChange({ pdfUrl, pdfBlob })
      })
    }
  }, [pdfUrl, onUrlChange])

  const src = pdfUrl ? `${pdfUrl}#toolbar=${showToolbar ? 1 : 0}` : null

  return (
    <iframe
      src={src}
      title={title}
      ref={innerRef}
      style={style}
      className={className}
      {...props}
    />
  )
}
