import { cn } from '@/utils'

export const CrossSectionIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M17.1601 4.98833L9.16014 2.56384C6.59138 1.78535 4 3.70777 4 6.39191V15.3556C4 17.1357 5.17635 18.7016 6.88603 19.1974L14.886 21.5171C17.4447 22.259 20 20.3394 20 17.6754V8.81639C20 7.05411 18.8467 5.49945 17.1601 4.98833Z"
        fill="#D8D8D8"
        fillOpacity="0.4"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 4"
      />
    </svg>
  )
}
