import { useParams } from 'wouter'
import { useShallow } from 'zustand/react/shallow'
import { useQuery, useMutation } from '@tanstack/react-query'

import {
  cadVersionUploaded,
  createCadVersion,
  uploadFile,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import {
  useUpdateCadVersion,
  getCadVersionQuery,
} from '@/services/queries/cad_versions'
import {
  QUERY_KEYS as CAD_QUERY_KEYS,
  useCADQuery,
} from '@/services/queries/cads'
import { getLatestDocumentVersion } from '@/services/queries/document_versions'
import {
  useDeleteDocumentPage,
  getDocumentPageQuery,
} from '@/services/queries/document_pages'
import { QUERY_KEYS as DOCUMENT_QUERY_KEYS } from '@/services/queries/documents'
import {
  useReorderDocumentPages,
  listDocumentPagesQuery,
} from '@/services/queries/document_pages'

import { useToast } from '@/components/ui/use-toast'
import { useAppStore } from '@/state'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useAssemblyTree, RawAssemblyTree } from '@/state'
import { compressFile } from '@/lib/gzip'

export const useAssemblyTreeQuery = ({
  cadVersionId,
  projectId,
  documentId,
}: {
  cadVersionId: string
  projectId: string
  documentId: string
}) => {
  const setAssemblyTree = useAssemblyTree(
    useShallow((state) => state.setAssemblyTree),
  )

  return useQuery({
    staleTime: 0,
    refetchOnMount: true,
    enabled: Boolean(projectId && cadVersionId),
    queryKey: [CAD_QUERY_KEYS.CAD_ASSEMBLY_TREE, { projectId }],
    queryFn: async () => {
      if (!cadVersionId) {
        return
      }

      const cadVersion = await getCadVersionQuery({ cadVersionId })
      const documentVersion = documentId
        ? await getLatestDocumentVersion({
            documentId,
          })
        : undefined
      const documentPages = documentVersion
        ? await listDocumentPagesQuery({
            documentVersionId: documentVersion.id as string,
          })
        : []

      const rawAssemblyTree =
        cadVersion.assembly_tree as unknown as RawAssemblyTree

      if (rawAssemblyTree && documentPages) {
        setAssemblyTree(rawAssemblyTree, documentPages)
      }

      return { assemblyTree: rawAssemblyTree, documentPages }
    },
  })
}

export const useDocument = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const getNodeByUUID = useAssemblyTree(useShallow((state) => state.getNode))
  const selectedPartUUID = useCADPageStore((s) =>
    s.selectedParts.length === 1 ? s.selectedParts[0] : null,
  )
  const { isLoading: isCadLoading, data: cad } = useCADQuery()

  const isEnabled = Boolean(selectedPartUUID && !isCadLoading && cad?.gltf)

  return useQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT, { projectId, selectedPartUUID }],
    queryFn: async () => {
      if (!isEnabled) {
        return { document: null, cad: null }
      }
      const node = getNodeByUUID(selectedPartUUID as string)

      if (!node) {
        throw new Error('No assembly node found')
      }

      if (!node.document_page_id) {
        return null
      }

      const documentPage = await getDocumentPageQuery({
        documentPageId: node.document_page_id,
      })

      return { documentPage, cadVersionId: cad.version?.id }
    },
    enabled: isEnabled,
  })
}

export const useCreateCadVersionMutation = () => {
  const { projectId, documentId } = useParams<{
    projectId: string
    documentId: string
  }>()

  return useMutation({
    mutationFn: async ({
      cadId,
      comment,
      file,
    }: {
      cadId: string
      comment: string
      file: File
    }) => {
      const compressedFile = await compressFile(file)
      const cadVersion = await createCadVersion(cadId, {
        filename: compressedFile.name,
        comment,
      })

      const uploadUrl = cadVersion.upload_url
      if (!uploadUrl) {
        throw new Error('Failed to get upload URL')
      }
      await uploadFile(uploadUrl, compressedFile)
      await cadVersionUploaded(cadVersion.id as string)
      return { cadVersion, cadId }
    },
    onSuccess: () => {
      window.location.href = `/p/${projectId}/document/${documentId}/cad`
    },
  })
}

export const useUngroupAssemblyNodesMutation = () => {
  const { toast } = useToast()
  const viewWorker = useAppStore((state) => state.viewWorker)
  const ungroupNodes = useAssemblyTree(
    useShallow((state) => state.ungroupNodes),
  )
  const getAssemblyTree = useAssemblyTree(
    useShallow((state) => state.getAssemblyTree),
  )
  const { mutate: updateCadVersion } = useUpdateCadVersion()
  const getNodeByUUID = useAssemblyTree(useShallow((state) => state.getNode))
  const { mutateAsync: reorderDocumentPages } = useReorderDocumentPages()
  const { mutateAsync: deleteDocumentPage } = useDeleteDocumentPage()
  const { data: cadData } = useCADQuery()
  const gltf = cadData.gltf

  return useMutation({
    mutationFn: async ({
      groupUUID,
      cadVersionId,
      documentVersionId,
    }: {
      groupUUID: string
      cadVersionId: string
      documentVersionId?: string | null
    }) => {
      const groupNode = getNodeByUUID(groupUUID)
      if (!groupNode) {
        return {}
      }

      ungroupNodes(groupUUID)
      const assemblyTree = getAssemblyTree()

      if (!assemblyTree) {
        return {}
      }

      await updateCadVersion({
        cadVersionId,
        values: {
          assembly_tree: assemblyTree,
        },
      })

      if (documentVersionId && groupNode.document_page_id) {
        await deleteDocumentPage({ documentPageId: groupNode.document_page_id })
        await reorderDocumentPages({ documentVersionId, assemblyTree })
      }

      if (gltf) {
        gltf.groupParts(assemblyTree)
      }

      return { cadVersionId, documentVersionId }
    },
    onSuccess: ({ cadVersionId, documentVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (viewWorker && cadVersionId && documentVersionId) {
        viewWorker.kick(cadVersionId, documentVersionId)
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateCadVersionMutation = () => {
  const { toast } = useToast()
  const viewWorker = useAppStore((state) => state.viewWorker)
  const { mutateAsync: updateCadVersion } = useUpdateCadVersion()

  return useMutation({
    mutationFn: async ({
      cadVersionId,
      documentVersionId,
      assemblyTree,
    }: {
      cadVersionId: string
      documentVersionId: string
      assemblyTree: RawAssemblyTree
    }) => {
      await updateCadVersion({
        cadVersionId,
        values: {
          assembly_tree: assemblyTree,
        },
      })

      return { cadVersionId, documentVersionId }
    },
    onSuccess: ({ cadVersionId, documentVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (viewWorker && cadVersionId && documentVersionId) {
        viewWorker.kick(cadVersionId, documentVersionId)
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}
