import { cn } from '@/utils'
export const ExplosionLineIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M11.4633 22.5393L6.79751 20.4656C4.73645 19.5496 3.70592 19.0916 3.70592 18.3334C3.70592 17.5752 4.73645 17.1172 6.79751 16.2012L11.2223 14.2346C11.8522 13.9546 12.1672 13.8147 12.502 13.8101C12.8368 13.8054 13.1555 13.9367 13.7928 14.1991L17.8188 15.8568C19.8084 16.6761 20.8032 17.0857 20.8388 17.8261C20.8745 18.5665 19.9237 19.0699 18.0221 20.0766L13.5027 22.4693C13.0102 22.73 12.764 22.8603 12.4955 22.8696C12.2271 22.8788 11.9725 22.7656 11.4633 22.5393Z"
        fill="#D9D9D9"
        stroke="#D9D9D9"
        strokeWidth="2.33333"
      />
      <path
        d="M3.75 16V18.9167"
        stroke="#1E1E1E"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M3.75 16V18.9167"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M20.667 16V18.9167"
        stroke="#1E1E1E"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M20.667 16V18.9167"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M3.75 6.6665V10.7498"
        stroke="#1E1E1E"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M3.75 6.6665V10.7498"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M20.667 6.6665V10.7498"
        stroke="#1E1E1E"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M20.667 6.6665V10.7498"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M12.5 19.5V22.4167"
        stroke="#1E1E1E"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M12.5 19.5V22.4167"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M12.5 10.75V14.8333"
        stroke="#1E1E1E"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M12.5 10.75V14.8333"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.03333"
        strokeLinecap="round"
      />
      <path
        d="M9.81689 9.13545L4.70066 7.16921C3.80041 6.82323 3.35028 6.65024 3.34295 6.33773C3.33561 6.02522 3.77712 5.8313 4.66015 5.44346L8.75422 3.64528C10.5998 2.83468 11.5226 2.42938 12.5005 2.42868C13.4784 2.42797 14.4018 2.83194 16.2485 3.63989L19.879 5.22825C20.7169 5.59484 21.1359 5.77813 21.1359 6.08333C21.1359 6.38854 20.7169 6.57183 19.879 6.93841L15.1338 9.01447C13.8341 9.58309 13.1842 9.8674 12.4937 9.88311C11.8032 9.89882 11.1411 9.64436 9.81689 9.13545Z"
        fill="#D9D9D9"
        stroke="#D9D9D9"
        strokeWidth="2.33333"
      />
    </svg>
  )
}
