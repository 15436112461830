export const PartIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.10893 13.5881L11.8919 11.4735C12.2958 11.2477 12.4973 11.1349 12.6442 10.977C12.7741 10.8372 12.8723 10.6727 12.9323 10.4939C13 10.2923 13 10.067 13 9.61762V5.38224C13 4.93289 13 4.70762 12.9323 4.50601C12.8723 4.32721 12.7741 4.16251 12.6442 4.02279C12.4979 3.86557 12.2969 3.75318 11.8965 3.52939L8.10825 1.41187C7.70441 1.18613 7.5029 1.07349 7.28823 1.02932C7.09829 0.990228 6.90191 0.990228 6.71197 1.02932C6.4973 1.07349 6.29511 1.18613 5.89127 1.41187L2.10755 3.52686C1.70418 3.75234 1.50265 3.86498 1.35589 4.0228C1.22595 4.16251 1.12783 4.32721 1.06782 4.50601C1 4.70809 1 4.93395 1 5.38542V9.61462C1 10.0661 1 10.2918 1.06782 10.4939C1.12783 10.6727 1.22595 10.8372 1.35589 10.977C1.50274 11.1349 1.70441 11.2477 2.10825 11.4735L5.89127 13.5881C6.2951 13.8138 6.4973 13.9265 6.71197 13.9707C6.90191 14.0098 7.09829 14.0098 7.28823 13.9707C7.5029 13.9265 7.7051 13.8138 8.10893 13.5881Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92152 7.49993C4.92152 8.61131 5.85208 9.51225 7 9.51225C8.14792 9.51225 9.07848 8.61131 9.07848 7.49993C9.07848 6.38855 8.14792 5.48761 7 5.48761C5.85208 5.48761 4.92152 6.38855 4.92152 7.49993Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
