import { ReactNode } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { ASMTreeNode, RawAssemblyTree } from '@/state'
import { DRAGGABLE_TYPE } from './constants'

export const DraggableTreeNodeContainer = ({
  className,
  children,
  enabled,
  node,
  tree,
  onMouseOver,
  onMouseOut,
}: {
  className?: string
  children: ReactNode
  enabled: boolean
  node: ASMTreeNode
  tree: RawAssemblyTree
  onMouseOver?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    node: ASMTreeNode,
  ) => void
  onMouseOut?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    node: ASMTreeNode,
  ) => void
}) => {
  const draggable = useDraggable({
    id: `assembly-tree-node-${node.uuid}`,
    data: { node, tree, type: DRAGGABLE_TYPE },
  })

  return (
    <div
      id={`tree-node-container-${node.uuid}`}
      className={className}
      ref={enabled ? draggable.setNodeRef : undefined}
      {...(enabled ? draggable.listeners : {})}
      {...(enabled ? draggable.attributes : {})}
      onMouseOver={(e) => {
        if (onMouseOver) {
          onMouseOver(e, node)
        }
      }}
      onMouseOut={(e) => {
        if (onMouseOut) {
          onMouseOut(e, node)
        }
      }}
    >
      {children}
    </div>
  )
}
