import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { SaveIcon } from '@/components/icons/SaveIcon'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { cn } from '@/utils'
import { CreateDocumentVersionForm } from './CreateDocumentVersionForm'

interface CreateDocumentVersionButtonProps {
  className?: string
  disabled?: boolean
}

export const CreateDocumentVersionButton = ({
  className,
  disabled,
}: CreateDocumentVersionButtonProps) => {
  const [open, setOpen] = useState(false)
  const documentPage = useDocumentPageQuery()
  const documentId = documentPage.data?.documentId
  const projectId = documentPage.data?.project.id || ''

  if (!documentId || documentPage.isLoading) {
    return null
  }

  return (
    <Dialog open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
      <DialogTrigger
        disabled={disabled}
        className={cn(className, {
          'cursor-default': disabled,
        })}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="rounded-md hover:bg-gray-100 m-0 h-8 w-8 flex p-0 items-center justify-center"
                style={{
                  backgroundColor: disabled ? '#D2E8FF' : undefined,
                }}
              >
                <div className="flex flex-col items-center space-y-2 hover:color-primary cursor-pointer">
                  <SaveIcon className="w-5 h-5 stroke-2" />
                </div>
              </Button>
            </TooltipTrigger>
            <TooltipContent className="bg-black text-white">
              <p>Save document version</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <DialogContent
        className="min-h-56 max-w-xl gap-0 pb-0"
        ariaLabel="Create New Document Version"
      >
        <DialogHeader>
          <DialogTitle>Create New Document Version</DialogTitle>
        </DialogHeader>
        <div className="my-3">
          <CreateDocumentVersionForm
            documentId={documentId}
            projectId={projectId}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
