import { useState, useEffect } from 'react'
import type { CommandOption } from '../commands/types'
import slash from '../commands/slash'
import commands from '../commands'

export const useCommand = ({ query }: { query: string }) => {
  const [command, setCommand] = useState<CommandOption>(slash)

  useEffect(() => {
    const commandOption =
      commands
        .filter((cmd) => cmd.path !== '/')
        .find((command) => query.startsWith(command.path.slice(1))) || slash

    if (commandOption && commandOption.name !== command.name) {
      setCommand(commandOption)
    }
  }, [command, query])

  return command
}
