import { useShallow } from 'zustand/react/shallow'

import { CaretDownIcon } from '@radix-ui/react-icons'
import { ExplodeIcon } from '@/components/icons/ExplodeIcon'
import { ExplosionLineIcon } from '@/components/icons/ExplosionLineIcon'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { ControlButton } from '../ControlButton'
import { SaveExplosionsButton } from './SaveExplosionsButton'

import { useCADPageStore } from '@/pages/CADPage/state'
import { useCADQuery } from '@/services/queries/cads/queries/useCADQuery'
import { useExplosionsControls } from '../../CADModel/hooks/useExplosionsControls'

export const ExplosionsToolbar = () => {
  const { isLoading, data: cadData } = useCADQuery()
  const gltf = cadData.gltf

  const colorMap = useCADPageStore((state) => state.colorMap)
  const toggleExplosions = useCADPageStore((state) => state.toggleExplosions)
  const linesEnabled = useCADPageStore((state) => state.isExplosionLinesEnabled)
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const disableSectioning = useCADPageStore((state) => state.disableSectioning)

  const handleExplosions = useExplosionsControls()

  if (isLoading || !gltf) return null

  return (
    <div>
      <DropdownMenu open={explosionsToolbar} modal={false}>
        <DropdownMenuTrigger>
          <ControlButton
            isActive={explosionsToolbar}
            asChild
            title="Explosions"
            icon={
              <div className="flex flex-row items-center">
                <ExplodeIcon className="w-4 h-4" />
                <CaretDownIcon />
              </div>
            }
            onClick={() => {
              const { explosionsToolbar } = getCadPageState()
              if (!explosionsToolbar) {
                setCadPageState({ wandSelected: null, isEditModeActive: true })
                disableSectioning()
              } else {
                gltf.unhighlightParts(colorMap)
                setCadPageState({
                  highlightedPartUUID: null,
                  selectedParts: [],
                  isEditModeActive: false,
                })
              }
              toggleExplosions()
              handleExplosions()
            }}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex flex-row min-w-0">
          <DropdownMenuItem>
            <div>
              <ControlButton
                title="Explosion Lines"
                icon={<ExplosionLineIcon className="w-4 h-4" />}
                isActive={linesEnabled}
                onClick={() => {
                  setCadPageState({
                    isExplosionLinesEnabled: !linesEnabled,
                  })
                }}
              />
            </div>
          </DropdownMenuItem>
          <SaveExplosionsButton />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
