import { Html, useProgress } from '@react-three/drei'
import { Progress } from '@/components/ui/progress'

export const CADLoading = () => {
  const { progress } = useProgress()
  return (
    <Html center>
      <Progress value={progress} />
      <span className="text-xs text-gray-500 font-semibold">{progress}%</span>
    </Html>
  )
}
