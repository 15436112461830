import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * Create a new CAD
 * @param projectId
 * @param values
 * @returns
 */
export const createCad = async (
  projectId: string,
  values: { name: string },
) => {
  await getCsrf()
  const resp = await client.POST('/api/v1/projects/{project_id}/cads', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        project_id: projectId,
      },
    },
    body: values,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create CAD')
  }

  return resp.data
}

/**
 * Update a CAD
 * @param cadId
 * @param values
 * @returns
 */
export const updateCad = async (cadId: string, values: { name: string }) => {
  await getCsrf()
  const resp = await client.PUT('/api/v1/projects/cads/{cad_id}', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        cad_id: cadId,
      },
    },
    body: values,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to update CAD')
  }

  return resp.data
}

/**
 * List CADs
 * @param projectId
 * @returns
 */
export const listCads = async (projectId: string) => {
  const resp = await client.GET('/api/v1/projects/{project_id}/cads', {
    params: {
      path: {
        project_id: projectId,
      },
    },
  })
  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list CADs')
  }
  return resp.data
}

/**
 * Get a CAD
 * @param cadId
 * @returns
 */
export const getCad = async (cadId: string) => {
  const resp = await client.GET('/api/v1/projects/cads/{cad_id}', {
    params: {
      path: {
        cad_id: cadId,
      },
    },
  })
  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get CAD')
  }
  return resp.data
}
