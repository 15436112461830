import colors from './colors.json'

export const ntc = {
  init: function () {
    let color, rgb, hsl
    for (let i = 0; i < ntc.names.length; i++) {
      color = '#' + ntc.names[i][0]
      rgb = ntc.rgb(color)
      hsl = ntc.hsl(color)
      ntc.names[i].push(rgb[0], rgb[1], rgb[2], hsl[0], hsl[1], hsl[2])
    }
  },

  name: function (color) {
    color = color.toUpperCase()
    if (color.length < 3 || color.length > 7)
      return ['#000000', 'Invalid Color: ' + color, false]
    if (color.length % 3 == 0) color = '#' + color
    if (color.length == 4)
      color =
        '#' +
        color.substr(1, 1) +
        color.substr(1, 1) +
        color.substr(2, 1) +
        color.substr(2, 1) +
        color.substr(3, 1) +
        color.substr(3, 1)

    const rgb = ntc.rgb(color)
    const r = rgb[0],
      g = rgb[1],
      b = rgb[2]
    const hsl = ntc.hsl(color)
    const h = hsl[0],
      s = hsl[1],
      l = hsl[2]
    let ndf1 = 0,
      ndf2 = 0,
      ndf = 0
    let cl = -1,
      df = -1

    for (let i = 0; i < ntc.names.length; i++) {
      if (color == '#' + ntc.names[i][0])
        return ['#' + ntc.names[i][0], ntc.names[i][1], true]

      ndf1 =
        Math.pow(r - ntc.names[i][2], 2) +
        Math.pow(g - ntc.names[i][3], 2) +
        Math.pow(b - ntc.names[i][4], 2)
      ndf2 =
        Math.pow(h - ntc.names[i][5], 2) +
        Math.pow(s - ntc.names[i][6], 2) +
        Math.pow(l - ntc.names[i][7], 2)
      ndf = ndf1 + ndf2 * 2
      if (df < 0 || df > ndf) {
        df = ndf
        cl = i
      }
    }

    return cl < 0
      ? ['#000000', 'Invalid Color: ' + color, false]
      : ['#' + ntc.names[cl][0], ntc.names[cl][1], false]
  },

  hsl: function (color) {
    const rgb = [
      parseInt('0x' + color.substring(1, 3)) / 255,
      parseInt('0x' + color.substring(3, 5)) / 255,
      parseInt('0x' + color.substring(5, 7)) / 255,
    ]
    const r = rgb[0],
      g = rgb[1],
      b = rgb[2]

    const min = Math.min(r, Math.min(g, b))
    const max = Math.max(r, Math.max(g, b))
    const delta = max - min
    const l = (min + max) / 2

    let s = 0
    if (l > 0 && l < 1) s = delta / (l < 0.5 ? 2 * l : 2 - 2 * l)

    let h = 0
    if (delta > 0) {
      if (max == r && max != g) h += (g - b) / delta
      if (max == g && max != b) h += 2 + (b - r) / delta
      if (max == b && max != r) h += 4 + (r - g) / delta
      h /= 6
    }
    return [
      parseInt(`${h * 255}`),
      parseInt(`${s * 255}`),
      parseInt(`${l * 255}`),
    ]
  },

  rgb: function (color) {
    return [
      parseInt('0x' + color.substring(1, 3)),
      parseInt('0x' + color.substring(3, 5)),
      parseInt('0x' + color.substring(5, 7)),
    ]
  },

  names: colors as any,
}

ntc.init()
