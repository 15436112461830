import { useState } from 'react'
import { useRoute } from 'wouter'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { VerticalDotsIcon } from '@/components/icons/VerticalDotsIcon'
import { PDFDownloadButton } from './PDFDownloadButton'
import { CSVDownloadButton } from './CSVDownloadButton'

export const ShareActionsDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [matchDocumentPage] = useRoute(
    '/p/:projectId/document/:documentId/edit',
  )

  const shouldRenderActionsDropdown = matchDocumentPage

  if (!shouldRenderActionsDropdown) {
    return null
  }

  return (
    <DropdownMenu open={isDropdownOpen} modal={false}>
      <DropdownMenuTrigger
        className="h-9 px-4 py-2 rounded-md hover:bg-primary-30"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <VerticalDotsIcon className="w-4 h-4 stroke-white" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-2">
        <DropdownMenuItem className="p-0">
          <PDFDownloadButton onDownload={() => setIsDropdownOpen(false)} />
        </DropdownMenuItem>
        <DropdownMenuItem className="p-0">
          <CSVDownloadButton />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
