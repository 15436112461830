import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * Create a new Image
 * @param projectId
 * @param values
 * @returns
 */
export const createImage = async (
  documentVersionId: string,
  values: {
    source_type: 'screenshot'
    name: string
  },
) => {
  await getCsrf()
  const resp = await client.POST(
    '/api/v1/projects/{document_version_id}/images',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_version_id: documentVersionId,
        },
      },
      body: values,
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create Image')
  }

  return resp.data
}

/**
 * listImages
 * @param documentVersionId
 * @returns
 */
export const listImages = async (documentVersionId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/{document_version_id}/images',
    {
      params: {
        path: {
          document_version_id: documentVersionId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list images')
  }
  return resp.data
}

/**
 * getImage
 * @param imageId
 * @returns
 */
export const getImage = async (imageId: string) => {
  const resp = await client.GET('/api/v1/projects/images/{image_id}', {
    params: {
      path: {
        image_id: imageId,
      },
    },
  })
  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to find image')
  }
  return resp.data
}

/**
 * deleteImage
 * @param imageId
 * @returns
 */
export const deleteImage = async (imageId: string) => {
  await getCsrf()
  const resp = await client.DELETE('/api/v1/projects/images/{image_id}', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        image_id: imageId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to delete image')
  }

  return resp.data
}
