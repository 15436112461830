import { useCallback, useEffect, useMemo } from 'react'

import { useAnnotationsQuery, useRemoveAnnotation } from './queries'
import { useDocumentState } from '@/state'
import { isLine } from './utils'

export const useAnnotations = () => {
  const {
    newAnnotation,
    refPosition,
    isAddingAnnotation,
    triggerLocation,
    selectedAnnotation,
    canDropAnnotation,
    selectAnnotation,
    clearNewAnnotation,
    updateCanDrop,
    setLinePoint,
  } = useAnnotationsQuery()
  const { mutate: deleteSelectedAnnotation } = useRemoveAnnotation()
  const selectedPage = useDocumentState((state) => state.currentDocumentPageId)
  const zoom = useDocumentState((state) => state.zoom)
  const zoomPercentage = useMemo(() => zoom / 100, [zoom])

  const alignPlaceholder = useCallback(
    (x: number, y: number) => {
      const reference = refPosition || { x: 0, y: 0 }
      const id = newAnnotation?.id
      const iconPlaceholder = document.getElementById(
        `icon-placeholder--${id}`,
      ) as HTMLElement
      const { width, height } =
        iconPlaceholder.getBoundingClientRect() as DOMRect
      if (!iconPlaceholder) return

      iconPlaceholder.style.transform = `translateY(${
        y - reference.y - height / 2
      }px)`
      iconPlaceholder.style.transform += `translateX(${
        x - reference.x - width / 2
      }px)`
      iconPlaceholder.style.transform += `scale(${zoomPercentage})`
      iconPlaceholder.style.transformOrigin = '0 0'
    },
    [refPosition, newAnnotation, zoomPercentage],
  )

  const calculateCanDrop = useCallback(
    ({ mousePosition }: { mousePosition: { x: number; y: number } }) => {
      const id = newAnnotation?.id
      const iconPlaceholder = document.getElementById(
        `icon-placeholder--${id}`,
      ) as HTMLElement
      const container = document.getElementById(
        `droppable-container--${selectedPage}`,
      ) as HTMLElement

      if (!container || !iconPlaceholder) return
      const {
        left: containerLeft,
        top: containerTop,
        right: containerRight,
        bottom: containerBottom,
      } = container.getBoundingClientRect()
      const { width: placeholderWidth, height: placeholderHeight } =
        iconPlaceholder.getBoundingClientRect()
      const widthOffset = !isLine(id || '') ? placeholderWidth / 2 : 0
      const heightOffset = !isLine(id || '') ? placeholderHeight / 2 : 0

      const canDrop =
        mousePosition.x - widthOffset >= containerLeft &&
        mousePosition.x + widthOffset <= containerRight &&
        mousePosition.y - heightOffset >= containerTop &&
        mousePosition.y + heightOffset <= containerBottom &&
        selectedPage === newAnnotation?.documentPageId

      if (canDrop !== canDropAnnotation) updateCanDrop(canDrop)
    },
    [selectedPage, canDropAnnotation, newAnnotation?.id, updateCanDrop],
  )

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      alignPlaceholder(event.clientX, event.clientY)
      calculateCanDrop({
        mousePosition: { x: event.clientX, y: event.clientY },
      })
      if (newAnnotation?.startPoint) {
        const container = document.getElementById(
          `droppable-container--${selectedPage}`,
        ) as HTMLElement
        const { x, y } = container.getBoundingClientRect()
        setLinePoint({
          position: 'end',
          x: event.clientX / zoomPercentage - x / zoomPercentage,
          y: event.clientY / zoomPercentage - y / zoomPercentage,
        })
      }
    },
    [
      newAnnotation?.startPoint,
      selectedPage,
      zoomPercentage,
      setLinePoint,
      alignPlaceholder,
      calculateCanDrop,
    ],
  )

  const onStopNewAnnotation = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') clearNewAnnotation()
    },
    [clearNewAnnotation],
  )

  const addNewAnnotationListeners = useCallback(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('keydown', onStopNewAnnotation)
  }, [onMouseMove, onStopNewAnnotation])

  const removeNewAnnotationListeners = useCallback(() => {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('keydown', onStopNewAnnotation)
  }, [onMouseMove, onStopNewAnnotation])

  useEffect(() => {
    if (isAddingAnnotation && triggerLocation) {
      addNewAnnotationListeners()
    } else if (!isAddingAnnotation) {
      removeNewAnnotationListeners()
    }

    return () => removeNewAnnotationListeners()
  }, [
    isAddingAnnotation,
    triggerLocation,
    addNewAnnotationListeners,
    removeNewAnnotationListeners,
  ])

  useEffect(() => {
    if (isAddingAnnotation && triggerLocation) {
      alignPlaceholder(triggerLocation.x, triggerLocation.y)
    }
  }, [isAddingAnnotation, triggerLocation])

  const unselectAnnotation = useCallback(() => {
    selectAnnotation(null)
  }, [selectAnnotation])

  const onRemoveAnnotation = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteSelectedAnnotation()
      }
    },
    [deleteSelectedAnnotation],
  )

  useEffect(() => {
    if (selectedAnnotation) {
      document.addEventListener('click', unselectAnnotation)
      document.addEventListener('keydown', onRemoveAnnotation)
    } else {
      document.removeEventListener('click', unselectAnnotation)
      document.removeEventListener('keydown', onRemoveAnnotation)
    }

    return () => {
      document.removeEventListener('click', unselectAnnotation)
      document.removeEventListener('keydown', onRemoveAnnotation)
    }
  }, [selectedAnnotation, unselectAnnotation, onRemoveAnnotation])
}
