export const ListBulletIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.42857 8.9984H13M4.42857 4.9992H13M4.42857 1H13M1.00167 8.9984V9L1 8.99996V8.9984H1.00167ZM1.00167 4.9992V5.0008L1 5.00076V4.9992H1.00167ZM1.00167 1V1.0016L1 1.00156V1H1.00167Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
