import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Files } from '@/components/ui/file'
import { Loading } from '@/components/ui/loading'
import { useReuploadCadMutation } from '../queries'
import { useCadPageParams } from '@/pages/CADPage/hooks'

export const ReuploadCadButton = ({
  cadVersionId,
}: {
  cadVersionId: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button onClick={() => setIsOpen(true)}>Reupload CAD</Button>
      </DialogTrigger>
      <DialogContent ariaLabel="Reupload CAD dialog">
        <DialogHeader>
          <DialogTitle>Reupload CAD</DialogTitle>
        </DialogHeader>
        <UploadForm cadVersionId={cadVersionId} />
      </DialogContent>
    </Dialog>
  )
}

const formSchema = z.object({
  files: z.array(z.any()).min(1, {
    message: 'Upload at least 1 CAD file.',
  }),
})

export const UploadForm = ({ cadVersionId }: { cadVersionId: string }) => {
  const { projectId, documentId } = useCadPageParams()
  const form = useForm<{ files: File[] }>({
    resolver: zodResolver(formSchema),
  })
  const { isPending, mutate: reuploadCad } = useReuploadCadMutation()

  useEffect(() => {
    form.reset()
  }, [])

  function onSubmit(values: z.infer<typeof formSchema>) {
    const file = values.files[0]
    reuploadCad({
      cadVersionId,
      documentId,
      projectId,
      file,
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <Files
          onChange={(files) => {
            form.setValue('files', files)
          }}
          validateFile={(file) => {
            form.setError('files', {})
            const validExtentions = ['step', 'stp']
            const tok = file.name.toLowerCase().split('.')
            const ext = tok[tok.length - 1]

            const hasValidFileExtention = validExtentions.includes(ext)
            if (!hasValidFileExtention) {
              form.setError('files', {
                type: 'extention',
                message: `Only files with the following extensions are allowed: ${validExtentions.join(
                  ', ',
                )}.`,
              })
            }

            const isValid = hasValidFileExtention

            return isValid
          }}
          error={form.formState.errors.files?.message}
        />
        <Button type="submit" disabled={isPending}>
          {isPending ? (
            <div className="flex items-center space-x-2">
              <span>Uploading</span>
              <Loading />
            </div>
          ) : (
            'Upload'
          )}
        </Button>
      </form>
    </Form>
  )
}
