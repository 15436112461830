import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { Editor as TipTapEditor } from '@tiptap/react'
import { ResizableBox } from 'react-resizable'
import debounce from 'lodash.debounce'
import max from 'lodash.max'

import {
  Label,
  TemplateAttribute,
  useDeleteLabel,
  useUpdateTemplateElement,
} from '@/services/hooks/template_attributes'
import { Editor } from '@/components/editor/Editor'
import { useDocumentState } from '@/state'
import { useEditDocumentPageLabel } from '@/services/queries/document_label/useEditDocumentPageLabel'
import { cn } from '@/utils'
import { Spinner } from '@/components/ui/spinner'
import { ExtensionsEnum } from '@/components/editor/constants'

interface OperationLabelProps {
  documentId: string
  documentPageId: string
  projectId: string
  isAddingAnnotation: boolean
  isReadOnly: boolean
  labelTemplate: TemplateAttribute<Label>
}

export const OperationLabel = ({
  documentId,
  documentPageId,
  projectId,
  isAddingAnnotation,
  isReadOnly,
  labelTemplate,
}: OperationLabelProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])

  const containerRef = useRef<HTMLDivElement>(null)
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const { mutateAsync: updateTemplate, isPending: isUpdatingTemplate } =
    useUpdateTemplateElement({
      documentPageId,
    })
  const { mutateAsync: removeLabel, isPending: isRemovingLabel } =
    useDeleteLabel()
  const zoom = useDocumentState((state) => state.zoom)
  const zoomRatio = useMemo(() => zoom / 100, [zoom])
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === labelTemplate.id,
    [selectedElement, documentPageId, labelTemplate.id],
  )
  const positionData = useMemo(
    () => labelTemplate?.template_values?.position,
    [labelTemplate],
  )
  const size = useMemo(
    () => labelTemplate?.template_values?.size,
    [labelTemplate],
  )

  const deleteLabelHandler = useCallback(() => {
    if (labelTemplate) {
      removeLabel(labelTemplate.id as string)
    }
  }, [labelTemplate, removeLabel])

  const updateLabelSizeHandler = useCallback(
    async (size: { width: number; height: number }) => {
      await updateTemplate({
        payload: {
          size,
        },
        data_type: 'label',
        templateAttributeId: labelTemplate.id as string,
      })
    },
    [labelTemplate.id, updateTemplate],
  )

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteLabelHandler()
      }
    },
    [deleteLabelHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    let resizeObserver: ResizeObserver
    const minDimensions = { width: 154, height: 25 }
    if (containerRef.current && !size) {
      const rect = containerRef.current.getBoundingClientRect()
      const width = max([rect.width, minDimensions.width])
      const height = max([rect.height, minDimensions.height])
      setContainerDimensions({
        width,
        height,
      })

      resizeObserver = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect

        setContainerDimensions({
          width: max([width, minDimensions.width]),
          height: max([height, minDimensions.height]),
        })
      })

      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [containerRef, size])

  useEffect(() => {
    if (size) {
      setContainerDimensions(size)
    }
  }, [size])

  const [{ position, delta }, setPosition] = useState({
    position: positionData,
    delta: INITIAL_DELTA,
  })

  const { mutate: updateLabel } = useEditDocumentPageLabel({
    labelTemplate,
    documentPageId,
  })

  const containerId = useMemo(
    () => `${documentPageId}-${labelTemplate.id}`,
    [documentPageId, labelTemplate.id],
  )

  const { attributes, isDragging, listeners, transform, setNodeRef } =
    useDraggable({
      disabled: isAddingAnnotation || isReadOnly,
      id: labelTemplate.id as string,
      data: { type: 'label' },
    })

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: (transform?.x || 0) / zoomRatio,
        y: (transform?.y || 0) / zoomRatio,
      },
    }))
  }, [transform, zoomRatio])

  useEffect(() => {
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  const saveLabel = useCallback(
    debounce(({ editor }: { editor: TipTapEditor }) => {
      updateLabel({
        editor,
      })
    }, 500),
    [updateLabel],
  )

  const mutatedListeners = useMemo(() => {
    return {
      ...listeners,
      onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => {
        const targetId = (e.target as Element)?.id
        if (targetId === containerId) {
          listeners?.onMouseDown(e)
        }
      },
      onTouchStart: (e: React.TouchEvent<Element>) => {
        const targetId = (e.target as Element)?.id
        if (targetId === containerId) {
          listeners?.onMouseDown(e)
        }
      },
    }
  }, [listeners, containerId])

  const isLoading = useMemo(
    () => isRemovingLabel || isUpdatingTemplate,
    [isRemovingLabel, isUpdatingTemplate],
  )

  return (
    <div
      id={containerId}
      ref={setNodeRef}
      className={cn('border-4 flex rounded-md box-content absolute', {
        'cursor-move': !isReadOnly,
        'cursor-default': isReadOnly,
        'rounded-md': isSelected,
        'shadow-md': isDragging,
        'shadow-gray-200': isDragging,
        'bg-sky-200/10': isDragging,
        'pointer-events-none': isAddingAnnotation,
        'pointer-events-auto': !isAddingAnnotation,
        'z-[1]': !isDragging,
        'z-[100]': isDragging,
      })}
      {...attributes}
      {...mutatedListeners}
      style={{
        translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
        top: position?.y || 0,
        left: position?.x || 0,
        borderColor: isSelected ? 'rgba(129, 140, 248, 0.5)' : 'transparent',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div ref={containerRef}>
        <ResizableBox
          width={containerDimensions.width}
          height={containerDimensions.height}
          minConstraints={[154, 25]}
          maxConstraints={[740, 420]}
          onResizeStop={(_event, data) => updateLabelSizeHandler(data.size)}
          resizeHandles={!isReadOnly && isSelected ? ['se'] : []}
        >
          <>
            {isLoading && (
              <div className="absolute h-full w-full bg-zinc-400/60 z-10">
                <Spinner />
              </div>
            )}
            <Editor
              extensionsList={[
                ExtensionsEnum.COLOR,
                ExtensionsEnum.ICONS,
                ExtensionsEnum.HIGHLIGHT,
                ExtensionsEnum.MENTION,
                ExtensionsEnum.UNDERLINE,
                ExtensionsEnum.SLASH_COMMAND,
              ]}
              menuScale={0.6}
              projectId={projectId}
              documentId={documentId}
              documentPageId={documentPageId}
              className={cn('flex-1 py-0 px-1 document-label min-h-0 text-xs', {
                'pointer-events-none': isAddingAnnotation || isDragging,
              })}
              isAddingAnnotation={isAddingAnnotation}
              onBlur={saveLabel}
              onClick={() => {
                setSelectedElement({
                  id: labelTemplate.id as string,
                  documentPageId,
                  isRemovable: false,
                })
              }}
              onUpdate={saveLabel}
              content={labelTemplate?.template_values?.content ?? ''}
              disabled={isReadOnly}
              placeholder="Write something..."
              isDragging={isDragging}
              updateBomWithSlashCommands={true}
            />
          </>
        </ResizableBox>
      </div>
    </div>
  )
}
