import { create } from 'zustand'
import { v4 as uuidv4 } from 'uuid'

import { DocumentPage, DocumentTypeChoices } from '@/lib/api/client'

export type RawAssemblyTree = {
  root: string
  nodes: ASMTreeNode[]
}

export type ASMTreeNode = {
  uuid: string
  instance: string
  product: string
  display_name: string
  group: boolean
  parent?: string | null
  children: string[]
  visible?: boolean
  document_page_id?: string | null
  document_page_id_array?: string[]
  depth?: number
}

export type BillOfMaterial = {
  partName: string
  quantity: number
  order?: number
  UUIDs?: Array<string>
}

export type BillOfMaterials = BillOfMaterial[]

export type ToolsBillOfMaterials = {
  toolName: string
  order?: number
}[]

export const useAssemblyTree = create<{
  tree: RawAssemblyTree | null
  setAssemblyTree: (
    tree: RawAssemblyTree | null,
    documentPages: DocumentPage[],
  ) => void
  getAssemblyTree: () => RawAssemblyTree | null
  getRoot: () => ASMTreeNode | undefined
  getNode: (uuid: string) => ASMTreeNode | undefined
  getNodeByInstanceName: (instanceName?: string) => ASMTreeNode | undefined
  getOperationNodes: () => ASMTreeNode[]
  setDocumentPageId: (
    uuid: string,
    documentPageId: string,
    documentType: DocumentTypeChoices,
  ) => void
  setVisibility: (uuid: string, visible: boolean) => void
  invisibleParts: string[]
  renameNode: (uuid: string, name: string) => void
  moveNodeToIndex: (uuid: string, index: number) => void
  groupNodes: (uuids: string[]) => ASMTreeNode | undefined
  ungroupNodes: (groupUUID: string) => void
  getNodeAndDescendants: (node: ASMTreeNode) => ASMTreeNode[]
}>((set, get) => ({
  tree: null,
  invisibleParts: [],
  setAssemblyTree: (
    tree: RawAssemblyTree | null,
    documentPages: DocumentPage[] = [],
  ) =>
    set((state) => {
      if (tree && tree.nodes) {
        // Instances map to threejs object names, so we need to follow
        // the same rules for naming
        tree.nodes.forEach((node) => {
          const prevNode = state.getNode(node.uuid)
          node.instance = node.instance.startsWith('Group:')
            ? node.instance
            : node.instance.replace(' ', '_').replace(':', '')
          node.visible = prevNode ? prevNode.visible : true
          node.document_page_id = undefined
        })

        if (Array.isArray(documentPages) && documentPages.length > 0) {
          tree.nodes.forEach((node) => {
            const page = documentPages.find(
              (p) => p.assembly_group_id === node.uuid,
            )
            node.document_page_id = page ? page.id : undefined
          })
        }
        return { tree }
      } else {
        return { tree }
      }
    }),

  getAssemblyTree: () => get().tree,

  getRoot: () => {
    const rootUUID = get().tree?.root
    if (rootUUID) {
      return get().tree?.nodes.find((node) => node.uuid === rootUUID)
    }
  },

  getNode: (uuid: string) =>
    get().tree?.nodes?.find((node) => node.uuid === uuid),

  getNodeByInstanceName: (instanceName?: string) => {
    if (!instanceName) {
      return
    }
    return get().tree?.nodes.find((node) => node.instance === instanceName)
  },

  getOperationNodes: () => {
    const nodes = get().tree?.nodes
    return nodes?.filter((node) => node.document_page_id || node.group) || []
  },

  setDocumentPageId: (
    uuid: string,
    documentPageId: string,
    documentType: DocumentTypeChoices,
  ) => {
    set((state) => {
      const tree = { ...(state.tree ?? {}) } as RawAssemblyTree
      const nodes = tree?.nodes
      const node = nodes?.find((node) => node.uuid === uuid)
      if (node) {
        if (documentType === 'work_instructions') {
          node.document_page_id = documentPageId
        } else if (documentType === 'project_tracker') {
          node.document_page_id_array = node.document_page_id_array ?? []
          node.document_page_id_array = [
            ...node.document_page_id_array,
            documentPageId,
          ]
        }
      }
      return { tree }
    })
  },

  setVisibility: (uuid: string, visible: boolean) => {
    set((state) => {
      const tree = { ...(state.tree ?? {}) } as RawAssemblyTree
      const nodes = tree?.nodes
      const node = nodes?.find((node) => node.uuid === uuid)
      if (node) {
        node.visible = visible

        const updatedInvisibleParts = visible
          ? state.invisibleParts.filter((part) => part !== node.instance)
          : [...state.invisibleParts, node.instance]
        return {
          tree,
          invisibleParts: [...new Set(updatedInvisibleParts)],
        }
      }

      return { tree }
    })
  },

  renameNode: (uuid: string, name: string) => {
    set((state) => {
      const tree = { ...(state.tree ?? {}) } as RawAssemblyTree
      const nodes = tree?.nodes
      const node = nodes?.find((node) => node.uuid === uuid)
      if (node) {
        node.display_name = name
      }
      return { tree }
    })
  },

  moveNodeToIndex: (uuid: string, index: number) => {
    set((state) => {
      const tree = { ...(state.tree ?? {}) } as RawAssemblyTree
      const nodes = tree?.nodes
      const root = nodes?.find((node) => node.uuid === tree?.root)
      const node = nodes?.find((node) => node.uuid === uuid)
      const nodeIndex = root?.children.indexOf(uuid)

      if (root && node && nodeIndex !== undefined && nodeIndex >= 0) {
        const newChildren = [...root.children].filter(
          (uuid) => uuid !== node.uuid,
        )
        root.children = [
          ...new Set([
            ...newChildren.slice(0, index),
            node.uuid,
            ...newChildren.slice(index),
          ]),
        ]
      }

      return { tree }
    })
  },

  groupNodes: (uuids: string[]) => {
    let groupNode: ASMTreeNode | undefined
    set((state) => {
      const tree = { ...(state.tree ?? {}) } as RawAssemblyTree
      const nodes = tree?.nodes
      const root = nodes?.find((node) => node.uuid === tree?.root)

      const firstNode = nodes?.find((node) => node.uuid === uuids[0])
      const firstNodeIndex = firstNode && root?.children.indexOf(firstNode.uuid)

      const canCreateGroup =
        uuids.length > 0 &&
        root &&
        firstNode &&
        firstNodeIndex !== undefined &&
        firstNodeIndex >= 0

      if (canCreateGroup) {
        const groupUUID = uuidv4()
        const groupChildren: string[] = []
        const groupsToDelete: string[] = []
        const childInstances: string[] = []

        uuids.forEach((uuid) => {
          const node = nodes?.find((node) => node.uuid === uuid)
          if (node) {
            if (node.group) {
              groupsToDelete.push(node.uuid)
              node.children.forEach((childUUID) => {
                groupChildren.push(childUUID)
                const childNode = nodes?.find((n) => n.uuid === childUUID)
                if (childNode) {
                  childInstances.push(childNode.instance)
                }
              })
            } else {
              node.parent = groupUUID
              groupChildren.push(node.uuid)
              childInstances.push(node.instance)
            }
          }
        })

        if (childInstances.length !== new Set(childInstances).size) {
          throw new Error('Duplicate instances found in child instances array')
        }

        const instanceName = `Group:${childInstances.join(':')}`
        groupNode = {
          uuid: groupUUID,
          instance: instanceName,
          product: instanceName,
          display_name: `Group - ${
            firstNode?.display_name ||
            firstNode?.product ||
            firstNode?.instance ||
            '01'
          }`,
          group: true,
          parent: root?.uuid || null,
          children: groupChildren,
          visible: true,
        }

        const newChildren = [
          ...root.children.slice(0, firstNodeIndex),
          groupNode.uuid,
          ...root.children.slice(firstNodeIndex),
        ]

        if (newChildren.length !== new Set(newChildren).size) {
          throw new Error('Duplicate UUIDs found in new children array')
        }

        root.children = newChildren.filter(
          (uuid) =>
            !groupChildren.includes(uuid) && !groupsToDelete.includes(uuid),
        )

        tree.nodes = [
          ...nodes.slice(0, firstNodeIndex),
          groupNode,
          ...nodes.slice(firstNodeIndex),
        ].filter((node) => !groupsToDelete.includes(node.uuid))
      }

      return { tree }
    })
    return groupNode
  },

  ungroupNodes: (groupUUID: string) => {
    set((state) => {
      const tree = { ...(state.tree ?? {}) } as RawAssemblyTree
      const nodes = tree?.nodes
      const root = nodes?.find((node) => node.uuid === tree?.root)
      const groupNode = nodes?.find((node) => node.uuid === groupUUID)

      if (groupNode && root && nodes) {
        const groupIndex = root.children.indexOf(groupNode.uuid)
        const newChildren: string[] = [
          ...root.children.slice(0, groupIndex),
          ...groupNode.children,
          ...root.children.slice(groupIndex),
        ]

        if (newChildren.length !== new Set(newChildren).size) {
          throw new Error('Duplicate UUIDs found in new children array')
        }

        root.children = newChildren.filter((uuid) => uuid !== groupNode.uuid)

        tree.nodes = nodes.filter((node) => node.uuid !== groupNode.uuid)

        root.children.forEach((uuid) => {
          const node = nodes.find((node) => node.uuid === uuid)
          if (node) {
            node.parent = root.uuid
          }
        })
      }

      return { tree }
    })
  },

  getNodeAndDescendants: (node: ASMTreeNode): ASMTreeNode[] => {
    const descendants: ASMTreeNode[] = []

    const traverse = (currentNode: ASMTreeNode, depth: number) => {
      currentNode.depth = depth
      descendants.push(currentNode)
      currentNode.children.forEach((childUUID) => {
        const childNode = get().getNode(childUUID)
        if (childNode) traverse(childNode, depth + 1)
      })
    }

    traverse(node, 0)
    return descendants
  },
}))
