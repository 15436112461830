export function formatNumber(num) {
  if (num >= 1.0e12) {
    // Trillion
    return (num / 1.0e12).toFixed(1) + 'T'
  } else if (num >= 1.0e9) {
    // Billion
    return (num / 1.0e9).toFixed(1) + 'B'
  } else if (num >= 1.0e6) {
    // Million
    return (num / 1.0e6).toFixed(1) + 'M'
  } else if (num >= 1.0e3) {
    // Thousand
    return (num / 1.0e3).toFixed(1) + 'K'
  } else {
    // Less than 1 thousand
    return num.toString()
  }
}
