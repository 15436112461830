import { Node } from '@tiptap/core'

export const Submit = Node.create({
  addStorage() {
    return {
      isSelecting: false,
    }
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const { onSubmit } = this.options
        if (!onSubmit) return false
        const { isEmpty, storage } = this.editor
        const canSubmit = !isEmpty && !storage.isSelecting
        const content = this.editor.getHTML()

        if (canSubmit) {
          onSubmit(content)
        }

        return false
      },
    }
  },
})
