import { Image, View, DocumentTypeChoices } from '@/lib/api/client'

export function getMaxViewsInDocument(
  documentType?: DocumentTypeChoices,
): number {
  switch (documentType) {
    case 'work_instructions':
      return 5
    case 'project_tracker':
      return 1
    default:
      return 2
  }
}

const isView = (view: View | Image): view is View => {
  return (view as View).document_page_order !== undefined
}

export function cleanAndFilterViews({
  images,
  documentImages,
  viewData,
  documentPageId,
  documentType,
}: {
  images?: Image[]
  documentImages: string[]
  viewData: View[]
  documentPageId: string
  documentType: DocumentTypeChoices
}): View[] | Image[] {
  const imagesInOperation = (images || [])
    .filter((image) => documentImages.find((id) => id === image.id))
    .map((image) => ({
      ...image,
      document_order: undefined,
      type: 'image',
    }))

  const views = viewData || []
  const viewsInOperation = views
    .filter((view) => view.document_page === documentPageId)
    .map((view) => ({
      ...view,
      type: 'view',
    }))
  const viewsWithoutPosition = viewsInOperation.filter(
    (view) =>
      view.document_page_order === undefined ||
      view.document_page_order === null,
  )

  const findNextViewWithoutPosition = (cleanViews: Array<View>) => {
    const imagesWithoutPosition = [
      ...viewsWithoutPosition,
      ...imagesInOperation,
    ]
    for (const image of imagesWithoutPosition) {
      const foundView = cleanViews.find(
        (currentView) => image.id === currentView.id,
      )
      if (!foundView) {
        return image
      }
    }
  }

  return [...viewsInOperation, ...imagesInOperation]
    .reduce(
      (accum, view) => {
        if (
          isView(view) &&
          view.document_page_order !== undefined &&
          view.document_page_order !== null
        ) {
          accum[view.document_page_order] = view
        }

        return accum
      },
      Array(getMaxViewsInDocument(documentType)).fill({
        type: 'empty',
      }),
    )
    .reduce((accum, view, i) => {
      if (view.type === 'empty') {
        const viewWithoutPosition = findNextViewWithoutPosition(accum)

        if (viewWithoutPosition) {
          accum[i] = viewWithoutPosition
        } else {
          accum[i] = view
        }
      } else {
        accum[i] = view
      }

      return accum
    }, [])
}
