import { create } from 'zustand'

interface PDFifyState {
  pageRefs: {
    [pageId: string]: {
      ref: HTMLDivElement
      order: number
    }
  }
  imageUrls: string[]
  downloadUrl?: string | null
  isGenerating: boolean
  setImageUrls: (imageUrls: string[]) => void
  setIsGenerating: (isGenerating: boolean) => void
  addPageRef: (pageId: string, order: number, ref: HTMLDivElement) => void
  removePageRef: (pageId) => void
}

export const usePDFifyStore = create<PDFifyState>((set) => ({
  imageUrls: [],
  pageRefs: {},
  isGenerating: false,

  setImageUrls: (imageUrls) => set({ imageUrls }),

  setIsGenerating: (isGenerating) => set({ isGenerating }),

  addPageRef: (pageId, order, ref) =>
    set((state) => ({
      pageRefs: { ...state.pageRefs, [pageId]: { ref, order } },
    })),

  removePageRef: (pageId) =>
    set((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [pageId]: _, ...rest } = state.pageRefs
      return { pageRefs: rest }
    }),
}))
