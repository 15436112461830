import { useState } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  DialogOverlay,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { CreateDocumentForm } from './CreateDocumentForm'

export const CreateDocumentButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          onClick={() => setIsOpen(true)}
          className="rounded-full h-8 px-4 py-2 border border-white text-xs text-primary-40 bg-white flex items-center space-x-1 drop-shadow-none"
        >
          <PlusIcon className="h-4 w-4" />
          <span>New Document</span>
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="DialogOverlay">
          <DialogContent
            className="min-h-96 max-h-full max-w-3xl pb-5 overflow-auto"
            ariaLabel="Create new document dialog"
          >
            <DialogHeader>
              <DialogTitle>Create New Document</DialogTitle>
            </DialogHeader>
            <CreateDocumentForm />
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  )
}
