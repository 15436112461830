import { cn } from '@/utils'

export const AddPartIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M1 11H8M13 10H16M16 10H19M16 10V13M16 10V7M1 6H12M1 1H12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
