import { cn } from '@/utils'

type BadgeProps = {
  label: string
  type?: 'warning' | 'error' | 'success' | 'neutral'
}
export const Badge = ({ label, type = 'neutral' }: BadgeProps) => (
  <span
    className={cn('inline-block rounded-full px-1.5 py-0 mx-1.5 h-auto', {
      'bg-slate-200': type === 'neutral',
      'bg-amber-400': type === 'warning',
      'bg-red-400': type === 'error',
      'bg-emerald-400': type === 'success',
    })}
    style={{
      fontSize: '0.75rem',
    }}
  >
    {label}
  </span>
)

export default Badge
