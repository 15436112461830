import { useDocumentVersion } from '../../ScreenshotPanelButton/hooks/useDocumentVersion'
import { useCadVersion } from '../../ScreenshotPanelButton/hooks/useCadVersions'
import { useListDocumentVersionViews } from '@/services/queries/views'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useCADQuery } from '@/services/queries/cads'

export const useScreenshots = () => {
  const { isLoading: isCadLoading, data: cadData } = useCADQuery()

  const cadVersionId = cadData?.version?.id

  const { documentId, dv: selectedDocumentVersionId } = useCadPageParams()

  const { isLoading: isDocumentVersionLoading, data: documentVersion } =
    useDocumentVersion({
      documentId,
      documentVersionId: selectedDocumentVersionId,
    })

  const { isLoading: isCadVersionLoading, data: cadVersion } = useCadVersion({
    documentId,
    cadVersionId,
  })

  const { isLoading: isViewsLoading, data: views } =
    useListDocumentVersionViews({
      cadVersionId: cadVersion?.id,
      documentVersionId: documentVersion?.id,
    })

  const isLoading =
    isDocumentVersionLoading ||
    isCadVersionLoading ||
    isViewsLoading ||
    isCadLoading

  const screenshots = (views || []).filter(
    (view) => view.source_type === 'screenshot',
  )

  return {
    isLoading,
    data: screenshots,
  }
}
