import { cn } from '@/utils'
import { ReactNode } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { useCADPageStore } from '@/pages/CADPage/state'
import type { Step } from '@/services/queries/operation_steps/types'
import { useCadPageParams } from '../../hooks'
import { GLTFObject } from '@/lib/cad/GLTFObject'

export const StepCardContainer = ({
  children,
  step,
  gltf,
}: {
  children: ReactNode
  step: Step
  gltf?: GLTFObject
  isReadOnly?: boolean
}) => {
  const { documentId } = useCadPageParams()
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const isActive = activeOperationStep?.stepId === step.id
  const selectFromCad = isActive && activeOperationStep?.selectFromCad
  const colorMap = useCADPageStore((state) => state.colorMap)

  return (
    <div
      className={cn('border border-gray-300 rounded-lg p-3 bg-white text-sm', {
        'bg-primary-10': isActive,
        'border-primary-50': isActive,
        'bg-teal-50': selectFromCad,
        'border-teal-600': selectFromCad,
      })}
      onMouseDown={() => {
        gltf?.unhighlightParts(colorMap)
        setCadPageState({
          selectedParts: [],
          operationStep: {
            documentId,
            stepId: step.id as string,
            selectFromCad: false,
            isActive: Boolean(isActive),
          },
        })
      }}
    >
      {children}
    </div>
  )
}
