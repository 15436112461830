import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useToast } from '@/components/ui/use-toast'
import {
  useCreateTemplateAttribute,
  useUpdateTemplateAttribute,
  useListTemplateAttributes,
  useDeleteTemplateAttribute,
  listTemplateAttributesQuery,
  createTemplateAttributeMutation,
  updateTemplateAttributeMutation,
} from '@/services/queries/template_attributes'
import { MUTATION_KEYS } from '@/services/queries/template_attributes'
import {
  TemplateAttribute as TemplateAttributeType,
  TemplateAttributeTypes,
} from '@/lib/api/client'
import {
  Annotation,
  Line,
} from '@/pages/DocumentPage/components/Annotations/queries'
import queryClient from '@/queryClient'

export type TemplateAttribute<T> = TemplateAttributeType & {
  template_values: T
}
export type Position = {
  x: number
  y: number
}
export type Size = {
  width: number
  height: number
}
export type Metadata = {
  size?: Size
  position?: Position
}
export type Note = Metadata & {
  editorVersion: number
  content: string
  viewId: string
  notesPosition?: 'absolute' | 'relative'
}

export type Label = Metadata & {
  content: string
}

export type ImageTemplate = Metadata & {
  imageId: string
  imagePosition?: 'relative' | 'absolute'
}

export type ViewTemplate = Metadata & {
  viewId: string
  imagePosition?: 'relative' | 'absolute'
}

export type TableColumn = {
  [accessor: string]: string
}

export type TableRow = {
  [accessor: string]: string
}

export type Table = Metadata & {
  columns?: Array<TableColumn>
  rows?: Array<TableRow>
  type?: string
  columnsSize?: {
    [accessor: string]: number
  }
  hidden?: boolean
  isManual?: boolean
}

export const useCreateDocumentNotes = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      notesData,
    }: {
      documentPageId: string
      notesData: any
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'note',
            template_values: notesData,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateDocumentNotes = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute({
    shouldOptimisticUpdate: false,
  })

  return useMutation({
    mutationFn: async ({
      templateAttributeId,
      notesData,
    }: {
      templateAttributeId: string
      notesData: any
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'note',
            template_values: notesData,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteNotes = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListNotes = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Notes type
  const notes: Array<TemplateAttribute<Note>> = useMemo(() => {
    if (isSuccess && data) {
      return data.filter(
        (templateAttribute) => templateAttribute.data_type === 'note',
      )
    }

    return []
  }, [data, isSuccess])

  return notes
}

export const useCreateLabel = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      labelData,
    }: {
      documentPageId: string
      labelData: Label
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'label',
            template_values: labelData as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateLabel = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute({
    shouldOptimisticUpdate: false,
  })

  return useMutation({
    mutationFn: async ({
      templateAttributeId,
      notesData,
    }: {
      templateAttributeId: string
      notesData: Label
    }) => {
      if (templateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'label',
            template_values: notesData as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteLabel = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListLabels = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Labels type
  const labels: Array<TemplateAttribute<Label>> = useMemo(() => {
    if (isSuccess && data) {
      return data.filter(
        (templateAttribute) => templateAttribute.data_type === 'label',
      )
    }

    return []
  }, [data, isSuccess])

  return labels
}

export const useCreateViewTemplate = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      viewTemplateData,
    }: {
      documentPageId: string
      viewTemplateData: any
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'view',
            template_values: viewTemplateData,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListViewsTemplates = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Views type
  const views: Array<TemplateAttribute<ViewTemplate>> = useMemo(() => {
    if (isSuccess && data) {
      return data.filter(
        (templateAttribute) => templateAttribute.data_type === 'view',
      )
    }

    return []
  }, [data, isSuccess])

  return views
}

export const getNotesQuery = async (documentPageId?: string) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  // @ts-expect-error - Defining Notes type
  const notes: Array<TemplateAttribute<Note>> = templateAttributes.filter(
    (templateAttribute) => templateAttribute.data_type === 'note',
  )

  return notes
}

export const useCreateAnnotation = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: ({
      documentPageId,
      annotationData,
    }: {
      documentPageId: string
      annotationData: Annotation | Line
    }) => {
      return createTemplateAttribute({
        documentPageId,
        payload: {
          data_type: 'annotation',
          template_values: annotationData as any,
        },
      })
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateAnnotation = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationFn: async (updatedAnnotation: Annotation | Line) => {
      if (updatedAnnotation.id) {
        await updateTemplateAttribute({
          templateAttributeId: updatedAnnotation.id,
          payload: {
            data_type: 'annotation',
            template_values: updatedAnnotation as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteAnnotation = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListAnnotations = (documentPageId?: string) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Annotation type
  const annotations: Array<TemplateAttribute<Annotation | Line>> =
    useMemo(() => {
      if (isSuccess && data) {
        return data.filter(
          (templateAttribute) => templateAttribute.data_type === 'annotation',
        )
      }

      return []
    }, [data, isSuccess])

  return annotations
}

export const getAnnotationsQuery = async (documentPageId?: string | null) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  // @ts-expect-error - Defining Annotation type
  const annotations: Array<TemplateAttribute<Annotation | Line>> =
    templateAttributes.filter(
      (templateAttribute) => templateAttribute.data_type === 'annotation',
    )

  return annotations
}

export const useCreateImageTemplate = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationFn: ({
      documentPageId,
      imageData,
    }: {
      documentPageId: string
      imageData: ImageTemplate
    }) => {
      return createTemplateAttribute({
        documentPageId,
        payload: {
          data_type: 'image',
          template_values: imageData as any,
        },
      })
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useDeleteImageTemplate = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useListImageTemplates = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  // @ts-expect-error - Defining Image template type
  const imageTemplates: Array<TemplateAttribute<ImageTemplate>> =
    useMemo(() => {
      if (isSuccess && data) {
        return data.filter(
          (templateAttribute) => templateAttribute.data_type === 'image',
        )
      }

      return []
    }, [data, isSuccess])

  return imageTemplates
}

export const getImageTemplatesQuery = async (
  documentPageId?: string | null,
) => {
  if (!documentPageId) return []

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return []
  // @ts-expect-error - Defining Image template type
  const imageTemplates: Array<TemplateAttribute<ImageTemplate>> =
    templateAttributes.filter(
      (templateAttribute) => templateAttribute.data_type === 'image',
    )

  return imageTemplates
}

export const useListTables = (documentPageId?: string | null) => {
  const { data, isSuccess } = useListTemplateAttributes({ documentPageId })
  const tables: Array<TemplateAttribute<Table>> | undefined = useMemo(() => {
    if (isSuccess && data) {
      return data.filter(
        (templateAttribute) => templateAttribute.data_type === 'table',
      )
    }

    return []
  }, [data, isSuccess])

  return tables
}

export const getTablesQuery = async (documentPageId?: string) => {
  if (!documentPageId) return

  const templateAttributes = await listTemplateAttributesQuery({
    documentPageId,
  })

  if (!templateAttributes) return
  return templateAttributes.filter(
    (templateAttribute) => templateAttribute.data_type === 'table',
  ) as Array<TemplateAttribute<Table>> | undefined
}

export const useCreateTable = () => {
  const { toast } = useToast()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_TABLE],
    mutationFn: async ({
      documentPageId,
      tableData,
    }: {
      documentPageId: string
      tableData: Table
    }) => {
      if (documentPageId) {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type: 'table',
            template_values: tableData as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const useUpdateTable = () => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_TABLE],
    mutationFn: async ({
      templateAttributeId,
      documentPageId,
      tableData,
    }: {
      templateAttributeId: string
      documentPageId: string
      tableData: Table
    }) => {
      if (templateAttributeId) {
        const tables = await getTablesQuery(documentPageId)
        const table = tables?.find(
          (table) => table.id === templateAttributeId,
        ) || { template_values: {} }

        await updateTemplateAttribute({
          templateAttributeId,
          payload: {
            data_type: 'table',
            template_values: {
              ...table?.template_values,
              ...tableData,
            } as any,
          },
        })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const createTableMutation = () => {
  const createTemplateAttribute = createTemplateAttributeMutation()
  return queryClient.getMutationCache().build(queryClient, {
    mutationKey: [MUTATION_KEYS.CREATE_TABLE],
    mutationFn: ({
      documentPageId,
      tableData,
    }: {
      documentPageId: string
      tableData: Table
    }) => {
      return createTemplateAttribute.execute({
        documentPageId,
        payload: {
          data_type: 'table',
          template_values: tableData as any,
        },
      })
    },
  })
}

export const updateTableMutation = () => {
  const createTemplateAttribute = updateTemplateAttributeMutation()
  return queryClient.getMutationCache().build(queryClient, {
    mutationKey: [MUTATION_KEYS.UPDATE_TABLE],
    mutationFn: async ({
      templateAttributeId,
      documentPageId,
      tableData,
    }: {
      templateAttributeId: string
      documentPageId: string
      tableData: Table
    }) => {
      const tables = await getTablesQuery(documentPageId)
      const table = tables?.find(
        (table) => table.id === templateAttributeId,
      ) || { template_values: {} }

      return createTemplateAttribute.execute({
        templateAttributeId,
        payload: {
          data_type: 'table',
          template_values: {
            ...table?.template_values,
            ...tableData,
          } as any,
        },
      })
    },
  })
}

export const useDeleteTable = () => {
  const { toast } = useToast()
  const { mutateAsync: deleteTemplateAttribute } = useDeleteTemplateAttribute()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_TABLE],
    mutationFn: async (templateAttributeId: string) => {
      if (templateAttributeId) {
        await deleteTemplateAttribute({ templateAttributeId })
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}

export const usePartsTables = (documentPageId?: string | null) => {
  const tables = useListTables(documentPageId)

  const partsTable = useMemo(
    () =>
      tables?.filter((table) => table.template_values.type === 'parts-table'),
    [tables],
  )

  return partsTable
}

export const useManualPartsTables = (documentPageId?: string | null) => {
  const partsTables = usePartsTables(documentPageId)

  const autoPartsTable = useMemo(
    () => partsTables?.filter((table) => table.template_values.isManual),
    [partsTables],
  )

  return autoPartsTable
}

export const useAutoPartsTable = (documentPageId?: string | null) => {
  const partsTables = usePartsTables(documentPageId)

  const autoPartsTable = useMemo(
    () => partsTables?.find((table) => !table.template_values.isManual),
    [partsTables],
  )

  return autoPartsTable
}

export const useToolsTables = (documentPageId?: string | null) => {
  const tables = useListTables(documentPageId)

  const toolsTable = useMemo(
    () =>
      tables?.filter((table) => table.template_values.type === 'tools-table'),
    [tables],
  )

  return toolsTable
}

export const useManualToolsTables = (documentPageId?: string | null) => {
  const toolsTables = useToolsTables(documentPageId)

  const autoToolsTable = useMemo(
    () => toolsTables?.filter((table) => table.template_values.isManual),
    [toolsTables],
  )

  return autoToolsTable
}

export const useAutoToolsTable = (documentPageId?: string | null) => {
  const toolsTables = useToolsTables(documentPageId)

  const autoToolsTable = useMemo(
    () => toolsTables?.find((table) => !table.template_values.isManual),
    [toolsTables],
  )

  return autoToolsTable
}

export const toolsTableQuery = async (documentPageId?: string | null) => {
  if (!documentPageId) return

  const templateAttributes = await getTablesQuery(documentPageId)

  if (!templateAttributes) return
  const tables = templateAttributes.filter(
    (templateAttribute) =>
      templateAttribute.template_values.type === 'tools-table',
  ) as Array<TemplateAttribute<Table>>

  if (!tables || !tables.length) return

  return tables[0]
}

export const toolsTablesQuery = async (documentPageId?: string | null) => {
  if (!documentPageId) return []

  const templateAttributes = await getTablesQuery(documentPageId)

  if (!templateAttributes) return []
  const tables = templateAttributes.filter(
    (templateAttribute) =>
      templateAttribute.template_values.type === 'tools-table',
  ) as Array<TemplateAttribute<Table>>

  if (!tables || !tables.length) return []

  return tables
}

export const partsTableQuery = async (documentPageId?: string | null) => {
  if (!documentPageId) return

  const templateAttributes = await getTablesQuery(documentPageId)

  if (!templateAttributes) return
  const tables = templateAttributes.filter(
    (templateAttribute) =>
      templateAttribute.template_values.type === 'parts-table',
  ) as Array<TemplateAttribute<Table>>

  if (!tables || !tables.length) return

  return tables[0]
}

export const partsTablesQuery = async (documentPageId?: string | null) => {
  if (!documentPageId) return []

  const templateAttributes = await getTablesQuery(documentPageId)

  if (!templateAttributes) return []
  const tables = templateAttributes.filter(
    (templateAttribute) =>
      templateAttribute.template_values.type === 'parts-table',
  ) as Array<TemplateAttribute<Table>>

  if (!tables || !tables.length) return []

  return tables
}

export const useGenericTables = (documentPageId?: string | null) => {
  const tables = useListTables(documentPageId)

  const genericTables = useMemo(
    () =>
      tables?.filter((table) => table.template_values.type === 'generic-table'),
    [tables],
  )

  return genericTables
}

export const useUpdateTemplateElement = ({
  documentPageId,
  onSuccess,
}: {
  documentPageId: string
  onSuccess?: () => void
}) => {
  const { toast } = useToast()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()
  const { mutateAsync: createTemplateAttribute } = useCreateTemplateAttribute()
  const { data: templateAttributes } = useListTemplateAttributes({
    documentPageId,
  })

  return useMutation({
    onSuccess,
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
    mutationFn: async ({
      payload,
      data_type,
      templateAttributeId,
    }: {
      payload: any
      data_type: TemplateAttributeTypes
      templateAttributeId: string
    }) => {
      const templateAttribute = templateAttributes?.find(
        (templateAttribute) => templateAttribute.id === templateAttributeId,
      )

      if (templateAttribute?.id) {
        await updateTemplateAttribute({
          templateAttributeId: templateAttribute.id,
          payload: {
            data_type: templateAttribute.data_type,
            template_values: {
              ...templateAttribute.template_values,
              ...payload,
            },
          },
        })
      } else {
        await createTemplateAttribute({
          documentPageId,
          payload: {
            data_type,
            template_values: payload,
          },
        })
      }
    },
  })
}
