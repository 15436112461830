import { Html } from '@react-three/drei'
import { useAssemblyTree } from '@/state'
import { useCADPageStore } from '../state'
import { DocumentTypeChoices } from '@/lib/api/client'
import { useOperationSteps } from '@/services/queries/operation_steps'

export const CADPartLabel = ({
  documentType,
}: {
  documentType: DocumentTypeChoices
}) => {
  const { isLoading, steps } = useOperationSteps()
  const highlightedPartUUID = useCADPageStore(
    (state) => state.highlightedPartUUID,
  )
  const tree = useAssemblyTree((state) => state.tree)
  const node = tree?.nodes.find((node) => node.uuid === highlightedPartUUID)
  const partName = node?.display_name || node?.product || node?.instance
  const selectedParts = useCADPageStore((state) => state.selectedParts)
  const operationStep = useCADPageStore((state) => state.operationStep)
  const selectedNode = tree?.nodes.find(
    (node) => node.uuid === selectedParts[0],
  )
  const selectedPartName =
    selectedNode?.display_name ||
    selectedNode?.product ||
    selectedNode?.instance

  const partIsSelected = selectedParts.includes(node?.uuid || '')

  const stepNumber = steps.find(
    (step) => step.id === operationStep?.stepId,
  )?.order_number

  if (isLoading || !steps) return null

  if (documentType === 'work_instructions' && partName) {
    const span =
      !operationStep?.selectFromCad || !stepNumber ? (
        <span>{partName}</span>
      ) : partIsSelected ? (
        <span>
          Remove {partName} from Step {stepNumber}
        </span>
      ) : (
        <span>
          Add {partName} to Step {stepNumber}
        </span>
      )
    return <CADPartLabelTemplate>{span}</CADPartLabelTemplate>
  } else if (documentType === 'project_tracker' && selectedPartName) {
    return (
      <CADPartLabelTemplate>
        {<span>Selected: {selectedPartName}</span>}
      </CADPartLabelTemplate>
    )
  } else if (documentType === 'project_tracker' && partName) {
    return (
      <CADPartLabelTemplate>{<span>{partName}</span>}</CADPartLabelTemplate>
    )
  }

  return null
}

const CADPartLabelTemplate = ({ children }: { children: React.ReactNode }) => (
  <Html
    wrapperClass="transform-none w-full h-0 !z-20 text-center"
    className="right-0"
    style={{
      right: 0,
      left: 0,
      top: '60px',
      transform: 'none !important',
    }}
  >
    <div className="flex justify-center select-none">
      <h1 className="text-sm text-white bg-gray-900 rounded px-2 py-1 flex items-center space-x-2">
        {children}
      </h1>
    </div>
  </Html>
)
