import { useSearch, useLocation } from 'wouter'
import queryString from 'query-string'
import { useShallow } from 'zustand/react/shallow'
import { Skeleton } from '@/components/ui/skeleton'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { ProjectDocumentTitle } from '@/components/core/ProjectDocumentTitle'
import type { DocumentPage } from '@/lib/api/client'
import { useDocumentState } from '@/state'
import { useCADQuery } from '@/services/queries/cads'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'
import { useDeleteDocumentPage } from '@/services/queries/document_pages'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Spinner } from '@/components/ui/spinner'
import { cn } from '@/utils'

export const ProjectTrackerTOC = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const { isLoading: isCADLoading, data: cadData } = useCADQuery()

  if (isLoading || isCADLoading) {
    return <Skeleton className="w-full h-6 bg-gray-200" />
  }

  const assemblyTree = cadData.rawAssemblyTree
  const project = data?.project

  if (!project || !assemblyTree) {
    return null
  }

  return (
    <div className="h-full overflow-auto pb-5 text-sm">
      <div className="my-4 px-10">
        <ProjectDocumentTitle />
      </div>
      <div className="flex flex-col">
        {data.documentPages.map((docPage) => {
          return <TrackerItem key={docPage.id} docPage={docPage} />
        })}
      </div>
    </div>
  )
}

const TrackerItem = ({ docPage }: { docPage: DocumentPage }) => {
  const { isPending: isDeleting, mutate: deleteDocumentPage } =
    useDeleteDocumentPage()

  const { data: readOnlyData } = useIsReadOnly()
  const isReadOnly = readOnlyData.isReadOnly

  const currentPageInView = useDocumentState(
    useShallow((state) => state.currentPageInView),
  )
  const [, setLocation] = useLocation()
  const search = useSearch()
  const { projectId, documentId } = useCadPageParams()

  return (
    <a
      href={`#`}
      className={cn(
        'flex items-center justify-between space-x-2 p-2 pl-10 font-medium hover:bg-indigo-50 hover:text-primary-50',
        {
          'bg-indigo-100': currentPageInView === `${docPage.id}`,
        },
      )}
      onClick={(e) => {
        e.preventDefault()
        const searchParams = queryString.parse(search) as {
          cv?: string // cad version
          dv?: string // document version
          page_id?: string // document page id
        }
        const route = `/p/${projectId}/document/${documentId}/edit?${queryString.stringify({ ...searchParams, page_id: docPage.id })}`
        setLocation(route)

        setTimeout(() => {
          const pageId = docPage.id

          if (!pageId) return

          const page = document.getElementById(pageId)

          if (page) {
            page.scrollIntoView({
              block: 'center',
              inline: 'start',
            })
          }
        }, 0)
      }}
    >
      <span>{docPage.name}</span>
      {!isReadOnly && (
        <DropdownMenu>
          <DropdownMenuTrigger className="px-1 py-2 rounded-lg hover:bg-gray-100">
            <EllipsisVerticalIcon className="w-4 h-4 text-black" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              className="flex items-center space-x-2"
              disabled={isDeleting}
              onMouseDown={() => {
                if (isDeleting) return
                deleteDocumentPage({
                  documentPageId: docPage.id as string,
                })
              }}
            >
              {isDeleting && <Spinner className="w-4 h-4" />}
              <span>Delete</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </a>
  )
}
