import { cn } from '@/utils'
import { useCADPageStore } from '@/pages/CADPage/state'
import type { ASMTreeNode } from '@/state'

export const AttachedToOperationIndicator = ({
  node,
  isVisible,
}: {
  node: ASMTreeNode
  isVisible: boolean
}) => {
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const isHidden = hiddenParts.includes(node.uuid)

  if (!isVisible) {
    return null
  }
  return (
    <div
      className={cn('h-2 w-2 rounded-full', {
        'bg-teal-200': isHidden,
        'bg-teal-400': !isHidden,
      })}
    ></div>
  )
}
