import { CSSProperties } from 'react'
import DOMPurify from 'dompurify'

interface MarkupProps {
  className?: string
  html: string
  style?: CSSProperties
}

export const Markup = ({ className, html, style }: MarkupProps) => {
  const cleanHtml = DOMPurify.sanitize(html)

  return (
    <div
      className={className}
      style={style}
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
    />
  )
}
