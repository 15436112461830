import { ASMTreeNode } from '@/state'
import { CollapsibleTreeNodeButton } from './CollapsibleTreeNodeButton'
import { HidePartButton } from './HidePartButton'
import { AttachedToOperationIndicator } from './AttachedToOperationIndicator'

import type { GLTFObject } from '@/lib/cad/GLTFObject'

export const TreeNodeItem = ({
  node,
  gltf,
  isCollapsible,
  isAttachedToOperationStep,
  level,
  showHidePartButton,
}: {
  node: ASMTreeNode
  gltf: GLTFObject
  isCollapsible: boolean
  isAttachedToOperationStep: boolean
  level: number
  showHidePartButton: boolean
}) => {
  return (
    <div
      id={`tree-node-${node.uuid}`}
      className="flex items-center space-x-0 py-1"
      style={{
        paddingLeft: level * 10,
      }}
    >
      <CollapsibleTreeNodeButton enabled={isCollapsible} />
      <div className="flex items-center justify-between w-full pr-4">
        <div>{node.display_name}</div>
        <div className="flex items-center space-x-2 justify-end">
          <HidePartButton
            node={node}
            isVisible={showHidePartButton}
            gltf={gltf}
          />
          <AttachedToOperationIndicator
            node={node}
            isVisible={isAttachedToOperationStep}
          />
        </div>
      </div>
    </div>
  )
}
