interface CreateMarkProps {
  projectId: string
  documentId: string
  text: string
  hash: string
  color: string
}

export const createMark = ({
  projectId,
  documentId,
  text,
  hash,
  color,
}: CreateMarkProps) => {
  const host = window.location.origin
  const href = `${host}/p/${projectId}/document/${documentId}/edit#${hash}`
  return `<mark class="slash-command-pill" href="${href}" style="background-color: ${color};color: rgb(30, 30, 30);">${text}</mark> `
}
