import { Route as Woute, Switch, Redirect, useParams } from 'wouter'
import { useAppStore } from '@/state'

import { ProjectsPage } from '@/pages/ProjectsPage/page'
import { CADPage } from '@/pages/CADPage'
import { DocumentPage } from '@/pages/DocumentPage'
import { LoginPage } from '@/pages/LoginPage'
import { useVerifyLogin } from '@/services/queries/authentication'
import { TermsOfServiceDialog } from '@/components/core/TermsOfServiceDialog'
import { NavHomeButton } from './components/nav/NavHomeButton'
import { Spinner } from './components/ui/spinner'
import { GarbageCollector } from './components/core/GarbageCollector'
import { setUser } from '@sentry/react'
import { useEffect } from 'react'

const auth = (Component: React.ComponentType<any>) => {
  const Authed = (props: any) => {
    const { isVerifyingLoginStatus, isLoggedIn, user } = useVerifyLogin()
    const initViewWorker = useAppStore((state) => state.initViewWorker)
    const initServiceWorker = useAppStore((state) => state.initServiceWorker)

    useEffect(() => {
      if (user && isLoggedIn) {
        setUser({
          id: user.id as number,
          username: `${user.first_name} ${user.last_name}`,
          email: user.email,
          ip_address: '{{auto}}',
          is_active: user.is_active,
        })
      }
    }, [isLoggedIn, user])

    if (isVerifyingLoginStatus) {
      return (
        <div className="w-full h-screen flex flex-col">
          <div className="bg-primary-40 h-16 flex items-center pl-8">
            <NavHomeButton />
          </div>
          <div className="flex flex-col items-center justify-center mt-20">
            <Spinner />
          </div>
        </div>
      )
    }

    if (isLoggedIn) {
      initViewWorker()
      initServiceWorker()
      return (
        <>
          <GarbageCollector />
          <TermsOfServiceDialog />
          <Component {...props} />
        </>
      )
    }

    return (
      <Redirect
        to={`/login?continue_to=${encodeURIComponent(document.location.pathname + document.location.search)}`}
        replace
      />
    )
  }

  Authed.displayName = Component.displayName || Component.name

  return Authed
}

interface RouteProps {
  path: string
  component: React.ComponentType<any>
  isProtected?: boolean
}

const Route = ({ path, component, isProtected = true }: RouteProps) => {
  return (
    <Woute path={path} component={isProtected ? auth(component) : component} />
  )
}

export const Router = () => {
  return (
    <Switch>
      <Route path="/" component={ProjectsPage} />
      <Route path="/p/:projectId" component={ProjectsPage} />
      <Route path="/p/:projectId/cad" component={RedirectToProjectPage} />
      <Route
        path="/p/:projectId/document/:documentId/cad"
        component={CADPage}
      />
      <Route
        path="/p/:projectId/document/:documentId/edit"
        component={DocumentPage}
      />
      <Route path="/login" component={LoginPage} isProtected={false} />
      <Route path="/*.html" component={ProjectsPage} isProtected={false} />
      <Woute>404: No such page!</Woute>
    </Switch>
  )
}

const RedirectToProjectPage = () => {
  const { projectId } = useParams<{ projectId: string }>()

  return <Redirect to={`/p/${projectId}`} />
}
