import { useLocation, useParams } from 'wouter'
import queryString from 'query-string'
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/outline'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'

import { useCadPageParams } from '@/pages/CADPage/hooks'

export const OpenDocumentPageButton = ({
  documentPageId,
}: {
  documentPageId: string
}) => {
  const { projectId, documentId } = useParams<{
    projectId: string
    documentId: string
  }>()
  const [, setLocation] = useLocation()
  const cadPageParams = useCadPageParams()
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            size="sm"
            variant="ghost"
            className="px-1 cursor-pointer"
            onMouseDown={(e) => {
              e.stopPropagation()
              const qs = queryString.stringify({
                dv: cadPageParams.dv,
                cv: cadPageParams.cv,
                page_id: `${documentPageId}`,
              })
              setLocation(`/p/${projectId}/document/${documentId}/edit?${qs}`)
            }}
          >
            <ArrowRightEndOnRectangleIcon className="w-5 h-5 text-gray-400 text-normal font-bold" />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>{'Go To Document Page'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
