import { useParams, useSearch } from 'wouter'
import queryString from 'query-string'

export const useCadPageParams = () => {
  const params = useParams<{
    projectId: string
    documentId: string
  }>()
  const search = useSearch()
  const queryParams = queryString.parse(search) as {
    cv?: string // cad version
    dv?: string // document version
  }
  return { ...params, ...queryParams }
}
