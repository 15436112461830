import {
  Line,
  LineBasicMaterial,
  Float32BufferAttribute,
  BufferGeometry,
  Plane,
} from 'three'

export class PlaneVisualizer extends Line {
  constructor(hex = 0xffff00) {
    const color = hex

    const positions = [
      1, -1, 0, -1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0, -1, -1, 0, 1, -1, 0, 1,
      1, 0,
    ]

    const geometry = new BufferGeometry()
    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3))
    geometry.computeBoundingSphere()

    super(geometry, new LineBasicMaterial({ color: color, toneMapped: false }))

    this.userData.isMerged = true
    this.userData.planeObject = true
  }

  synchronize(plane: Plane) {
    const normal = plane.normal.clone().negate()
    this.lookAt(normal)
    this.translateZ(-plane.constant)
  }

  dispose() {
    this.geometry.dispose()
  }
}
