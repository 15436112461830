import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const useIsReadOnly = () => {
  const { isLoading: isCadLoading, data: cadData } = useCADQuery()
  const { isLoading: isDocLoading, data: docData } = useDocumentPageQuery()

  const isLatestDocumentVersion = Boolean(docData?.isLatestDocumentVersion)
  const isLatestCadVersion = Boolean(cadData.isLatestCadVersion)

  const docVersion =
    docData?.documentVersion?.name ||
    `V${docData?.documentVersion?.version_number}`
  const cadVersion = cadData.version?.version_number

  const isLoading = isCadLoading || isDocLoading
  const isReadOnly = !isLatestDocumentVersion || !isLatestCadVersion

  return {
    isLoading,
    data: {
      isReadOnly,
      isLatestCadVersion,
      isLatestDocumentVersion,
      cadVersion,
      docVersion,
    },
  }
}
