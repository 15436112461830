import { useMutation, useQuery } from '@tanstack/react-query'

import { getTermsOfService, acceptTermsOfService } from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'

type mutationTOSProps = {
  onSuccess?: () => void
}
export const useAcceptTermsOfService = (props?: mutationTOSProps) =>
  useMutation({
    mutationKey: [MUTATION_KEYS.ACCEPT_TOS],
    mutationFn: acceptTermsOfService,
    onSuccess: () => {
      if (props?.onSuccess) props.onSuccess()
      queryClient.setQueryData([QUERY_KEYS.TERMS_OF_SERVICE], {
        created: Date.now(),
      })
    },
  })

export const useGetTermsOfService = () =>
  useQuery({
    queryKey: [QUERY_KEYS.TERMS_OF_SERVICE],
    refetchOnMount: true,
    queryFn: getTermsOfService,
  })

export const getTermsOfServiceQuery = () =>
  queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.TERMS_OF_SERVICE],
    queryFn: getTermsOfService,
  })

export { QUERY_KEYS, MUTATION_KEYS }
