import { useRef, useEffect } from 'react'
import { useAppStore } from '@/state'

export const WebWorkerDebugger = () => {
  const ref = useRef<HTMLDivElement>(null)
  const canvas = useAppStore((state) => state.webworkerDebugger.canvas)
  useEffect(() => {
    if (canvas && ref.current) {
      ref.current.appendChild(canvas)
    }
  }, [canvas])

  return (
    <div
      style={{
        position: 'absolute',
        border: '1px solid red',
        zIndex: 10,
        right: 0,
        top: 0,
        transform: 'scale(0.4)',
      }}
    >
      <div ref={ref}></div>
    </div>
  )
}
