export const ScrewdriverIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      fill="#000000"
      height="800px"
      width="800px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 463 463"
      className={className}
    >
      <g>
        <path d="m247.5,0h-32c-12.958,0-23.5,10.542-23.5,23.5v152c0,7.023 3.101,13.332 8,17.641v14.359c0,12.958 10.542,23.5 23.5,23.5h0.5v162.234c-9.29,3.138-16,11.93-16,22.266 0,0.494 0.049,0.986 0.146,1.471l8,40c0.701,3.506 3.779,6.029 7.354,6.029h16c3.575,0 6.653-2.523 7.354-6.029l8-40c0.097-0.484 0.146-0.977 0.146-1.471 0-10.336-6.71-19.128-16-22.266v-162.234h0.5c12.958,0 23.5-10.542 23.5-23.5v-14.359c4.899-4.31 8-10.619 8-17.641v-152c0-12.958-10.542-23.5-23.5-23.5zm-14.148,448h-3.703l-6.625-33.128c0.322-4.395 4.001-7.872 8.477-7.872s8.154,3.478 8.477,7.872l-6.626,33.128zm-26.352-424.5c0-4.687 3.813-8.5 8.5-8.5h32c4.687,0 8.5,3.813 8.5,8.5v152c0,4.687-3.813,8.5-8.5,8.5h-32c-4.687,0-8.5-3.813-8.5-8.5v-152zm41,184c0,4.687-3.813,8.5-8.5,8.5h-16c-4.687,0-8.5-3.813-8.5-8.5v-8.513c0.167,0.004 0.332,0.013 0.5,0.013h32c0.168,0 0.333-0.009 0.5-0.013v8.513z" />
        <path d="m231.5,167c4.143,0 7.5-3.357 7.5-7.5v-120c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v120c0,4.143 3.357,7.5 7.5,7.5z" />
      </g>
    </svg>
  )
}
