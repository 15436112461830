import { AddPartForm } from '@/components/editor/components/AddPartForm'
import { createMark } from '@/components/editor/lib/createMark'
import {
  createTableMutation,
  partsTableQuery,
  updateTableMutation,
} from '@/services/hooks/template_attributes'
import type { EditorAttributes } from '@/components/editor/lib/types'
import type { CommandOption } from './types'

const command: CommandOption = {
  name: 'parts',
  path: '/parts:',
  multiSelect: false,
  getItems: async ({ documentPageId, bom }) =>
    bom.map((part) => ({
      key: `${documentPageId}-${part.partName}`,
      value: part.partName,
    })),
  onSelect: async ({
    editor,
    range,
    items,
    query,
    bom,
    fullBom,
    templateAttributes,
    updateBomOnSelect,
    toast,
  }) => {
    if (items.length === 0) {
      return
    }

    const editorAttributes = editor.options.editorProps
      .attributes as EditorAttributes
    const projectId = editorAttributes.projectId
    const documentPageId = editorAttributes.documentPageId
    const documentId = editorAttributes.documentId

    const parts: string[] = []

    bom.forEach((part) => {
      parts.push(part.partName)
    })

    const customParts =
      templateAttributes.find(
        (template) =>
          template.data_type === 'table' &&
          template.template_values.type === 'parts-table',
      )?.template_values?.rows || []

    customParts.forEach((part: any) => {
      if (!part.partName) return
      parts.push(part.partName)
    })

    const partQuery = query.replace('parts:', '')

    const hasPart = parts.includes(partQuery)

    const filteredItems = items.filter((item) =>
      item.value.toLowerCase().includes(partQuery.toLowerCase()),
    )
    const isInBom =
      !!fullBom.find((part) => part.partName === items[0]?.value) &&
      !parts.find((part) => part === items[0]?.value)

    const createCustomRow = async ({
      documentPageId,
      customRow,
    }: {
      documentPageId: string
      customRow: {
        partName: string
        quantity: string
      }
    }) => {
      const tableTemplate = await partsTableQuery(documentPageId)
      const TABLE_TYPE = 'parts-table'

      if (tableTemplate) {
        await updateTableMutation().execute({
          templateAttributeId: tableTemplate.id as string,
          documentPageId,
          tableData: {
            type: TABLE_TYPE,
            rows: [...(tableTemplate.template_values.rows || []), customRow],
          },
        })
      } else {
        await createTableMutation().execute({
          documentPageId,
          tableData: {
            type: TABLE_TYPE,
            rows: [customRow],
          },
        })
      }
    }

    const shouldAddNewPart =
      !hasPart &&
      (filteredItems.length === 0 || isInBom) &&
      (partQuery.trim() !== '' || isInBom)

    const addFromBom = filteredItems.length && isInBom
    const partName = addFromBom ? items[0]?.value : partQuery
    const MARK_COLOR = '#DAF2EF'

    if (shouldAddNewPart && updateBomOnSelect) {
      try {
        await createCustomRow({
          documentPageId: documentPageId as string,
          customRow: {
            partName,
            quantity: '',
          },
        })
        toast({
          variant: 'default',
          description: (
            <div>
              <div className="px-2 py-2 break-all">
                Added{' '}
                <span
                  style={{
                    padding: '2px 6px',
                    borderRadius: '5px',
                    backgroundColor: MARK_COLOR,
                    color: 'rgb(30, 30, 30)',
                  }}
                >
                  {partName}
                </span>{' '}
                to {`${addFromBom ? 'Operation' : 'BOM'}`}
              </div>
            </div>
          ),
        })
      } catch (error) {
        console.error(error)
        toast({
          variant: 'destructive',
          title: `Failed to add part ${partName} to BOM`,
        })
      }
    }

    const selectedParts = new Set<string>()

    if (
      shouldAddNewPart ||
      (!updateBomOnSelect && !filteredItems.length && partName)
    ) {
      selectedParts.add(partName)
    } else {
      filteredItems.forEach((item) => {
        selectedParts.add(item.value)
      })
    }

    const content = Array.from(selectedParts)
      .map((item) =>
        createMark({
          projectId: projectId as string,
          documentId: documentId as string,
          text: item,
          hash: `part-${documentPageId}-${item.replace(/\s/g, '-')}`,
          color: MARK_COLOR,
        }),
      )
      .join(' ')

    editor.chain().focus().deleteRange(range).insertContent(content).run()
  },
  renderEmptyResults: ({ query, editor, range, updateBomOnSelect }) => (
    <AddPartForm
      query={query}
      editor={editor}
      range={range}
      updateBomOnSelect={updateBomOnSelect}
    />
  ),
}

export default command
