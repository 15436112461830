import { Point } from './queries'

export const calculateLineValues = ({
  startPoint,
  endPoint,
}: {
  startPoint: Point
  endPoint: Point
}) => {
  const lineSize = Math.sqrt(
    Math.pow(endPoint.x - startPoint.x, 2) +
      Math.pow(endPoint.y - startPoint.y, 2),
  )
  const slope = (endPoint.y - startPoint.y) / (endPoint.x - startPoint.x)
  const direction = endPoint.x < startPoint.x ? 'left' : 'right'
  let inclination = Math.atan(slope) * (180 / Math.PI)

  if (direction === 'left') {
    inclination = (180 - inclination) * -1
  }

  return {
    lineSize,
    inclination,
  }
}

export const isArrow = (type: string) =>
  type === 'arrow' || type.includes('bubble') || type.includes('arrow')
export const isLine = (type: string) => type === 'line' || isArrow(type)
