export const ColorPallete = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="800px"
      width="800px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512 512"
      className={className}
    >
      <path
        fill="#D8D8DA"
        d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z   M256,336.842c-44.648,0-80.842-36.194-80.842-80.842s36.194-80.842,80.842-80.842s80.842,36.194,80.842,80.842  S300.648,336.842,256,336.842z"
      />
      <path
        fill="#D4B6E6"
        d="M282.947,188.632h220.076C485.09,122.726,441.507,67.394,383.64,34.044L229.053,188.632H282.947z"
      />
      <path
        fill="#EBAFD1"
        d="M229.053,188.632L383.639,34.044C346.068,12.39,302.482,0,256,0c-23.319,0-45.899,3.135-67.368,8.978  v220.075L229.053,188.632z"
      />
      <path
        fill="#E07188"
        d="M188.632,229.053V8.978C122.726,26.91,67.394,70.493,34.045,128.36l154.586,154.588V229.053z"
      />
      <g>
        <polygon
          fill="#D8D8DA"
          points="188.632,229.053 229.053,188.633 282.947,188.633 282.947,188.632 229.053,188.632  "
        />
        <polygon
          fill="#D8D8DA"
          points="229.053,323.367 188.632,282.947 229.053,323.368 282.947,323.368 323.368,282.947    282.947,323.367  "
        />
      </g>
      <path
        fill="#B4D8F1"
        d="M503.024,188.632H282.947v0.001h0.958l39.463,40.42L477.955,383.64  C499.611,346.068,512,302.482,512,256C512,232.681,508.865,210.099,503.024,188.632z"
      />
      <path
        fill="#ACFFF4"
        d="M323.368,282.947v220.075c65.905-17.932,121.238-61.517,154.586-119.382L323.368,229.053V282.947z"
      />
      <path
        fill="#95D5A7"
        d="M282.947,323.368L128.361,477.956C165.932,499.61,209.518,512,256,512  c23.319,0,45.899-3.135,67.368-8.977V282.947L282.947,323.368z"
      />
      <path
        fill="#F8E99B"
        d="M229.053,323.368H8.976C26.91,389.274,70.493,444.606,128.36,477.956l154.588-154.588H229.053z"
      />
      <path
        fill="#EFC27B"
        d="M188.632,282.947L34.045,128.36C12.389,165.932,0,209.518,0,256c0,23.319,3.135,45.901,8.976,67.368  h220.076L188.632,282.947z"
      />
      <polygon
        fill="#D8D8DA"
        points="283.905,188.633 282.947,188.633 323.368,229.053 "
      />
      <path
        fill="#B681D5"
        d="M503.024,188.632C485.09,122.726,441.507,67.394,383.64,34.044L256,161.684v26.947h26.947H503.024z"
      />
      <path
        fill="#E592BF"
        d="M383.639,34.044C346.068,12.39,302.482,0,256,0v161.684L383.639,34.044z"
      />
      <path
        fill="#80CB93"
        d="M256,350.316V512c23.319,0,45.899-3.135,67.368-8.977V282.947l-40.421,40.421L256,350.316z"
      />
      <polygon
        fill="#F6E27D"
        points="282.947,323.368 256,323.368 256,350.316 "
      />
    </svg>
  )
}
