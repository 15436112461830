import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { Skeleton } from '../ui/skeleton'

export const ProjectDocumentTitle = () => {
  const { isLoading, data } = useDocumentPageQuery()

  const documentType = data?.documentType
  const documentName = data?.doc?.name

  if (isLoading) {
    return <Skeleton className="w-full h-6 bg-gray-200" />
  }

  return (
    <>
      <h1 className="text-lg font-semibold">{documentName}</h1>
      {documentType === 'project_tracker' && (
        <h2 className="text-xs text-gray-500">Project Workbook</h2>
      )}
      {documentType === 'work_instructions' && (
        <h2 className="text-xs text-gray-500">Work Instructions</h2>
      )}
    </>
  )
}
