import type { Step } from '@/services/queries/operation_steps/types'
import type { BillOfMaterials, RawAssemblyTree, BillOfMaterial } from '@/state'

export const findPartsInOperation = (
  step: Step,
  assemblyTree: RawAssemblyTree,
): BillOfMaterials => {
  const bomMap: { [partName: string]: BillOfMaterial } = {}
  step.assembly_group_ids.forEach((uuid) => {
    const part = assemblyTree.nodes.find((node) => node.uuid === uuid)

    if (!part) {
      return
    }

    const partName =
      part.display_name || part.product || part.instance || 'Unknown'

    const existingMaterial = bomMap[partName]

    bomMap[partName] = {
      partName,
      quantity: existingMaterial ? existingMaterial.quantity + 1 : 1,
      UUIDs: [...(existingMaterial?.UUIDs || []), uuid],
    }
  })

  return Object.values(bomMap)
}

export const countAllParts = ({
  assemblyTree,
  customParts = {},
}: {
  assemblyTree: RawAssemblyTree
  customParts: Record<string, number>
}): BillOfMaterials => {
  const cleanNodes = assemblyTree.nodes
    .filter((node) => !node.children?.length)
    .reduce(
      (accum, node) => {
        const partName =
          node.display_name || node.product || node.instance || 'Unknown'
        if (accum[partName]) {
          accum[partName] += 1
        } else {
          accum[partName] = 1
        }
        return accum
      },
      {} as Record<string, number>,
    )

  const bom = Object.keys({ ...cleanNodes, ...customParts }).reduce(
    (acc, key) => {
      acc[key] = (cleanNodes[key] || 0) + (customParts[key] || 0)
      return acc
    },
    {},
  )

  return Object.entries(bom)
    .map(([partName, quantity]) => ({
      partName,
      quantity,
    }))
    .sort((a, b) => a.partName.localeCompare(b.partName)) as BillOfMaterials
}
