export const DEFAULT_STROKE_COLOR = '#000000'
export const DISABLED_STROKE_COLOR = '#E0E0E0'
export const DEFAULT_ANNOTATION_SIZE = 'default'
export const DEFAULT_LINE_HEIGHT = 10
export const STROKE_COLOR_VARIATIONS = [
  '#FF0000',
  '#FF7F00',
  '#FADE66',
  '#00E200',
  '#0080FF',
  '#6900D2',
  '#983D10',
  '#FBFAF8',
]
export const STROKE_COLOR_OPTIONS = [
  DEFAULT_STROKE_COLOR,
  ...STROKE_COLOR_VARIATIONS,
]
