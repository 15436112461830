import { CSRF_TOKEN_NAME } from '@/constants'
import { client } from './client'

/**
 * Get the value of a cookie
 * @param name - The name of the cookie to retrieve
 * @returns
 */
export const getCookie = (name: string) => {
  let cookieValue: string | null = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue =
          decodeURIComponent(cookie.substring(name.length + 1)) || ''
        break
      }
    }
  }
  return cookieValue
}

/**
 * Get the CSRF token
 * @returns Promise<csrf_token>
 */
export const getCsrf = async () => {
  const csrf = getCookie(CSRF_TOKEN_NAME)
  if (csrf) {
    return csrf
  }
  await client.POST('/api/v1/accounts/csrf')
  return getCookie(CSRF_TOKEN_NAME)
}

/**
 * Log in to the application
 * @param email
 * @param password
 * @returns
 */
export const login = async (email: string, password: string) => {
  await getCsrf()
  return client.POST('/api/v1/accounts/login', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    body: {
      email,
      password,
    },
  })
}

/**
 * Log out of the application
 */
export const logout = async () =>
  client.POST('/api/v1/accounts/logout', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
  })

/**
 * Verify that the user is logged in
 */
export const verifyLogin = async () => client.GET('/api/v1/accounts/hello')
