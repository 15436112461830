import { useState, useEffect } from 'react'
import { MinimalLayout } from '@/components/layouts/MinimalLayout'
import { EditorNavbar } from '@/components/nav/EditorNavbar'
import { WebWorkerDebugger } from '@/components/core/WebWorkerDebugger'
import { ReadOnlyBanner } from '@/components/banners/ReadOnlyBanner'
import { CadPageContainer } from '@/components/layouts/CadPageContainer'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable'

import { CADEditor } from './components/CADEditor'
import { AssemblyTree } from './components/AssemblyTree'
import { SidePanel } from './components/SidePanel'
import { DraggableTreeNodeContext } from './components/AssemblyTree/DraggableTreeNodeContext'
import { useCADPageListeners } from './hooks/useCADPageListeners'
import { useAssemblyPanelSize } from './hooks/useAssemblyPanelSize'

export const CADPage = () => {
  useCADPageListeners()
  const [showWebWorkerDebugger, setShowWebWorkerDebugger] =
    useState<boolean>(false)

  useEffect(() => {
    // @ts-expect-error - adding react-query devtools toggle
    window.toggleWebWorkerDebugger = () =>
      setShowWebWorkerDebugger((old) => !old)
  }, [])

  const assemblyPanelSize = useAssemblyPanelSize()

  return (
    <MinimalLayout>
      <EditorNavbar active="cad" />
      <ReadOnlyBanner />
      <CadPageContainer>
        <ResizablePanelGroup direction="horizontal" className="py-4 px-2">
          <DraggableTreeNodeContext>
            <ResizablePanel
              defaultSize={assemblyPanelSize}
              maxSize={Infinity}
              className="pr-1"
              style={{ minWidth: 320 }}
            >
              <AssemblyTree />
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel
              className="pl-4"
              style={{ width: 320, maxWidth: 320, minWidth: 320 }}
            >
              <SidePanel />
            </ResizablePanel>
          </DraggableTreeNodeContext>
          <ResizablePanel>
            <CADEditor />
            {showWebWorkerDebugger && <WebWorkerDebugger />}
          </ResizablePanel>
        </ResizablePanelGroup>
      </CadPageContainer>
    </MinimalLayout>
  )
}
