import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { TemplateAttributeInput } from './types'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * Create a new template attribute
 * @param documentPageId
 * @param payload
 * @returns
 */
export const createTemplateAttribute = async (
  documentPageId: string,
  payload: TemplateAttributeInput,
) => {
  await getCsrf()
  const resp = await client.POST(
    '/api/v1/projects/{document_page_id}/template_attributes',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
      body: payload,
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create template attribute')
  }

  return resp.data
}

/**
 * Create a new template attribute
 * @param templateAttributeId
 * @param payload
 * @returns
 */
export const updateTemplateAttribute = async (
  templateAttributeId: string,
  payload: TemplateAttributeInput,
) => {
  await getCsrf()
  const resp = await client.PUT(
    '/api/v1/projects/template_attributes/{template_attribute_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          template_attribute_id: templateAttributeId,
        },
      },
      body: payload,
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to update template attribute')
  }

  return resp.data
}

/**
 * Delete a template attribute
 * @param templateAttributeId
 * @returns
 */
export const deleteTemplateAttribute = async (templateAttributeId: string) => {
  await getCsrf()
  const resp = await client.DELETE(
    '/api/v1/projects/template_attributes/{template_attribute_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          template_attribute_id: templateAttributeId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to delete template attribute')
  }

  return resp.data
}

/**
 * Get a template attribute
 * @param templateAttributeId
 * @returns
 */
export const getTemplateAttribute = async (templateAttributeId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/template_attributes/{template_attribute_id}',
    {
      params: {
        path: {
          template_attribute_id: templateAttributeId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get template attribute')
  }

  return resp.data
}

/**
 * List all template attributes
 * @param documentPageId
 * @returns
 */
export const listTemplateAttributes = async (documentPageId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/{document_page_id}/template_attributes',
    {
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list template attributes')
  }

  return resp.data
}
