import { useEffect, useState } from 'react'
import prettyBytes from 'pretty-bytes'
import { TrashIcon } from '@heroicons/react/24/solid'
import { DocumentIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { cn } from '@/utils'
import { Button } from './button'

interface FilesProps {
  onChange: (files: File[]) => void
  validateFile?: (file: File) => boolean
  error?: string
  maxFiles?: number
  placeholder?: string
}

export const Files = ({
  error,
  onChange,
  validateFile,
  maxFiles = 1,
  placeholder = 'Upload a valid STEP file',
}: FilesProps) => {
  const [files, setFiles] = useState<{ [k: string]: File }>({})

  useEffect(() => {
    onChange(Object.values(files))
  }, [files, onChange])

  return (
    <>
      <FileInput
        onChange={(inputFiles) => {
          const inputEntries = Object.entries(inputFiles).slice(0, maxFiles)
          if (inputEntries.length < maxFiles) {
            const remaining = maxFiles - inputEntries.length
            const existingFiles = Object.entries(files).slice(0, remaining)
            const newFiles = Object.fromEntries(
              existingFiles.concat(inputEntries),
            )
            setFiles(newFiles)
          } else {
            setFiles(inputFiles)
          }
        }}
        validateFile={validateFile}
        error={error}
        placeholder={placeholder}
      />
      <div className="!my-1">
        {Object.values(files).map((file, i) => {
          return (
            <div
              key={`${i}_${file.name}`}
              className="flex items-center justify-between py-2 px-4 text-sm"
            >
              <div className="flex items-center space-x-2">
                <DocumentIcon className="w-4 h-4" />
                <span>{file.name}</span>
              </div>
              <div className="flex items-center">
                <div>{prettyBytes(file.size)}</div>
                <Button
                  variant="ghost"
                  onMouseDown={() => {
                    const updatedFiles = { ...files }
                    delete updatedFiles[file.name]
                    setFiles(updatedFiles)
                  }}
                >
                  <TrashIcon className="h-4 w-4 text-red-500" />
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

interface FileInputProps {
  onChange: (files: { [k: string]: File }) => void
  validateFile?: (file: File) => boolean
  error?: string
  placeholder?: string
}

export const FileInput = ({
  error,
  onChange,
  validateFile,
  placeholder = 'Upload a valid STEP file',
}: FileInputProps) => {
  const [isOver, setIsOver] = useState(false)

  return (
    <>
      <label
        htmlFor="file"
        className={cn(
          'border rounded-md min-h-28 w-full flex items-center justify-center cursor-pointer hover:border-black !mt-2',
          {
            'border-cyan-700': isOver,
            'border-destructive': error,
          },
        )}
        onDragEnter={(ev) => {
          ev.preventDefault()
          setIsOver(true)
        }}
        onDragLeave={(ev) => {
          ev.preventDefault()
          // This condition will be true when the drag leaves the parent for a child,
          // but false when the drag leaves the parent for somewhere else.
          if (ev.currentTarget.contains(ev.relatedTarget as Node)) return
          setIsOver(false)
        }}
        onDragOver={(ev) => ev.preventDefault()}
        onDrop={async (ev) => {
          ev.preventDefault()
          setIsOver(false)
          const files: { [k: string]: File } = {}

          const fileHandlesPromises = [...ev.dataTransfer.items]
            .filter((item) => item.kind === 'file')
            .map((item) => item.getAsFile())
          for await (const file of fileHandlesPromises) {
            if (file) {
              const isValid =
                typeof validateFile === 'function' ? validateFile(file) : true
              if (isValid) {
                files[file.name] = file
              }
            }
          }

          onChange(files)
        }}
      >
        <div className="flex flex-col items-center">
          <div className="p-2 flex items-center rounded-full">
            <CloudArrowUpIcon className="h-6 w-6 text-bold" />
          </div>
          <span className="text-sm">{placeholder}</span>
        </div>
      </label>
      <input
        className="hidden"
        type="file"
        id="file"
        name="file"
        multiple
        onChange={(ev) => {
          ev.preventDefault()
          const files: { [k: string]: File } = {}
          if (ev.target.files) {
            for (const file of ev.target.files) {
              const isValid =
                typeof validateFile === 'function' ? validateFile(file) : true
              if (isValid) {
                files[file.name] = file
              }
            }
            onChange(files)
          }
        }}
      />
      {error && (
        <div className="text-[0.8rem] font-medium text-destructive !mt-2">
          {error}
        </div>
      )}
    </>
  )
}
