import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Editor } from '@/components/editor/Editor'
import { CreateViewButton } from '../ControlPanel/CreateViewButton'
import { Skeleton } from '@/components/ui/skeleton'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { v4 as generateUUID } from 'uuid'

import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCADPageStore } from '../../state'
import { useAppStore } from '@/state'
import { useDeleteView } from '@/services/queries/views'
import { useIsReadOnly } from '../../hooks/useIsReadOnly'

import { useScreenshots } from './hooks/useScreenshots'
import { useCreateNote } from './hooks/useCreateNote'
import { useCadVersionId } from './hooks/useCadVersionId'
import { useDocumentVersionId } from './hooks/useDocumentVersionId'

const formSchema = z.object({
  title: z
    .string()
    .min(2, {
      message: 'Username must be at least 2 characters.',
    })
    .optional(),
  content: z.string().optional(),
  contentKey: z.string().optional(),
})

type FormInputs = z.infer<typeof formSchema>

export const NoteCreatorPanel = () => {
  const { isLoading: isReadOnlyDataLoading, data: readOnlyData } =
    useIsReadOnly()
  const isReadOnly = readOnlyData.isReadOnly

  const { mutate: deleteView } = useDeleteView()

  const documentVersionId = useDocumentVersionId()

  const cadVersionId = useCadVersionId()

  const { isLoading: isDocLoading, data: docData } = useDocumentPageQuery()

  const numOfPages = docData?.documentPages.length || 0

  const form = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: `Note ${numOfPages + 1}`,
      contentKey: generateUUID(),
    },
  })

  const { isLoading: isLoadingScreenshots, data: screenshots } =
    useScreenshots()

  const isLoading =
    isLoadingScreenshots || isDocLoading || isReadOnlyDataLoading

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const createdViews = useCADPageStore((state) => state.createdViews)
  const views = screenshots.filter(
    (view) => view.id && createdViews.includes(view.id),
  )

  const allViewUploadsInProgress = useAppStore(
    (state) => state.viewUploadsInProgress,
  )

  const viewUploadsInProgress = Array.from(
    new Set(
      allViewUploadsInProgress
        .filter((p) => {
          const view = views.find((v) => v.id === p.viewId)
          return view && !view.download_url
        })
        .map((p) => p.viewId),
    ),
  )

  const { isPending: isNotePending, mutate: createNote } = useCreateNote({
    onSuccess: () => {
      form.setValue('title', `Note ${numOfPages + 2}`)
      form.setValue('content', '')
      form.setValue('contentKey', generateUUID())
      setCadPageState({ createdViews: [] })
    },
  })

  const onSubmit = useCallback(
    ({ title, content }: FormInputs) => {
      if (documentVersionId && cadVersionId) {
        createNote({
          cadVersionId,
          documentVersionId,
          views,
          title: title || `Note ${numOfPages + 1}`,
          content: content || '',
        })
      }
    },
    [cadVersionId, createNote, documentVersionId, numOfPages, views],
  )

  if (isLoading) {
    return (
      <div className="bg-gray-100 flex flex-col h-full w-full py-4 px-2 space-y-4 border border-gray-300 rounded-xl">
        <Skeleton />
      </div>
    )
  }

  return (
    <div className="bg-gray-100 flex flex-col h-full w-full py-4 px-2 space-y-4 border border-gray-300 rounded-xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col h-full"
        >
          <div className="text-sm font-semibold mb-4">Note</div>
          <div className="space-y-4 flex-grow overflow-y-scroll">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input
                      className="bg-white"
                      placeholder="Add notes title"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div>
              <FormLabel className="mb-1">Screenshots</FormLabel>
              <div className="grid grid-cols-2">
                {views.map(
                  (view, i) =>
                    view.download_url && (
                      <div
                        key={i}
                        className="bg-white rounded-md border relative"
                      >
                        {!isReadOnly && (
                          <DropdownMenu>
                            <DropdownMenuTrigger className="px-1 py-2 rounded-lg hover:bg-gray-100 absolute right-px">
                              <EllipsisVerticalIcon className="w-4 h-4 text-gray-500" />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem
                                onMouseDown={() => {
                                  deleteView({ viewId: view.id as string })
                                }}
                              >
                                Delete
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        )}
                        <img src={view.download_url} alt="screenshot" />
                      </div>
                    ),
                )}

                {viewUploadsInProgress.map((_, i) => (
                  <Skeleton key={i} className="h-[136px] w-full" />
                ))}

                {!isReadOnly && (
                  <div className="h-[136px] bg-white rounded-md border flex flex-col justify-center items-center px-3">
                    <CreateViewButton
                      className="w-16 h-10 rounded-full"
                      iconClassName="h-6 w-6"
                      disabled={isNotePending}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="h-full w-full max-h-[200px] mb-8">
              <FormLabel className="mb-1">Content</FormLabel>
              <Editor
                key={form.getValues('contentKey')}
                documentPageId=""
                documentId=""
                projectId=""
                content={form.getValues('content') || ''}
                placeholder="Write something or press '/' for commands..."
                onUpdate={({ editor }) => {
                  form.setValue('content', editor.getHTML())
                }}
              />
            </div>
          </div>
          <div className="flex justify-center mt-4 mb-8">
            <Button
              type="submit"
              className="rounded-full"
              disabled={isNotePending}
            >
              Save Note
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
