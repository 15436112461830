import {
  useOperationSteps,
  useCreateOperationStep,
} from '@/services/queries/operation_steps'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useIsReadOnly } from '../../hooks/useIsReadOnly'
import { Skeleton } from '@/components/ui/skeleton'
import { StepCardList } from './StepCardList'
import { AddStepButton } from './AddStepButton'

export const AssemblyOrderPanel = () => {
  const { isLoading: isCadLoading, data: cadData } = useCADQuery()
  const { isLoading: isDocumentLoading, data: docData } = useDocumentPageQuery()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const { mutate: createOperationStep } = useCreateOperationStep()
  const { isLoading: isReadOnlyLoading, data: isReadOnlyData } = useIsReadOnly()

  const isLoading =
    isCadLoading || isDocumentLoading || isLoadingSteps || isReadOnlyLoading

  if (isLoading) {
    return (
      <div>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )
  }

  const documentVersionId = cadData.documentVersion?.id
  const documentType = docData?.documentType
  const isReadOnly = Boolean(isReadOnlyData?.isReadOnly)

  return (
    <div className="bg-gray-100 flex flex-col h-full w-full py-4 px-2 space-y-4 border border-gray-300 rounded-xl">
      <div className="text-sm font-semibold px-2">
        {documentType === 'project_tracker' ? 'Notes' : 'Operation Steps'}
      </div>
      <StepCardList
        documentVersionId={documentVersionId as string}
        steps={steps}
        isReadOnly={isReadOnly}
      />
      <div className="flex justify-end">
        <AddStepButton
          isReadOnly={isReadOnly}
          documentType={documentType}
          onClick={() => {
            if (documentVersionId) {
              createOperationStep({ documentVersionId })
            }
          }}
        />
      </div>
    </div>
  )
}
