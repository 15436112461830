import { CSRF_TOKEN_NAME } from '@/constants'
import { client } from './client'
import { getCsrf, getCookie } from './authentication'

export const createDocumentPageAssemblyGroupID = async (
  documentPageId: string,
  assemblyGroupId: string,
) => {
  await getCsrf()
  const resp = await client.POST(
    '/api/v1/projects/{document_page_id}/document_page_assembly_group_ids',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
      body: {
        assembly_group_id: assemblyGroupId,
      },
    },
  )
  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create document page assembly group id')
  }

  return resp.data
}

export const deleteDocumentPageAssemblyGroupID = async (
  documentPageAssemblyReferenceId: number,
) => {
  await getCsrf()
  const resp = await client.DELETE(
    '/api/v1/projects/document_page_assembly_group_ids/{document_page_assembly_group_id_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_assembly_group_id_id: String(
            documentPageAssemblyReferenceId,
          ),
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to delete document page assembly group id')
  }

  return resp.data
}
