import { Skeleton } from '@/components/ui/skeleton'
import { AssemblyOrderPanel } from './AssemblyOrderPanel'
import { NoteCreatorPanel } from './NoteCreatorPanel'

import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const SidePanel = () => {
  const { isLoading, data } = useDocumentPageQuery()

  if (isLoading) {
    return (
      <div>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )
  }

  if (data?.documentType === 'project_tracker') {
    return <NoteCreatorPanel />
  }

  if (data?.documentType === 'work_instructions') {
    return <AssemblyOrderPanel />
  }

  return <></>
}
