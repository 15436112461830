import React from 'react'
import { create } from 'zustand'
import { Group, Object3D, Plane, Vector3 } from 'three'
import { v4 as uuidv4 } from 'uuid'
import { persist } from 'zustand/middleware'
import { ISOMETRIC_CONSTS } from '@/lib/workers/constants'
import { PlaneVisualizer } from '@/lib/cad/PlaneVisualizer'

const LOCAL_STORAGE_KEY = 'q20-cad-page-store'

export type Explosions = {
  [instanceName: string]: {
    position: [number, number, number]
    originalPosition: [number, number, number]
  }
}

export type SaveableExplosions = {
  id: string
  cadId: string
  explosions: Explosions
}

export enum SectionState {
  INIT = 'init',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export type WandButtonState = string | null
export type CrossSectionState = 'rotate' | 'translate' | 'idle' | null
export type RenderMode = 'full-color' | 'outline'

export type StateVariables = {
  cameraAspect: number
  cameraPosition: number[]
  cameraFov: number
  cameraZoom: number
  cameraQuaternion: number[]
  cameraUp: number[]
  cameraNear: number | null
  cameraFar: number | null
  createdViews: string[]
  boundsMax: number | null
  sceneCenter: Vector3 | null
  selectedParts: string[]
  hiddenParts: string[]
  renderMode: RenderMode
  colorMap: {
    [key: string]: number
  }
  transparentParts: string[]
  highlightedPartUUID: string | null
  showAxis: boolean
  isRotating: boolean
  isDragging: boolean
  isExplosionLinesEnabled: boolean
  isEditModeActive: boolean
  wandSelected: WandButtonState
  explodeObjectRef: React.RefObject<Object3D | Group> | null
  explosions: Explosions
  explosionsToolbar: boolean
  loadedExplosion: string
  savedExplosions: SaveableExplosions[]
  sectionState: SectionState | null
  clippingPlanes: Plane[]
  exclusiveCrossSectionMap: string[][]
  crossSectionMap: string[][]
  planeObject: PlaneVisualizer | null
  rotateEnabled: boolean
  translateEnabled: boolean
  loadingProgress: number
  createViewButtonHover: boolean
  operationStep?: {
    documentId: string
    stepId: string
    selectFromCad: boolean
    isActive: boolean
  } | null
  filterScreenshotsByDocumentPageId?: string | null
}

export type StateActions = {
  updatePartColor: (
    partName: string,
    color: number | string,
    isColored: boolean,
  ) => void
  isColored: (partName: string) => boolean
  updateTransparency: (partName: string) => void
  isTransparent: (partName: string) => boolean
  isHidden: (uuid: string) => boolean
  setState: (state: Partial<CADPageState>) => void
  resetEditMode: () => void
  getState: () => CADPageState
  pushSelectedPart: (uuid: string) => void
  setExplosion: (
    instanceName: string,
    oldPositions: [number, number, number],
    newPosition: [number, number, number],
  ) => void
  toggleAxis: () => void
  toggleSectioning: () => void
  disableSectioning: () => void
  setWandSelected: (wandState: WandButtonState) => void
  setExplodeObjectRef: (ref: React.RefObject<Object3D | Group> | null) => void
  saveNewExplosion: (cadId: string) => void
  toggleExplosions: () => void
  getHighlightedPartUUID: () => string | null
  setLoadingProgress: (progress: number) => void
  setCreateViewButtonHover: (hover: boolean) => void
  reset: () => void
  addCreatedView: (viewId: string) => void
}

export type CADPageState = StateVariables & StateActions

const initialState: StateVariables = {
  cameraAspect: 1,
  cameraPosition: [],
  cameraFov: ISOMETRIC_CONSTS.FOV,
  cameraZoom: ISOMETRIC_CONSTS.ZOOM,
  cameraQuaternion: ISOMETRIC_CONSTS.QUATERNION,
  cameraUp: [0, 1, 0],
  cameraNear: null,
  cameraFar: null,
  createdViews: [],
  boundsMax: null,
  sceneCenter: null,
  selectedParts: [],
  hiddenParts: [],
  transparentParts: [],
  renderMode: 'full-color',
  colorMap: {},
  highlightedPartUUID: null,
  showAxis: false,
  isRotating: false,
  isDragging: false,
  isExplosionLinesEnabled: false,
  isEditModeActive: false,
  wandSelected: null,
  explodeObjectRef: null,
  explosions: {},
  savedExplosions: [],
  explosionsToolbar: false,
  loadedExplosion: '',
  sectionState: null,
  clippingPlanes: [],
  exclusiveCrossSectionMap: [],
  crossSectionMap: [],
  planeObject: null,
  rotateEnabled: true,
  translateEnabled: true,
  loadingProgress: 0,
  createViewButtonHover: false,
  operationStep: null,
  filterScreenshotsByDocumentPageId: null,
}

export const useCADPageStore = create<CADPageState>()(
  persist(
    (set, get) => ({
      ...initialState,

      reset: () => {
        set({ ...initialState, createdViews: [] })
        localStorage.removeItem(LOCAL_STORAGE_KEY)
      },

      setState: (state: Partial<CADPageState>) => {
        set(state)
      },
      resetEditMode: () => {
        const prevOperationStep = get().operationStep
        set({
          isEditModeActive: false,
          operationStep: prevOperationStep
            ? {
                ...prevOperationStep,
                selectFromCad: false,
              }
            : null,
          selectedParts: [],
          explosionsToolbar: false,
          wandSelected: null,
          explodeObjectRef: null,
        })
      },
      getState: () => get(),

      isHidden: (partName: string) => {
        return get().hiddenParts.includes(partName)
      },
      pushSelectedPart: (uuid: string) => {
        set((state) => ({
          selectedParts: [...new Set([...state.selectedParts, uuid])],
        }))
      },
      toggleAxis: () => set((state) => ({ showAxis: !state.showAxis })),
      setExplodeObjectRef: (ref: React.RefObject<Object3D | Group> | null) => {
        set({ explodeObjectRef: ref })
      },
      setExplosion: (
        instanceName: string,
        oldPositions: [number, number, number],
        newPosition: [number, number, number],
      ) =>
        set((state) => {
          const explosions = { ...state.explosions }
          if (explosions[instanceName]) {
            explosions[instanceName].position = newPosition
          } else {
            explosions[instanceName] = {
              position: newPosition,
              originalPosition: oldPositions,
            }
          }
          return { explosions }
        }),
      saveNewExplosion: (cadId: string) => {
        set((state) => {
          const newExplosions = JSON.parse(JSON.stringify(state.explosions))
          const newSaveableExplosions: SaveableExplosions = {
            cadId,
            id: uuidv4(),
            explosions: newExplosions,
          }
          return {
            savedExplosions: [...state.savedExplosions, newSaveableExplosions],
            loadedExplosion: newSaveableExplosions.id,
          }
        })
      },
      toggleExplosions: () =>
        set((state) => ({ explosionsToolbar: !state.explosionsToolbar })),
      disableSectioning: () => {
        set((state) => {
          if (state.sectionState === SectionState.ENABLED) {
            return { sectionState: SectionState.DISABLED }
          }
          return state
        })
      },
      toggleSectioning: () => {
        set((state) => {
          if (!state.sectionState) {
            return { sectionState: SectionState.INIT }
          } else if (state.sectionState === SectionState.DISABLED) {
            return { sectionState: SectionState.ENABLED }
          } else {
            return { sectionState: SectionState.DISABLED }
          }
        })
      },
      setWandSelected: (wandState: WandButtonState) => {
        const currentState = get().wandSelected
        if (currentState === wandState) set({ wandSelected: null })
        else set({ wandSelected: wandState })
      },
      updatePartColor: (
        partName: string,
        color: number | string,
        addColor: boolean,
      ) => {
        if (addColor) {
          if (get().isTransparent(partName)) {
            set((state) => ({
              transparentParts: state.transparentParts.filter(
                (part) => part !== partName,
              ),
            }))
          }
          const parsedColor =
            typeof color === 'number' ? color : parseInt(color.slice(1), 16)
          set((state) => ({
            colorMap: {
              ...state.colorMap,
              [partName]: parsedColor,
            },
          }))
        } else {
          set((state) => {
            const newColorMap = Object.fromEntries(
              Object.entries(state.colorMap).filter(
                ([key]) => key !== partName,
              ),
            )
            return { colorMap: newColorMap }
          })
        }
      },
      isColored: (partName: string) => {
        return get().colorMap[partName] !== undefined
      },
      updateTransparency: (partName: string) => {
        set((state) => {
          if (!get().isTransparent(partName)) {
            if (get().isColored(partName)) {
              set((state) => {
                const newColorMap = Object.fromEntries(
                  Object.entries(state.colorMap).filter(
                    ([key]) => key !== partName,
                  ),
                )
                return { colorMap: newColorMap }
              })
            }
            return {
              transparentParts: [
                ...new Set([...state.transparentParts, partName]),
              ],
            }
          } else {
            return {
              transparentParts: state.transparentParts.filter(
                (part) => part !== partName,
              ),
            }
          }
        })
      },
      isTransparent: (partName: string) => {
        return get().transparentParts.includes(partName)
      },
      getHighlightedPartUUID: () => {
        return get().highlightedPartUUID
      },
      setLoadingProgress: (progress: number) =>
        set({ loadingProgress: progress }),
      setCreateViewButtonHover: (hover: boolean) => {
        set({ createViewButtonHover: hover })
      },
      addCreatedView: (viewId) => {
        set((state) => {
          state.createdViews.push(viewId)
          return state
        })
      },
    }),
    {
      name: LOCAL_STORAGE_KEY,
      partialize: (state) => ({ savedExplosions: state.savedExplosions }),
    },
  ),
)
