import { useMemo, useState } from 'react'
import { RawAssemblyTree } from '@/state'
import { cn } from '@/utils'

import { useCADPageStore } from '@/pages/CADPage/state'
import { CollapsibleTreeNode } from './CollapsibleTreeNode'
import { TreeNodeItem } from './TreeNodeItem'
import { DraggableTreeNodeContainer } from './DraggableTreeNodeContainer'

import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { DocumentTypeChoices } from '@/lib/api/client'

export const TreeNode = ({
  nodeUUID,
  tree,
  documentType,
  gltf,
  level,
  hasDraggableChildren,
  isReadOnly,
  assemblyGroupIdsInOperationStep,
}: {
  nodeUUID: string
  documentType: DocumentTypeChoices
  tree?: RawAssemblyTree
  gltf: GLTFObject
  level: number
  hasDraggableChildren: boolean
  isReadOnly: boolean
  assemblyGroupIdsInOperationStep: string[]
}) => {
  const colorMap = useCADPageStore((state) => state.colorMap)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const [showHidePartButton, setShowHidePartButton] = useState(false)

  const isHidden = hiddenParts.includes(nodeUUID)

  const node = useMemo(
    () => tree?.nodes?.find((n) => n.uuid === nodeUUID),
    [nodeUUID, tree?.nodes],
  )

  if (!node || !tree) {
    return null
  }

  const isRootNode = nodeUUID === tree.root
  const isCollapsible = node.children.length > 0 && !isRootNode
  const isAttachedToOperationStep = assemblyGroupIdsInOperationStep?.includes(
    node.uuid,
  )

  const isDraggable =
    documentType === 'work_instructions' &&
    (hasDraggableChildren || (!isRootNode && level === 1)) &&
    !isReadOnly &&
    !isAttachedToOperationStep

  return (
    <DraggableTreeNodeContainer
      className={cn({
        'hover:bg-primary-10 rounded-lg border border-transparent': !isRootNode,
        'cursor-move': isDraggable,
        'bg-gray-100 text-gray-600': isHidden,
      })}
      enabled={isDraggable}
      node={node}
      tree={tree}
      onMouseOver={(e, node) => {
        e.stopPropagation()
        setShowHidePartButton(true)
        if (gltf) {
          gltf.highlightPart(tree, node.instance)
        }
      }}
      onMouseOut={(e) => {
        e.stopPropagation()
        setShowHidePartButton(false)
        gltf.unhighlightParts(colorMap)
      }}
    >
      {isCollapsible ? (
        <CollapsibleTreeNode
          node={node}
          tree={tree}
          gltf={gltf}
          level={level}
          documentType={documentType}
          hasDraggableChildren={hasDraggableChildren}
          isReadOnly={isReadOnly}
          assemblyGroupIdsInOperationStep={assemblyGroupIdsInOperationStep}
          isAttachedToOperationStep={isAttachedToOperationStep}
          showHidePartButton={showHidePartButton}
        />
      ) : (
        <>
          {!isRootNode && (
            <TreeNodeItem
              node={node}
              gltf={gltf}
              level={level}
              isAttachedToOperationStep={isAttachedToOperationStep}
              isCollapsible={false}
              showHidePartButton={showHidePartButton}
            />
          )}
          {node.children.map((childUUID) => (
            <TreeNode
              key={childUUID}
              documentType={documentType}
              nodeUUID={childUUID}
              tree={tree}
              gltf={gltf}
              level={level + 1}
              hasDraggableChildren={hasDraggableChildren}
              isReadOnly={isReadOnly}
              assemblyGroupIdsInOperationStep={assemblyGroupIdsInOperationStep}
            />
          ))}
        </>
      )}
    </DraggableTreeNodeContainer>
  )
}
