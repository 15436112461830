import React from 'react'

import { cn } from '@/utils'

type DocumentGridProps = {
  gridDimensions: {
    width: number
    height: number
  }
  showGrid?: boolean
}
const DocumentGrid = React.forwardRef<HTMLDivElement, DocumentGridProps>(
  ({ gridDimensions, showGrid }: DocumentGridProps, ref) => (
    <div
      ref={ref}
      className={cn(
        `absolute top-0 left-0 w-full h-full border border-gray-200`,
        {
          visible: showGrid,
          invisible: !showGrid,
        },
      )}
      style={{
        backgroundSize: `${gridDimensions.width}px ${gridDimensions.height}px`,
        backgroundImage:
          'repeating-linear-gradient(#f1f1f1 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #f1f1f1 0 1px, transparent 1px 100%)',
      }}
    />
  ),
)

DocumentGrid.displayName = 'DocumentGrid'

export default DocumentGrid
