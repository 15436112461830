import { useListDocumentVersionViews } from '@/services/queries/views'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

import { useDocumentVersion } from './useDocumentVersion'
import { useCadVersion } from './useCadVersions'

export const useScreenshots = ({
  documentId,
  cadVersionId,
  documentVersionId,
}: {
  documentId: string
  cadVersionId?: string | null
  documentVersionId?: string | null
}) => {
  const { isLoading: isDocumentVersionLoading, data: documentVersion } =
    useDocumentVersion({
      documentId,
      documentVersionId,
    })

  const { isLoading: isCadVersionLoading, data: cadVersion } = useCadVersion({
    documentId,
    cadVersionId,
  })

  const { isLoading: isViewsLoading, data: views } =
    useListDocumentVersionViews({
      cadVersionId: cadVersion?.id,
      documentVersionId: documentVersion?.id,
    })

  const { isLoading: isDocLoading, data: docData } = useDocumentPageQuery()

  const isLoading =
    isDocumentVersionLoading ||
    isCadVersionLoading ||
    isViewsLoading ||
    isDocLoading

  const documentPageIds = (docData?.documentPages || []).map((page) => page.id)

  const screenshots = (views || []).filter(
    (view) =>
      view.source_type === 'screenshot' &&
      documentPageIds.includes(view.document_page),
  )

  return {
    isLoading,
    data: screenshots,
  }
}
