import { useState, useEffect, useMemo } from 'react'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { usePDFifyStore } from '@/components/pdf/state'

export const usePDFDownloadEnabled = () => {
  const [isPDFDownloadEnabled, setIsPDFDownloadEnabled] = useState(false)

  const { isLoading: isDocumentLoading, data: documentData } =
    useDocumentPageQuery()

  const { isLoading: isCadLoading } = useCADQuery()

  const pageRefs = usePDFifyStore((state) => state.pageRefs)

  const pages = useMemo(() => documentData?.documentPages || [], [documentData])

  useEffect(() => {
    const isEnabled =
      !isDocumentLoading &&
      !isCadLoading &&
      Object.keys(pageRefs).length >= pages.length

    setIsPDFDownloadEnabled(isEnabled)
  }, [pages, isDocumentLoading, pageRefs, isCadLoading])

  return isPDFDownloadEnabled
}
