import { cn } from '@/utils'

export const CameraPlusIcons = ({ className }: { className?: string }) => {
  return (
    <svg
      width="37"
      height="31"
      viewBox="0 0 37 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        className="stroke-inherit"
        d="M10.652 13.332H6.26693C4.77345 13.332 4.02616 13.332 3.45573 13.6227C2.95396 13.8783 2.54631 14.286 2.29065 14.7878C2 15.3582 2 16.1055 2 17.599V25.0656C2 26.5591 2 27.3048 2.29065 27.8752C2.54631 28.377 2.95396 28.786 3.45573 29.0417C4.0256 29.332 4.77199 29.332 6.26255 29.332H21.7375C23.228 29.332 23.9733 29.332 24.5432 29.0417C25.045 28.786 25.454 28.377 25.7096 27.8752C26 27.3054 26 26.56 26 25.0695V17.5946C26 16.104 26 15.3576 25.7096 14.7878C25.454 14.286 25.045 13.8783 24.5432 13.6227C23.9728 13.332 23.2271 13.332 21.7336 13.332H17.3478M10.652 13.332H10.7344M10.652 13.332C10.6687 13.332 10.6862 13.332 10.7048 13.332L10.7344 13.332M10.652 13.332C10.5105 13.332 10.4312 13.3312 10.3685 13.3242C9.58504 13.2365 9.04771 12.4908 9.21224 11.7198C9.22755 11.6481 9.25781 11.5573 9.31774 11.3775L9.32031 11.3699C9.38875 11.1646 9.42297 11.0619 9.46077 10.9714C9.84782 10.0439 10.7243 9.41314 11.7266 9.33925C11.8245 9.33203 11.932 9.33203 12.1484 9.33203H15.8514C16.0679 9.33203 16.1763 9.33203 16.2742 9.33925C17.2765 9.41314 18.152 10.0439 18.5391 10.9714C18.5769 11.0619 18.6113 11.1645 18.6797 11.3698C18.7413 11.5547 18.7721 11.6472 18.7877 11.7199C18.9522 12.4909 18.4157 13.2365 17.6323 13.3242C17.5696 13.3312 17.4895 13.332 17.3478 13.332M10.7344 13.332H17.2653M17.2653 13.332H17.3478M17.2653 13.332L17.2949 13.332C17.3134 13.332 17.331 13.332 17.3478 13.332M14 25.332C11.7909 25.332 10 23.5412 10 21.332C10 19.1229 11.7909 17.332 14 17.332C16.2091 17.332 18 19.1229 18 21.332C18 23.5412 16.2091 25.332 14 25.332Z"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke-inherit"
        d="M26 6H36"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="stroke-inherit"
        d="M31 11V1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
