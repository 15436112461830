import { Html } from '@react-three/drei'
import { useContextBridge } from 'its-fine'
import { Controls } from './Controls'
import { CreateViewButton } from './CreateViewButton'

export const ControlPanel = () => {
  const Bridge = useContextBridge()

  return (
    <Html
      wrapperClass="transform-none w-full h-0 !z-20 !top-auto !bottom-0 select-none"
      style={{ right: 0, left: 0, bottom: 40, transform: 'none !important' }}
    >
      <Bridge>
        <div className="flex justify-center bg-transparent w-full">
          <div className="flex items-center space-x-1 py-0 pl-4 pr-1 border shadow-md rounded-full bg-white">
            <Controls />
            <CreateViewButton />
          </div>
        </div>
      </Bridge>
    </Html>
  )
}
