import { useMemo, forwardRef } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Spinner } from '@/components/ui/spinner'
import { useListCads } from '@/services/queries/cads'

export const SelectCADDropdown = forwardRef<
  any,
  {
    onChange: (value: string) => void
    value?: string
    projectId?: string
  }
>(
  (
    { onChange, value, projectId },
    // @ts-expect-error ref is not used but it's required for forwardRef
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const { isFetching, data } = useListCads({ projectId })

    const cads = useMemo(() => {
      return data?.sort() || []
    }, [data])

    return (
      <Select disabled={!projectId} onValueChange={onChange} value={value}>
        <SelectTrigger className="w-[180px]" id="project-id">
          <SelectValue placeholder="Select a CAD" />
        </SelectTrigger>
        <SelectContent className="max-h-72">
          {cads?.map((cad) => (
            <SelectItem key={cad.id} value={cad.id as string}>
              {cad.name}
            </SelectItem>
          ))}
          {isFetching && <Spinner />}
        </SelectContent>
      </Select>
    )
  },
)
SelectCADDropdown.displayName = 'SelectCADDropdown'
