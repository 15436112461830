import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'

import { cn } from '@/utils'
import { listUsersFromOrganizations } from '@/services/hooks/users'
import { User } from '@/lib/api/client'
import { useAppStore } from '@/state'

type MentionListProps = {
  items: Array<User>
  command: ({ id }: { id: any }) => void
}
const MentionList = forwardRef(({ items, command }: MentionListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const currentUser = useAppStore((state) => state.currentUser)

  const selectItem = useCallback(
    (index) => {
      const item = items[index]

      if (item) {
        command({ id: `${item.first_name} ${item.last_name}` })
      }
    },
    [command, items],
  )

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length)
  }, [items.length, selectedIndex])

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % items.length)
  }, [selectedIndex, items.length])

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex)
  }, [selectedIndex, selectItem])

  useEffect(() => setSelectedIndex(0), [items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="bg-slate-50 shadow-md rounded-xl flex flex-col gap-0.5 overflow-auto p-2 relative min-w-[200px] ">
      {items.length ? (
        items.map((item, index) => (
          <button
            className={cn(
              'px-2 py-1 rounded-md flex items-center gap-1 hover:bg-slate-300 text-left width-full',
              {
                'bg-slate-200': index === selectedIndex,
                'bg-transparent': index !== selectedIndex,
              },
            )}
            key={index}
            onClick={() => selectItem(index)}
          >
            {`${item.first_name} ${item.last_name}`}
            {currentUser?.id === item.id && (
              <span className="text-xs text-slate-500"> (You)</span>
            )}
          </button>
        ))
      ) : (
        <span className="text-sm px-2">No result...</span>
      )}
    </div>
  )
})

MentionList.displayName = 'MentionList'

export default {
  allowSpaces: true,
  items: ({ query }) => {
    const users = listUsersFromOrganizations()
    return users.then((items: Array<User>) =>
      items.filter(
        ({ first_name, last_name, email }) =>
          first_name?.toLowerCase().includes(query.toLowerCase()) ||
          last_name?.toLowerCase().includes(query.toLowerCase()) ||
          `${first_name} ${last_name}`
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          email.toLowerCase().includes(query.toLowerCase()),
      ),
    )
  },
  render: () => {
    let component
    let popup

    return {
      onStart: (props) => {
        props.editor.storage.isSelecting = true
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit(props) {
        props.editor.storage.isSelecting = false
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}
