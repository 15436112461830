import { useMemo } from 'react'

import {
  listUserOrganizationsQuery,
  useListUserOrganizations,
} from '../queries/organizations'
import { User } from '@/lib/api/client'

export const useListUsersFromOrganizations = () => {
  const { data: pagedOrganizations } = useListUserOrganizations()

  const users = useMemo(() => {
    if (!pagedOrganizations) return []

    return pagedOrganizations.items.reduce((acc, curr) => {
      return [...acc, ...curr.users] as Array<User>
    }, [] as Array<User>)
  }, [pagedOrganizations])

  return users
}

export const listUsersFromOrganizations = async () => {
  const pagedOrganizations = await listUserOrganizationsQuery()

  const items = pagedOrganizations.items || []
  const users = items.reduce((acc, curr) => {
    return [...acc, ...curr.users] as Array<User>
  }, [] as Array<User>)
  return users
}

export const useGetUserById = (userId: number) => {
  const users = useListUsersFromOrganizations()

  const user = useMemo(() => {
    if (!users) return null

    return users.find((user) => user.id === userId)
  }, [users, userId])

  return user
}

export const getUserByIdQuery = async (userId: number) => {
  const users = await listUsersFromOrganizations()

  if (!users) return null
  return users.find((user) => user.id === userId)
}
