import { useMutation } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { v4 as generateUUID } from 'uuid'

import queryClient from '@/queryClient'
import {
  createDocumentPage,
  createTemplateAttribute,
  updateView,
} from '@/lib/api/client'

import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages'
import { QUERY_KEYS as VIEWS_QUERY_KEYS } from '@/services/queries/views'

import type { View } from '@/lib/api/client'

export const useCreateNote = ({ onSuccess }: { onSuccess: () => void }) => {
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      cadVersionId,
      documentVersionId,
      views,
      title,
      content,
    }: {
      cadVersionId: string
      documentVersionId: string
      views: View[]
      title?: string
      content?: string
    }) => {
      const documentPage = await createDocumentPage(documentVersionId, {
        name: title || '',
        assembly_group_id: generateUUID(),
        template_values: {},
      })

      const documentPageId = documentPage.id as string

      await createTemplateAttribute(documentPageId, {
        data_type: 'note',
        template_values: {
          content: content || '',
          notesPosition: 'relative',
        } as any,
      })

      if (views.length > 0) {
        for (const view of views) {
          const viewId = view.id as string
          await updateView(viewId, {
            source_type: 'screenshot',
            name: view.name,
            image_data: view.image_data,
            document_page_id: documentPageId,
          })

          await createTemplateAttribute(documentPageId, {
            data_type: 'view',
            template_values: {
              viewId: view.id,
              imagePosition: 'absolute',
            } as any,
          })
        }
      }

      return { cadVersionId, documentVersionId }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Failed to create note.',
        description: 'There was a problem with your request.',
      })
    },
    onSuccess: ({ cadVersionId, documentVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [
          DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId },
        ],
      })
      queryClient.invalidateQueries({
        queryKey: [VIEWS_QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
      })

      toast({
        variant: 'success',
        title: 'Success! Note created!',
      })

      onSuccess()
    },
  })
}
