export const AxisIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 1V11.2308L1.5 16"
        stroke="#1E1E1E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.47 16.848C16.9383 17.1407 17.5553 16.9983 17.848 16.53C18.1407 16.0617 17.9983 15.4447 17.53 15.152L16.47 16.848ZM8.47 11.848L16.47 16.848L17.53 15.152L9.53 10.152L8.47 11.848Z"
        fill="#1E1E1E"
      />
    </svg>
  )
}
