import { Editor as TiptapEditor } from '@tiptap/react'

import { MenubarContent, MenubarItem } from '@/components/ui/menubar'
import { CUSTOM_ICONS } from './constants'
import { cn } from '@/utils'
import { useEffect, useMemo, useState } from 'react'

type IconsMenuProps = {
  editor: TiptapEditor
}
export const IconsMenu = ({ editor }: IconsMenuProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const categories = useMemo(
    () =>
      CUSTOM_ICONS.reduce((acc, icon) => {
        const category = icon.group
        if (category && !acc[category]) {
          acc[category] = {
            fallbackImage: icon.fallbackImage,
            emoji: icon.emoji,
          }
        }
        return acc
      }, {}),
    [],
  )

  useEffect(() => {
    setSelectedCategory(Object.keys(categories)[0])
  }, [categories])

  return (
    <MenubarContent className="w-[150px] h-[200px] min-w-[0] overflow-auto">
      <div className="flex flex-row flex-wrap w-full gap-1 justify-center p-2 pb-9">
        {CUSTOM_ICONS.filter((icon) => icon.group === selectedCategory).map(
          (icon) => (
            <MenubarItem
              key={icon.name}
              onClick={() => {
                // @ts-expect-error - accessing custom commands through the editor
                editor.chain().focus().addIcon(icon.name, icon.color).run()
              }}
              className="flex items-center justify-center w-6 h-6 rounded-md bg-slate-100 shadow-md p-0 cursor-pointer hover:bg-slate-200 hover:bg-accent"
            >
              {icon.fallbackImage ? (
                <img className="w-4 h-4" src={icon.fallbackImage} />
              ) : (
                <span
                  className="text-xl"
                  style={{
                    color: icon.color,
                  }}
                >
                  {icon.emoji}
                </span>
              )}
            </MenubarItem>
          ),
        )}
      </div>
      <div className="absolute left-0 bottom-0 w-full flex flex-nowrap bg-white py-1 px-2 shadow-inner gap-1 border-t border-gray-200">
        {Object.keys(categories).map((categoryKey) => (
          <span
            key={categoryKey}
            className={cn(
              'p-0.5 h-5 w-5 border border-input bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground cursor-pointer rounded-md flex items-center justify-center',
              {
                'bg-gray-200': categoryKey === selectedCategory,
                'border-gray-400': categoryKey === selectedCategory,
              },
            )}
            onClick={() => {
              setSelectedCategory(categoryKey)
            }}
          >
            {categories[categoryKey].fallbackImage ? (
              <img
                className="w-4 h-4"
                src={categories[categoryKey].fallbackImage}
              />
            ) : (
              <span className="text-lg">{categories[categoryKey].emoji}</span>
            )}
          </span>
        ))}
      </div>
    </MenubarContent>
  )
}

export default IconsMenu
