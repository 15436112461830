export const HighlightIcon = ({
  className,
  selectedColor = 'black',
}: {
  className?: string
  selectedColor?: string
}) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 90 112.5"
    className={className}
  >
    <path d="M22.195,53.495l7.744,7.743l-3.386,3.386l-7.754,0.005l0.011-7.748L22.195,53.495z M45.596,26.303l11.525,11.525  L41.424,56.422l-5.557,1.12l-9.98-9.976L27.002,42L45.596,26.303z M59.335,14.698l9.391,9.386l-7.198,8.525L50.815,21.896  L59.335,14.698z M59.726,5.463L20.799,38.339L19.059,47l-7.057,7.063l-0.011,17.375l17.382-0.016l7.057-7.057l8.667-1.736  L77.96,23.698l-2.219-2.208L59.726,5.463z" />
    <rect
      x="11.987"
      y="77.733"
      width="65.973"
      height="6.807"
      stroke={selectedColor}
      fill={selectedColor}
    />
  </svg>
)

export default HighlightIcon
