import { DocumentTemplate } from '../../DocumentTemplate'
import { View, Image } from '@/lib/api/client'
import { AnnotationsContainer } from '../../Annotations/AnnotationsContainer'
import type { DocumentPage } from '@/lib/api/client'
import DraggableImage from '../../DraggableImage'
import { useAnnotationsQuery } from '../../Annotations/queries'
import {
  useListViewsTemplates,
  useListImageTemplates,
  useListNotes,
} from '@/services/hooks/template_attributes'
import { OperationNotesEditor } from '../../OperationNotesEditor/OperationNotesEditor'

interface ProjectsTrackerPageProps {
  projectId: string
  documentId: string
  pageTitle: string
  pageCreatedAt?: string
  pageId: string
  views: View[]
  images: Image[]
  isReadOnly: boolean
  documentPage: DocumentPage
}

export const ProjectsTrackerPage = ({
  documentId,
  projectId,
  pageId,
  pageTitle,
  pageCreatedAt,
  views,
  images,
  isReadOnly,
  documentPage,
}: ProjectsTrackerPageProps) => {
  const { isAddingAnnotation } = useAnnotationsQuery()

  const notesTemplates = useListNotes(documentPage.id)
  const imageTemplates = useListImageTemplates(documentPage.id)
  const viewTemplates = useListViewsTemplates(documentPage.id)

  return (
    <DocumentTemplate
      id={`${pageId}`}
      title={pageTitle}
      order={1}
      documentPageId={documentPage?.id as string}
      createdAt={pageCreatedAt}
    >
      <AnnotationsContainer documentPage={documentPage} isReadOnly={isReadOnly}>
        <div
          className="grid grid-cols-2 gap-4 flex-1"
          style={{ minHeight: '60%' }}
        >
          <div className="flex flex-1">
            {notesTemplates.map((note) => (
              <OperationNotesEditor
                key={note.id}
                documentId={documentId}
                projectId={projectId}
                documentPageId={documentPage.id ?? ''}
                isAddingAnnotation={isAddingAnnotation}
                isReadOnly={isReadOnly}
                type="note"
                notesTemplate={note}
              />
            ))}
          </div>
          {viewTemplates.map((viewTemplate) => {
            const viewId = viewTemplate.template_values?.viewId || ''
            const view = views.find((view) => view.id === viewId)
            const userFacingDownloadUrl = `${view?.name}.jpg`
            const downloadUrl = view?.download_url || ''

            if (view && view.download_url) {
              return (
                <div className="flex relative flex-1" key={viewTemplate.id}>
                  <DraggableImage
                    className="w-auto"
                    documentPageId={documentPage?.id as string}
                    userFacingDownloadUrl={userFacingDownloadUrl}
                    isAddingAnnotation={isAddingAnnotation}
                    downloadUrl={downloadUrl}
                    type="view"
                    imageTemplate={viewTemplate}
                    isReadOnly={isReadOnly}
                    timestamp={view.created}
                  />
                </div>
              )
            }

            return null
          })}
          {imageTemplates.map((imageTemplate) => {
            const imageId = imageTemplate.template_values?.imageId || ''
            const image = images.find((image) => image.id === imageId)
            const userFacingDownloadUrl = `${image?.name}.jpg`
            const downloadUrl = image?.download_url || ''

            if (image && image.download_url) {
              return (
                <DraggableImage
                  key={imageTemplate.id}
                  documentPageId={documentPage?.id as string}
                  userFacingDownloadUrl={userFacingDownloadUrl}
                  isAddingAnnotation={isAddingAnnotation}
                  downloadUrl={downloadUrl}
                  type="image"
                  imageTemplate={imageTemplate}
                  isReadOnly={isReadOnly}
                />
              )
            }
            return null
          })}
        </div>
      </AnnotationsContainer>
    </DocumentTemplate>
  )
}
