import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { getCsrf, View } from '@/lib/api/client'

import { VIEW_WORKER_ACTIONS } from './constants'
import queryClient from '@/queryClient'
import { QUERY_KEYS } from '@/services/queries/views'
import type { ViewJob } from './types'

// https://github.com/F-loat/offscreen-canvas-demo/tree/main

interface ViewWorkerOptions {
  onUploadsCompleted?: (viewIds: string[]) => void
  onUploadsInProgress?: (
    viewIds: {
      viewId: string
      assemblyGroupId: string
      documentOrder?: number | null
    }[],
  ) => void
  onScreenshot?: (image: string) => void
  onKick?: () => void
  onStart?: () => void
}

export class ViewWorker {
  worker: Worker | null
  canvas: HTMLCanvasElement | null
  offscreenCanvas: OffscreenCanvas | null
  jobs: {
    [id: string]: {
      job: ViewJob
    }
  }
  loader: GLTFLoader
  options: ViewWorkerOptions

  constructor(options: ViewWorkerOptions = {}) {
    this.options = options
    this.worker = null
    this.canvas = null
    this.offscreenCanvas = null
    this.jobs = {}
    this.loader = new GLTFLoader()
    this.start()
  }

  start() {
    this.worker = new Worker(new URL('./ViewWorkerProcess', import.meta.url), {
      type: 'module',
    })

    this.canvas = document.createElement('canvas')
    this.canvas.style.display = 'block'
    this.canvas.width = 1080
    this.canvas.height = 1024
    this.offscreenCanvas = this.canvas.transferControlToOffscreen()

    this.worker.onmessage = (message) => {
      const { action, payload } = message.data
      if (action === VIEW_WORKER_ACTIONS.UPLOAD_VIEW) {
        this.__uploadView(payload.job)
      } else if (action === VIEW_WORKER_ACTIONS.VIEWS_IN_PROGRESS) {
        if (typeof this.options.onUploadsInProgress === 'function') {
          this.options.onUploadsInProgress(payload)
        }
      } else if (action === VIEW_WORKER_ACTIONS.VIEWS_COMPLETED) {
        if (typeof this.options.onUploadsCompleted === 'function') {
          this.options.onUploadsCompleted(payload)
        }
      } else if (action === VIEW_WORKER_ACTIONS.START_JOBS) {
        if (typeof this.options.onStart === 'function') {
          this.options.onStart()
        }
      }
    }

    this.worker.postMessage(
      {
        action: VIEW_WORKER_ACTIONS.INIT,
        payload: {
          offscreenCanvas: this.offscreenCanvas,
        },
      },
      [this.offscreenCanvas],
    )
  }

  async kick(cadVersionId: string, documentVersionId: string) {
    if (!this.worker || !this.canvas) {
      return
    }

    if (typeof this.options.onKick === 'function') {
      this.options.onKick()
    }

    const csrf = await getCsrf()

    this.worker.postMessage(
      {
        action: VIEW_WORKER_ACTIONS.KICK_JOBS,
        payload: {
          canvasWidth: this.canvas.width,
          canvasHeight: this.canvas.height,
          cadVersionId,
          csrf,
          documentVersionId,
        },
      },
      [],
    )
  }

  __uploadView(job: ViewJob) {
    if (!this.canvas || !this.worker) {
      return
    }

    const base64Image = this.canvas.toDataURL('image/png')
    const viewsUpdaterFn = (viewData?: View[]) => {
      const views = viewData || []

      if (!job?.view?.id) return views

      const view = views.find((view: View) => view?.id === job.view.id)

      if (view) {
        return views.map((view: View) =>
          view.id === job.view.id
            ? {
                ...view,
                download_url: base64Image,
              }
            : view,
        )
      }
      return [
        ...views,
        {
          ...(job.view as any),
          download_url: base64Image,
        },
      ]
    }
    queryClient.setQueriesData(
      {
        queryKey: [
          QUERY_KEYS.VIEWS,
          {
            cadVersionId: job.view.cad_version,
            documentVersionId: job.documentVersionId,
          },
        ],
      },
      viewsUpdaterFn,
    )

    if (this.options.onScreenshot) {
      this.options.onScreenshot(base64Image)
    }
  }
}
