export const BookIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 4.19991V11M7 4.19991C7 3.07979 7 2.51997 7.21799 2.09214C7.40973 1.71581 7.71547 1.40974 8.0918 1.21799C8.51962 1 9.07976 1 10.1999 1H11.9332C12.3066 1 12.4934 1 12.636 1.07266C12.7614 1.13658 12.8632 1.23849 12.9271 1.36394C12.9997 1.50655 13 1.69337 13 2.06675V7.93349C13 8.30686 12.9997 8.49329 12.9271 8.6359C12.8632 8.76134 12.7617 8.8636 12.6362 8.92751C12.4937 9.0001 12.3073 9.0001 11.9347 9.0001H10.0462C9.42008 9.0001 9.10644 9.0001 8.82227 9.08641C8.57069 9.16281 8.33711 9.28791 8.13399 9.45486C7.90455 9.64344 7.73068 9.90394 7.38336 10.4249L7 11M7 4.19991C7 3.07979 6.99989 2.51997 6.7819 2.09214C6.59015 1.71581 6.28418 1.40974 5.90785 1.21799C5.48003 1 4.91984 1 3.79974 1H2.06641C1.69304 1 1.50654 1 1.36393 1.07266C1.23849 1.13658 1.13658 1.23849 1.07266 1.36394C1 1.50655 1 1.69337 1 2.06675V7.93349C1 8.30686 1 8.49329 1.07266 8.6359C1.13658 8.76134 1.23849 8.8636 1.36393 8.92751C1.5064 9.0001 1.69267 9.0001 2.06531 9.0001H3.95382C4.57996 9.0001 4.89295 9.0001 5.17712 9.08641C5.4287 9.16281 5.66341 9.28791 5.86654 9.45486C6.09505 9.64268 6.26781 9.90184 6.61232 10.4186L7 11"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
