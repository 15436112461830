import { useRef, useEffect, ReactNode } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { usePDFifyStore } from './state'

export const PDFify = ({
  id,
  order,
  children,
}: {
  id: string
  order: number
  children: ReactNode
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const addPageRef = usePDFifyStore(useShallow((state) => state.addPageRef))
  const removePageRef = usePDFifyStore(
    useShallow((state) => state.removePageRef),
  )

  useEffect(() => {
    if (ref.current) {
      addPageRef(id, order, ref.current)
    }

    return () => {
      removePageRef(id)
    }
  }, [addPageRef, id, order, removePageRef])

  return (
    <div className="h-full w-full" ref={ref}>
      {children}
    </div>
  )
}
