import { Checkbox } from '@/components/ui/checkbox'
import { User } from '@/lib/api/client'
import { useListUsersFromOrganizations } from '@/services/hooks/users'
import { useAppStore, useProjectsState } from '@/state'

export const CreatorsFilters = () => {
  const users = useListUsersFromOrganizations()

  return (
    <div className="flex flex-col flex-1 my-2">
      <h3 className="text-md border-b py-2">Document creator</h3>
      <div className="flex flex-row flex-wrap gap-y-2 justify-between mt-4">
        {users.map((user) => (
          <CreatorsFilter key={user.id} user={user} />
        ))}
      </div>
    </div>
  )
}

type CreatorsFilterProps = {
  user: User
}
const CreatorsFilter = ({ user }: CreatorsFilterProps) => {
  const currentUser = useAppStore((state) => state.currentUser)
  const projectCreatorsFilter = useProjectsState(
    (state) => state.projectCreatorsFilter,
  )
  const setProjectCreatorFilter = useProjectsState(
    (state) => state.setProjectCreatorFilter,
  )
  return (
    <div className="p-2 flex items-center" style={{ width: '48%' }}>
      <Checkbox
        className="mr-2 h-6 w-6"
        onCheckedChange={(checked) => {
          const updatedCreators = checked
            ? [...projectCreatorsFilter, user.id]
            : projectCreatorsFilter.filter((id) => id !== user.id)
          setProjectCreatorFilter(updatedCreators as Array<number>)
        }}
        checked={projectCreatorsFilter.includes(user.id as number)}
      />
      <span>
        {user.first_name} {user.last_name}{' '}
        {currentUser?.id === user.id && '(You)'}
      </span>
    </div>
  )
}
