import { useDocument } from '@/services/queries/documents'
import {
  useGetLatestDocumentVersion,
  useGetDocumentVersion,
} from '@/services/queries/document_versions'

export const useDocumentVersion = ({
  documentId,
  documentVersionId,
}: {
  documentId: string
  documentVersionId?: string | null
}) => {
  const { isLoading: isLoadingDocument, data: latestDocument } = useDocument({
    documentId,
  })

  const {
    isLoading: isSelectedDocVersionLoading,
    data: selectedDocumentVersion,
  } = useGetDocumentVersion({
    documentVersionId,
  })

  const { isLoading: isDocVersionLoading, data: latestDocumentVersion } =
    useGetLatestDocumentVersion({
      documentId: latestDocument?.id,
    })

  const isLoading =
    isLoadingDocument || isDocVersionLoading || isSelectedDocVersionLoading

  const documentVersion = documentVersionId
    ? selectedDocumentVersion
    : latestDocumentVersion

  return {
    isLoading,
    data: documentVersion,
  }
}
