import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useCadPageParams } from '@/pages/CADPage/hooks'

export const useAutoSelectedExplodedPart = () => {
  const { documentId } = useCadPageParams()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const { isLoading: isCadLoading, data: cadData } = useCADQuery()
  const { isLoading: isDocPageLoading, data: docData } = useDocumentPageQuery()

  const activeStep = useCADPageStore((state) => state.operationStep)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))

  const documentType = docData?.documentType

  const tree = cadData.rawAssemblyTree

  const isLoading = isLoadingSteps || isCadLoading || isDocPageLoading

  return useCallback(() => {
    if (isLoading) {
      console.warn('Unable to auto selected part for explosion: CAD is loading')
      return
    }

    if (!tree) {
      console.warn(
        'Unable to auto selected part for explosion: CAD tree is undefined',
      )
      return
    }

    const { explosionsToolbar, selectedParts } = getCadPageState()

    if (!explosionsToolbar) {
      return
    }

    if (activeStep?.selectFromCad) {
      setCadPageState({
        operationStep: {
          documentId,
          stepId: activeStep?.stepId as string,
          isActive: true,
          selectFromCad: false,
        },
      })
    }

    if (selectedParts.length > 0) {
      return
    }

    const step = steps.find((step) => step.id === activeStep?.stepId)
    if (step && step.assembly_group_ids.length > 0) {
      setCadPageState({ selectedParts: step.assembly_group_ids })
      return
    }

    const firstPartInTree = tree?.nodes[0].children[0]

    const lastPartInTree =
      tree?.nodes[0].children[tree?.nodes[0].children.length - 1]

    const selected =
      documentType === 'work_instructions'
        ? [lastPartInTree]
        : [firstPartInTree]

    setCadPageState({ selectedParts: selected })
  }, [activeStep?.stepId, documentType, isLoading, steps, tree])
}
