import * as THREE from 'three'

export const frontMaterialProperties: THREE.MeshStandardMaterialParameters = {
  side: THREE.FrontSide,
  clipShadows: true,
  stencilWrite: false,
  stencilRef: 1,
  stencilFunc: THREE.AlwaysStencilFunc,
  stencilFail: THREE.KeepStencilOp,
  stencilZFail: THREE.KeepStencilOp,
  stencilZPass: THREE.ReplaceStencilOp,
  depthTest: true,
  depthWrite: true,

  polygonOffset: true,
  polygonOffsetFactor: 0.1,
  polygonOffsetUnits: 0.1,
  // depthFunc: THREE.LessDepth,
}

export const backMaterialProperties: THREE.MeshBasicMaterialParameters = {
  side: THREE.BackSide,
  stencilWrite: true,
  stencilRef: 1,
  stencilFunc: THREE.NotEqualStencilFunc,
  stencilFail: THREE.KeepStencilOp,
  stencilZFail: THREE.KeepStencilOp,
  stencilZPass: THREE.ReplaceStencilOp,
  depthTest: true,
  depthWrite: true, // true
  // depthFunc: THREE.LessEqualDepth,

  // Positive value pushes polygons away from the camera
  polygonOffset: true,
  polygonOffsetFactor: -0.1,
  polygonOffsetUnits: -0.1,
}

export const planeMaterialProperties = {
  metalness: 0.1,
  roughness: 0.75,

  // stencilWrite: true,
  // stencilRef: 0,
  // stencilFunc: THREE.NotEqualStencilFunc,
  // stencilFail: THREE.ReplaceStencilOp,
  // stencilZFail: THREE.ReplaceStencilOp,
  // stencilZPass: THREE.ReplaceStencilOp,

  side: THREE.DoubleSide,
  depthTest: true,
  depthWrite: true,
}

export const calculatePolygonOffset = (boundsMax: number, front: boolean) => {
  return front
    ? {
        polygonOffset: true,
        polygonOffsetFactor: boundsMax / 2,
        polygonOffsetUnits: boundsMax / 2,
      }
    : {
        polygonOffset: true,
        polygonOffsetFactor: -(boundsMax / 2),
        polygonOffsetUnits: -(boundsMax / 2),
      }
}
