import { useCallback, useEffect, useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useUpdateStepPartsFromCAD } from '../components/AssemblyOrderPanel/StepCardList/hooks/useUpdateStepPartsFromCAD'
import { useCADQuery } from '@/services/queries/cads'
import { useOperationSteps } from '@/services/queries/operation_steps'

export const useCADPageListeners = () => {
  const isEditModeActive = useCADPageStore(
    useShallow((state) => state.isEditModeActive),
  )
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const explosions = useCADPageStore((state) => state.explosions)
  const { data: cadData } = useCADQuery()
  const { steps } = useOperationSteps()
  const gltf = useMemo(() => cadData?.gltf, [cadData?.gltf])
  const assemblyTree = useMemo(
    () => cadData?.rawAssemblyTree,
    [cadData?.rawAssemblyTree],
  )

  const resetEditMode = useCADPageStore(
    useShallow((state) => state.resetEditMode),
  )
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const updateStepPartsFromCAD = useUpdateStepPartsFromCAD()
  const onResetEditMode = useCallback(() => {
    resetEditMode()

    updateStepPartsFromCAD()
  }, [resetEditMode, updateStepPartsFromCAD])

  const onExitEditMode = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') onResetEditMode()
    },
    [onResetEditMode],
  )

  const onUnselectStep = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (!assemblyTree) return
        gltf?.setVisibilityForOperationSteps({
          assemblyTree,
          steps,
          activeStep: null,
          hiddenParts,
          explosions,
          isExplosionLinesEnabled,
        })
        setCadPageState({
          selectedParts: [],
          operationStep: null,
        })
      }
    },
    [
      setCadPageState,
      gltf,
      steps,
      hiddenParts,
      explosions,
      isExplosionLinesEnabled,
      assemblyTree,
    ],
  )

  useEffect(() => {
    if (isEditModeActive) {
      document.addEventListener('keydown', onExitEditMode)
    } else {
      document.removeEventListener('keydown', onExitEditMode)
    }

    return () => {
      document.removeEventListener('keydown', onExitEditMode)
    }
  }, [isEditModeActive, onExitEditMode])

  useEffect(() => {
    if (activeOperationStep && !isEditModeActive) {
      document.addEventListener('keydown', onUnselectStep)
    } else {
      document.removeEventListener('keydown', onUnselectStep)
    }

    return () => {
      document.removeEventListener('keydown', onUnselectStep)
    }
  }, [activeOperationStep, isEditModeActive, onUnselectStep])
}
