import { useDocument } from '@/services/queries/documents'
import { useCad } from '@/services/queries/cads/queries/useCad'
import {
  useListCadVersions,
  useGetCadVersion,
} from '@/services/queries/cad_versions'

export const useCadVersion = ({
  documentId,
  cadVersionId,
}: {
  documentId: string
  cadVersionId?: string | null
}) => {
  const { isLoading: isDocumentLoading, data: latestDocument } = useDocument({
    documentId,
  })

  const { isLoading: isCadLoading, data: cad } = useCad({
    cadId: latestDocument?.cad,
  })

  const { isLoading: isCadVersionsLoading, data: cadVersions } =
    useListCadVersions({ cadId: cad?.id })

  const latestCadVersion =
    Array.isArray(cadVersions) && cadVersions.length ? cadVersions[0] : null

  const { isLoading: isCadVersionLoading, data: selectedCadVersion } =
    useGetCadVersion({ cadVersionId })

  const cadVersion = cadVersionId ? selectedCadVersion : latestCadVersion

  const isLoading =
    isDocumentLoading ||
    isCadLoading ||
    isCadVersionsLoading ||
    isCadVersionLoading

  return {
    isLoading,
    data: cadVersion,
  }
}
