import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useDocumentVersion } from '../../ScreenshotPanelButton/hooks/useDocumentVersion'

export const useDocumentVersionId = () => {
  const { documentId, dv: selectedDocumentVersionId } = useCadPageParams()
  const { data: documentVersion } = useDocumentVersion({
    documentId,
    documentVersionId: selectedDocumentVersionId,
  })

  return documentVersion?.id
}
