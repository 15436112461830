import { useQuery } from '@tanstack/react-query'
import queryClient from '@/queryClient'

import { listUserOrganizations } from '@/lib/api/client/organizations'
import QUERY_KEYS from './queryKeys'

export const listUserOrganizationsQuery = () => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS],
    queryFn: listUserOrganizations,
  })
}

export const useListUserOrganizations = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS],
    queryFn: listUserOrganizations,
  })
}
