import { ReactNode } from 'react'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button, ButtonProps } from '@/components/ui/button'

interface ControlButtonProps extends ButtonProps {
  icon: ReactNode
  isActive?: boolean
  title: string
}

export const ControlButton = ({
  icon,
  title,
  isActive,
  ...btnProps
}: ControlButtonProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="!h-auto"
            {...btnProps}
            style={{ backgroundColor: isActive ? '#D2E8FF' : undefined }}
          >
            <div className="flex flex-col items-center space-y-2 hover:color-primary cursor-pointer text-xs">
              {icon}
              <div>{title}</div>
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>{title}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
