import { useEffect } from 'react'
import { Box3, Vector3 } from 'three'
import { useThree } from '@react-three/fiber'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { useCADPageStore } from '@/pages/CADPage/state'

export const useBounds = (gltf?: GLTFObject) => {
  const { camera } = useThree() as { camera: THREE.PerspectiveCamera }

  const setCadPageState = useCADPageStore((state) => state.setState)
  const cameraPosition = useCADPageStore((state) => state.cameraPosition)
  const cameraUp = useCADPageStore((state) => state.cameraUp)
  const cameraZoom = useCADPageStore((state) => state.cameraZoom)
  const cameraQuaternion = useCADPageStore((state) => state.cameraQuaternion)
  const cameraFov = useCADPageStore((state) => state.cameraFov)
  const cameraNear = useCADPageStore((state) => state.cameraNear)
  const cameraFar = useCADPageStore((state) => state.cameraFar)
  const boundsMax = useCADPageStore((state) => state.boundsMax)

  const distanceFactor = 1.3
  const defaultCameraNear = 0.001
  const cameraFarFactor = 50

  useEffect(() => {
    if (!gltf) return

    const bounds = new Box3().setFromObject(gltf?.scene)
    const boundsSizes = bounds.getSize(new Vector3())
    const boundsMaxSize = Math.max(...boundsSizes.toArray())
    const center = bounds.getCenter(new Vector3())

    gltf.scene.position.sub(center)
    if (gltf.wireFrameScene) {
      gltf.wireFrameScene.position.sub(center)
    }

    if (!boundsMax) {
      setCadPageState({ boundsMax: boundsMaxSize })
    }

    if (!cameraNear) {
      camera.near = defaultCameraNear
      setCadPageState({ cameraNear: camera.near })
    }

    if (!cameraFar) {
      camera.far = cameraFarFactor * distanceFactor * boundsMaxSize
      setCadPageState({ cameraFar: camera.far })
    }

    if (cameraPosition.length) {
      camera.position.fromArray(cameraPosition)
    } else {
      const position = [
        center.x + boundsMaxSize * distanceFactor,
        center.y + boundsMaxSize * distanceFactor,
        center.z + boundsMaxSize * distanceFactor,
      ]
      camera.position.fromArray(position)
      setCadPageState({ cameraPosition: position })
    }

    camera.fov = cameraFov
    camera.zoom = cameraZoom
    camera.up.fromArray(cameraUp)
    camera.quaternion.fromArray(cameraQuaternion)

    camera.updateProjectionMatrix()
  }, [gltf])
}
