export enum MUTATION_KEYS {
  CREATE_TEMPLATE_ATTRIBUTE = 'CREATE_TEMPLATE_ATTRIBUTE',
  UPDATE_TEMPLATE_ATTRIBUTE = 'UPDATE_TEMPLATE_ATTRIBUTE',
  DELETE_TEMPLATE_ATTRIBUTE = 'DELETE_TEMPLATE_ATTRIBUTE',
  CREATE_TABLE = 'CREATE_TABLE',
  UPDATE_TABLE = 'UPDATE_TABLE',
  DELETE_TABLE = 'DELETE_TABLE',
}

export default MUTATION_KEYS
