import { forwardRef } from 'react'
import { Editor, Range } from '@tiptap/react'
import { Spinner } from '@/components/ui/spinner'
import { useToast } from '@/components/ui/use-toast'
import { useListTemplateAttributes } from '@/services/queries/template_attributes'
import {
  useFullPartsBOM,
  useFullToolsBOM,
  useStepBOM,
} from '@/pages/DocumentPage/components/OperationDocument/hooks/useBOM'

import { CommandSelector } from './CommandSelector'
import { useCommand } from './hooks/useCommand'
import { useCommandItems } from './hooks/useCommandItems'
import type { EditorAttributes } from '../../lib/types'

interface SlashCommandProps {
  query: string
  editor: Editor
  range: Range
  updateBomOnSelect: boolean
}

export const CommandList = forwardRef<any, SlashCommandProps>(
  ({ query, editor, range, updateBomOnSelect }, ref) => {
    const editorAttributes = editor.options.editorProps
      .attributes as EditorAttributes

    const documentPageId = editorAttributes?.documentPageId

    const command = useCommand({ query })

    const { toast } = useToast()

    const { isLoading, data: items } = useCommandItems({
      commandName: command.name,
      documentPageId: documentPageId as string,
    })

    const stepBom = useStepBOM({ documentPageId })
    const fullBom = useFullPartsBOM()
    const fullToolsBom = useFullToolsBOM()

    const templateAttributes = useListTemplateAttributes({
      documentPageId,
    })

    if (isLoading || templateAttributes.isLoading) {
      return <Spinner />
    }

    if (!items) {
      return null
    }

    return (
      <CommandSelector
        parentRef={ref}
        items={items}
        canMultiSelect={Boolean(command.multiSelect)}
        query={query.replace(command.path.slice(1), '')}
        editor={editor}
        range={range}
        updateBomOnSelect={updateBomOnSelect}
        onSelect={async (items) => {
          command.onSelect({
            items,
            editor,
            range,
            bom: stepBom,
            fullBom,
            fullToolsBom,
            templateAttributes: templateAttributes.data || [],
            updateBomOnSelect: updateBomOnSelect,
            toast,
            query,
          })
        }}
        renderEmptyResults={command.renderEmptyResults}
      />
    )
  },
)

CommandList.displayName = 'CommandList'
