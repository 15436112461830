import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '@/queryClient'

import {
  createComment,
  listComments,
  updateComment,
  deleteComment,
} from '@/lib/api/client/comments'
import QUERY_KEYS from './queryKeys'
import MUTATION_KEYS from './mutationKeys'
import {
  DocumentPageComment,
  DocumentPageCommentInput,
  MappedDocumentPageComment,
  User,
} from '@/lib/api/client'
import { getUserByIdQuery } from '@/services/hooks/users'
import { useAppStore } from '@/state'

export const listCommentsQuery = ({
  documentPageId,
}: {
  documentPageId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.COMMENTS, { documentPageId }],
    queryFn: async () => {
      const comments = await listComments(documentPageId)
      return comments
    },
  })
}

export const useListComments = ({
  documentPageId,
}: {
  documentPageId?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.COMMENTS, { documentPageId }],
    queryFn: async () => {
      if (!documentPageId) return []
      const comments = await listComments(documentPageId)
      return await Promise.all(
        comments.map(async (comment) => ({
          ...comment,
          creator: await getUserByIdQuery(comment.creator),
        })),
      )
    },
    enabled: !!documentPageId,
  })
}

export const useCreateComment = () => {
  const currentUser = useAppStore((state) => state.currentUser)
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_COMMENT],
    mutationFn: async ({
      documentPageId,
      values,
    }: {
      documentPageId: string
      values: DocumentPageCommentInput
    }) => {
      const comment = await createComment(documentPageId, values)
      return {
        ...comment,
        creator: currentUser as User,
      }
    },
    onSuccess: (comment) => {
      queryClient.setQueriesData(
        {
          queryKey: [
            QUERY_KEYS.COMMENTS,
            { documentPageId: comment.document_page },
          ],
        },
        (comments: Array<MappedDocumentPageComment> | undefined) => {
          if (comments) {
            return [...comments, comment]
          }
          return [comment]
        },
      )
    },
  })
}

export const useUpdateComment = () => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_COMMENT],
    mutationFn: async ({
      commentId,
      documentPageId,
      values,
    }: {
      commentId: string
      documentPageId: string
      values: DocumentPageCommentInput
    }) => {
      await updateComment(commentId, values)
      return {
        values,
        commentId,
        documentPageId,
      }
    },
    onSuccess: ({ values, documentPageId, commentId }) => {
      queryClient.setQueriesData(
        {
          queryKey: [QUERY_KEYS.COMMENTS, { documentPageId }],
        },
        (comments: Array<DocumentPageComment> | undefined) => {
          if (comments) {
            return comments.map((comment: DocumentPageComment) => {
              if (comment.id === commentId) {
                return {
                  ...comment,
                  ...values,
                }
              }
              return comment
            })
          }
          return comments
        },
      )
    },
  })
}

export const useDeleteComment = () => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_COMMENT],
    mutationFn: async ({
      commentId,
      documentPageId,
    }: {
      commentId: string
      documentPageId: string
    }) => {
      await deleteComment(commentId)
      return {
        documentPageId,
        commentId,
      }
    },
    onSuccess: ({ documentPageId, commentId }) => {
      queryClient.setQueriesData(
        {
          queryKey: [QUERY_KEYS.COMMENTS, { documentPageId }],
        },
        (comments: Array<DocumentPageComment> | undefined) => {
          if (comments) {
            return comments.filter(
              (c: DocumentPageComment) => c.id !== commentId,
            )
          }
          return comments
        },
      )
    },
  })
}
