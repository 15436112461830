import { cn } from '@/utils'
import { useShallow } from 'zustand/react/shallow'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { AddPartIcon } from '@/components/icons/AddPartIcon'
import { Step } from '@/services/queries/operation_steps/types'

import { useCadPageParams } from '../../hooks'
import { useCADQuery } from '@/services/queries/cads'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useUpdateStepPartsFromCAD } from './StepCardList/hooks/useUpdateStepPartsFromCAD'

export const AddPartFromCADButton = ({ step }: { step: Step }) => {
  const { documentId } = useCadPageParams()
  const { isLoading: isCADLoading, data: cadData } = useCADQuery()
  const { isLoading: isStepsLoading, steps } = useOperationSteps()
  const gltf = cadData.gltf

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const updateStepPartsFromCAD = useUpdateStepPartsFromCAD()

  const isLoading = isCADLoading || isStepsLoading
  const isActive = activeOperationStep?.stepId === step.id
  const selectFromCad = isActive && activeOperationStep?.selectFromCad

  if (isLoading || !gltf || !steps) return null

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            size="sm"
            variant="ghost"
            className="px-1 cursor-pointer hover:bg-teal-50"
            onMouseDown={(e) => {
              e.stopPropagation()

              setCadPageState({
                explosionsToolbar: false,
                isEditModeActive: !selectFromCad,
                selectedParts: selectFromCad ? [] : step.assembly_group_ids,
                operationStep: {
                  documentId,
                  stepId: step.id as string,
                  selectFromCad: !selectFromCad,
                  isActive: Boolean(isActive),
                },
              })
              updateStepPartsFromCAD()
            }}
          >
            <AddPartIcon
              className={cn('w-4 h-4 stroke-gray-400 hover:stroke-teal-600', {
                'stroke-teal-600': selectFromCad,
              })}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Add Parts From CAD</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
