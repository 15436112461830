import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useSearch, useLocation } from 'wouter'
import queryString from 'query-string'

import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  CollapsibleTriangleIcon,
} from '@/components/ui/collapsible'
import { Button } from '@/components/ui/button'
import { BookIcon } from '@/components/icons/BookIcon'
import { DocumentTextIcon } from '@/components/icons/DocumentTextIcon'
import { ListBulletIcon } from '@/components/icons/ListBulletIcon'
import { ClockIcon } from '@/components/icons/ClockIcon'
import { PartIcon } from '@/components/icons/PartIcon'
import {
  usePartsTables,
  useToolsTables,
} from '@/services/hooks/template_attributes'
import { useStepBOM } from '../OperationDocument/hooks/useBOM'
import { ToolIcon } from '@/components/icons/ToolIcon'
import { DocumentPage } from '@/lib/api/client'
import { useDocumentState } from '@/state'
import { ProjectDocumentTitle } from '@/components/core/ProjectDocumentTitle'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { cn } from '@/utils'

export const WorkInstructionsTOC = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const currentPageInView = useDocumentState(
    useShallow((state) => state.currentPageInView),
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  const project = data?.project

  if (!project) {
    return null
  }

  const navigateToPage = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    selector: string,
  ) => {
    e.preventDefault()
    document.querySelector(selector)?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  const operations = data.documentPages
  const numOfDocVersions = data.documentVersions.length

  return (
    <div className="h-full overflow-auto pb-5 text-sm">
      <div className="my-4 px-10">
        <ProjectDocumentTitle />
      </div>
      <div className="flex flex-col">
        <a
          href="#cover"
          className={cn(
            'flex items-center space-x-2 px-10 py-1.5 font-medium hover:bg-indigo-50 hover:text-primary-50',
            {
              'bg-indigo-100': currentPageInView === 'cover',
            },
          )}
          onClick={(e) => navigateToPage(e, '#cover')}
        >
          <BookIcon className="w-4 h-4" />
          <span>Cover</span>
        </a>
        {numOfDocVersions > 1 && (
          <a
            href="#history"
            className={cn(
              'flex items-center space-x-2 px-10 py-1.5 font-medium hover:bg-indigo-50 hover:text-primary-50',
              {
                'bg-indigo-100': currentPageInView === 'history',
              },
            )}
            onClick={(e) => navigateToPage(e, '#history')}
          >
            <ClockIcon className="w-4 h-4" />
            <span>Version History</span>
          </a>
        )}

        <a
          href="#bom"
          className={cn(
            'flex items-center space-x-2 px-10 py-1.5 font-medium hover:bg-indigo-50 hover:text-primary-50',
            {
              'bg-indigo-100': new RegExp(/^parts-bom--\d+$/).test(
                currentPageInView,
              ),
            },
          )}
          onClick={(e) => navigateToPage(e, '#bom')}
        >
          <ListBulletIcon className="w-4 h-4" />
          <span>Bill of Materials</span>
        </a>
        {operations.map((operation, i) => {
          return <OperationItem operation={operation} key={`operation_${i}`} />
        })}
      </div>
    </div>
  )
}

const OperationItem = ({ operation }: { operation: DocumentPage }) => {
  const currentPageInView = useDocumentState(
    useShallow((state) => state.currentPageInView),
  )

  const [, setLocation] = useLocation()
  const search = useSearch()
  const { projectId, documentId } = useCadPageParams()

  return (
    <Collapsible>
      <a
        href={`${operation.id}`}
        className={cn(
          'flex items-center space-x-2 font-medium hover:bg-indigo-50 hover:text-primary-50',
          {
            'bg-indigo-100': currentPageInView === `${operation.id}`,
          },
        )}
        onClick={(e) => {
          e.preventDefault()
          const searchParams = queryString.parse(search) as {
            cv?: string // cad version
            dv?: string // document version
            page_id?: string // document page id
          }
          const route = `/p/${projectId}/document/${documentId}/edit?${queryString.stringify({ ...searchParams, page_id: operation.id })}`
          setLocation(route)

          setTimeout(() => {
            const pageId = operation.id

            if (!pageId) return

            const page = document.getElementById(pageId)

            if (page) {
              page.scrollIntoView({
                block: 'center',
                inline: 'start',
              })
            }
          }, 0)
        }}
      >
        <CollapsibleTrigger>
          <Button variant="ghost" className="px-2">
            <CollapsibleTriangleIcon className="w-5 h-5 text-indigo-400" />
          </Button>
        </CollapsibleTrigger>
        <DocumentTextIcon className="w-4 h-4" />
        <span>Operation {operation.order_number}</span>
      </a>
      <CollapsibleContent>
        <div className="pl-6">
          <PartsList documentPageId={operation.id} />
        </div>
        <div className="pl-6 mt-4">
          <ToolsList documentPageId={operation.id as string} />
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

const PartsList = ({ documentPageId }: { documentPageId?: string | null }) => {
  const bom = useStepBOM({ documentPageId })

  const partsTableTemplates = usePartsTables(documentPageId)
  const partsCustomRows = useMemo(() => {
    return partsTableTemplates.reduce((accum, template) => {
      const rows = template.template_values.rows as any
      const columns = template.template_values.columns as any

      if (!rows?.length) return [...accum]

      if (columns?.length) {
        return [...accum, ...(rows || [].slice(bom.length, rows.length))]
      }

      return [...accum, ...rows]
    }, [] as any)
  }, [bom?.length, partsTableTemplates])

  const customParts = useMemo(
    () =>
      Array.from(new Set(partsCustomRows.map((part) => part.partName))).map(
        (partName) => ({ partName }),
      ),
    [partsCustomRows],
  )

  return (
    <Collapsible defaultOpen={true}>
      <div className="flex items-center space-x-2">
        <CollapsibleTrigger>
          <CollapsibleTriangleIcon className="w-5 h-5 text-indigo-400" />
        </CollapsibleTrigger>
        <PartIcon className="w-4 h-4" />
        <span className="font-semibold">Parts</span>
      </div>
      <CollapsibleContent>
        <div className="pl-10 mt-2">
          {[...bom, ...customParts].map((part, i) => (
            <div key={`part_${i}`} className="flex items-center space-x-2">
              <span>{part.partName as any}</span>
            </div>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

const ToolsList = ({ documentPageId }: { documentPageId: string }) => {
  const toolsTableTemplates = useToolsTables(documentPageId)
  const toolsRows = useMemo(() => {
    return toolsTableTemplates.reduce((accum, template) => {
      const rows = template.template_values.rows as any

      if (!rows?.length) return [...accum]

      return [...accum, ...rows]
    }, [] as any)
  }, [toolsTableTemplates])

  const toolsList = useMemo(
    () =>
      Array.from(
        new Set(toolsRows.map((toolsRow) => toolsRow.toolName || '')),
      ).map((toolName) => ({ toolName })),
    [toolsRows],
  )

  return toolsList.length ? (
    <Collapsible defaultOpen={true}>
      <div className="flex items-center space-x-2">
        <CollapsibleTrigger>
          <CollapsibleTriangleIcon className="w-5 h-5 text-indigo-400" />
        </CollapsibleTrigger>
        <ToolIcon className="w-4 h-4" />
        <span className="font-semibold">Tools</span>
      </div>
      <CollapsibleContent>
        <div className="pl-10 mt-2">
          {toolsList.map((tool, i) => (
            <div key={`tool${i}`} className="flex items-center space-x-2">
              <span>{tool.toolName as any}</span>
            </div>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  ) : null
}
