import { Html } from '@react-three/drei'
import '@/lib/cad/screenshot-overlay.css'
import { useCADPageStore } from '../state'
import { cn } from '@/utils'

export const ScreenshotOverlay = () => {
  const hovered = useCADPageStore((state) => state.createViewButtonHover)

  return (
    <Html
      wrapperClass="w-full h-full transform-none"
      zIndexRange={[10, 11]}
      className={cn('pointer-events-none absolute m-auto inset-0 w-fit h-fit', {
        'screenshot-hover-effect': hovered,
      })}
    >
      <div className="lines"></div>
      <div className="angles"></div>
      <div
        className="overlay"
        style={{
          width: `${1080 / 1.75}px`,
          height: `${1024 / 1.75}px`,
        }}
      />
    </Html>
  )
}
