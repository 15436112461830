import { Skeleton } from '@/components/ui/skeleton'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

import { WorkInstructionsTOC } from './WorkInstructionsTOC'
import { ProjectTrackerTOC } from './ProjectTrackerTOC'

export const TableOfContents = () => {
  const { isLoading, data } = useDocumentPageQuery()

  if (isLoading) {
    return <Skeleton className="w-full h-6 bg-gray-200" />
  }

  const documentType = data?.documentType
  return documentType === 'work_instructions' ? (
    <WorkInstructionsTOC />
  ) : (
    <ProjectTrackerTOC />
  )
}
