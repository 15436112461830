import { useCallback } from 'react'

import {
  useAddPartToOperation,
  useDeletePartFromOperation,
  useOperationSteps,
} from '@/services/queries/operation_steps'
import { Step } from '@/services/queries/operation_steps/types'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useShallow } from 'zustand/react/shallow'

/**
 *
 * Custom hook that automatically adds a part to a step in an operation.
 * If part is already in step, part will be removed from step.
 * If part is in another step, part will be removed from that step before being added to new step.
 *
 */
export const useAutoAddPartToStep = () => {
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const { mutate: addPartToOperation } = useAddPartToOperation()
  const { mutate: deletePartFromOperation } = useDeletePartFromOperation()
  const { isLoading, steps } = useOperationSteps()

  const deleteFromStep = (partUUID: string, step: Step) => {
    const documentPageAssemblyReference =
      step?.documentpageassemblygroupids.find(
        (id) => id.assembly_group_id === partUUID,
      )

    if (documentPageAssemblyReference?.id) {
      deletePartFromOperation({
        assemblyGroupId: partUUID,
        documentPageId: step.id as string,
        documentVersionId: step.document_version as string,
        documentPageAssemblyReferenceId: documentPageAssemblyReference.id,
      })
    }
  }

  return useCallback(
    (partUUID: string) => {
      const { operationStep } = getCadPageState()
      const activeStep = steps.find((step) => step.id === operationStep?.stepId)

      if (!activeStep) return
      const isInActiveStep = activeStep?.assembly_group_ids.includes(partUUID)

      if (isInActiveStep) {
        deleteFromStep(partUUID, activeStep)
        return
      }

      if (isLoading || !steps) {
        console.warn('Unable to switch steps: Steps are loading')
        return
      }

      const otherStep = steps.find(
        (step) =>
          step.id !== activeStep.id &&
          step.assembly_group_ids.includes(partUUID),
      )

      if (otherStep) {
        // #TODO - Add a toast message here
        deleteFromStep(partUUID, otherStep)
      }

      addPartToOperation({
        assemblyGroupId: partUUID,
        documentPageId: activeStep.id as string,
        documentVersionId: activeStep.document_version as string,
      })

      // #TODO - Fix timing
      // const el = document.getElementById(`step-part-diplay-name-${partUUID}`)
      // console.log(el)
      // el?.classList.add('teal-600')
      // setTimeout(() => {
      //   el?.classList.remove('teal-600')
      // }, 5000)
    },
    [steps],
  )
}
