import { SERVICE_WORKER_ACTIONS, RESPONSE_TYPES } from './constants'
export class ServiceWorker {
  private worker: globalThis.ServiceWorker | null
  constructor() {
    this.worker = null
    this.start()
  }

  async start() {
    if ('serviceWorker' in navigator) {
      const serviceWorkerUrl = new URL(
        '/serviceWorkerProcess.js',
        import.meta.url,
      )
      const registrations = await navigator.serviceWorker.getRegistrations()
      const registration = registrations.find(
        (registration) =>
          registration.active?.scriptURL === serviceWorkerUrl.href,
      )

      if (registration) {
        await registration.update()
      }

      const { installing, waiting, active } =
        await navigator.serviceWorker.register(serviceWorkerUrl, {
          type: 'module',
        })
      this.worker = active || installing || waiting

      this.worker?.addEventListener('statechange', (event: any) => {
        if (event.target?.state === 'activated') {
          this.worker = event.target
        }
      })

      navigator.serviceWorker.onmessage = ({ data }: any) =>
        this.handleResponse(data)
    }
  }

  private handleResponse = ({
    action,
    payload,
  }: {
    action: string
    payload: any
  }) => {
    const handlers = {
      [SERVICE_WORKER_ACTIONS.HANDLE_ERROR]: this.onHandleError,
    }

    if (handlers[action]) handlers[action](payload)
  }

  private onUnauthenticatedHandler = () => {
    const loginUrl = `/login?continue_to=${encodeURIComponent(document.location.pathname + document.location.search)}`
    window.location.href = loginUrl
  }

  private onHandleError = ({
    status,
  }: {
    url: string
    status: number
    statusText: string
  }) => {
    const handlers = {
      [RESPONSE_TYPES.UNAUTHORIZED]: () => this.onUnauthenticatedHandler(),
    }

    if (handlers[status]) handlers[status]()
  }

  public deleteCache(cacheKey: string) {
    if (this.worker) {
      this.worker.postMessage({
        action: SERVICE_WORKER_ACTIONS.DELETE_CACHE,
        payload: {
          cacheKey,
        },
      })
    }
  }

  public addToCache({
    cacheKey,
    response,
  }: {
    cacheKey: string
    response: Response
  }) {
    if (this.worker) {
      this.worker.postMessage({
        action: SERVICE_WORKER_ACTIONS.ADD_TO_CACHE,
        payload: {
          cacheKey,
          response: response.clone(),
        },
      })
    }
  }
}
