import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * List all projects in a user's account
 */
export const listProjects = async ({
  limit,
  offset,
  userId = [],
}: {
  limit?: number
  offset?: number
  userId?: Array<number>
}) => {
  const resp = await client.GET('/api/v1/projects', {
    params: {
      query: {
        limit,
        offset,
        user_id: userId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list projects')
  }

  return {
    projects: resp.data.items,
    totalProjects: resp.data.count,
    limit,
    offset,
  }
}

/**
 * Get a project
 * @param projectId
 * @returns
 */
export const getProject = async (projectId: string) => {
  const resp = await client.GET('/api/v1/projects/{project_id}', {
    params: {
      path: {
        project_id: projectId,
      },
    },
  })

  if (resp.error || !resp.response.ok || !resp.data.id) {
    throw new Error('Failed to get project')
  }
  return resp.data
}

/**
 * Create a new project
 * @param values
 * @returns
 */
export const createProject = async (values: { name: string }) => {
  await getCsrf()
  const resp = await client.POST('/api/v1/projects', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    body: values,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create project')
  }

  return resp.data
}

export const updateProject = async (
  projectId: string,
  values: { name: string },
) => {
  await getCsrf()
  const resp = await client.PUT('/api/v1/projects/{project_id}', {
    params: {
      path: {
        project_id: projectId,
      },
    },
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    body: values,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to update project')
  }

  return resp.data
}

export const deleteProject = async (projectId: string) => {
  await getCsrf()
  const resp = await client.DELETE('/api/v1/projects/{project_id}', {
    params: {
      path: {
        project_id: projectId,
      },
    },
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to delete project')
  }

  return resp.data
}
