import { CaretDownIcon } from '@radix-ui/react-icons'
import { AxisIcon } from '@/components/icons/AxisIcon'
import { ResetIcon } from '@/components/icons/ResetIcon'
import { WandFillIcon } from '@/components/icons/WandFillIcon'
import { WandOutlineIcon } from '@/components/icons/WandOutlineIcon'
// import { DrumIcon } from '@/components/icons/DrumIcon'
// import { DrumOutlineIcon } from '@/components/icons/DrumOutlineIcon'
// import { DrumTransparentIcon } from '@/components/icons/DrumTransparentIcon'
import { WandButtonState } from '../../state'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { ControlButton } from './ControlButton'
import { ExplosionsToolbar } from './ExplosionsButton/ExplosionsButton'
import { CrossSectionButton } from './CrossSectionButton/CrossSectionButton'

import { useShallow } from 'zustand/react/shallow'
import { useCADQuery } from '@/services/queries/cads'
import { SectionState, useCADPageStore } from '@/pages/CADPage/state'
import { COLOR_OPTIONS } from '@/constants'

export const Controls = () => {
  const toggleAxis = useCADPageStore((state) => state.toggleAxis)
  const toggleExplosions = useCADPageStore((state) => state.toggleExplosions)
  const explosions = useCADPageStore((state) => state.explosions)
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const sectionState = useCADPageStore((state) => state.sectionState)
  const toggleSectioning = useCADPageStore((state) => state.toggleSectioning)

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const explodeObjectRef = useCADPageStore((state) => state.explodeObjectRef)
  const wandSelected = useCADPageStore((state) => state.wandSelected)
  const setWandSelected = useCADPageStore((state) => state.setWandSelected)
  // const renderMode = useCADPageStore((state) => state.renderMode)
  const parts = useCADPageStore(useShallow((state) => state.transparentParts))
  const colorMap = useCADPageStore((state) => state.colorMap)

  const { isLoading, data: cadData } = useCADQuery()
  const gltf = cadData.gltf
  const assemblyTree = cadData.rawAssemblyTree

  if (isLoading || !gltf || !assemblyTree) return null

  return (
    <>
      <div>
        <ControlButton
          title="Show Axis"
          icon={<AxisIcon className="w-4 h-4" />}
          onClick={() => {
            toggleAxis()
          }}
        />
      </div>
      <div>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger>
            <ControlButton
              asChild
              icon={
                <div className="flex flex-row items-center">
                  {getWandIcon(wandSelected)}
                  <CaretDownIcon />
                </div>
              }
              isActive={wandSelected !== null}
              title="Appearance"
              onClick={(e) => {
                if (explodeObjectRef?.current !== null) {
                  if (gltf) gltf.unhighlightParts(colorMap)
                  setCadPageState({
                    explodeObjectRef: null,
                    selectedParts: [],
                    explosionsToolbar: false,
                    isEditModeActive: false,
                  })
                }
                if (wandSelected !== null) {
                  e.preventDefault()
                  setWandSelected(null)
                  setCadPageState({
                    isEditModeActive: false,
                  })
                }
              }}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex flex-row">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <ControlButton
                  asChild
                  icon={<WandOutlineIcon className="w-4 h-4" />}
                  title="Color"
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="flex flex-row">
                {COLOR_OPTIONS.map((color) => {
                  const colorValue = `#${color.value.toString(16).padStart(6, '0').toUpperCase()}`
                  return (
                    <DropdownMenuItem
                      key={color.value}
                      onClick={() => {
                        setWandSelected(
                          wandSelected === colorValue
                            ? null
                            : (colorValue as string),
                        )
                        setCadPageState({
                          isEditModeActive: wandSelected !== colorValue,
                        })
                      }}
                    >
                      <ControlButton
                        className="p-1 w-12 h-12 rounded-md"
                        icon={
                          <WandFillIcon
                            className="w-4 h-4"
                            color={colorValue}
                          />
                        }
                        title={`${color.name}`}
                      />
                    </DropdownMenuItem>
                  )
                })}
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenuItem>
              <ControlButton
                isActive={wandSelected === 'transparency'}
                icon={<WandOutlineIcon className="w-4 h-4" />}
                title="Transparency"
                onClick={() => {
                  setWandSelected(
                    wandSelected === 'transparency' ? null : 'transparency',
                  )
                  setCadPageState({
                    isEditModeActive: wandSelected !== 'transparency',
                  })
                }}
              />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div>
        <ControlButton
          title="Reset"
          icon={<ResetIcon className="w-4 h-4" />}
          onClick={() => {
            gltf.showExplosionLines(explosions, assemblyTree, false)
            gltf.resetExplosions(explosions)
            gltf.unhighlightParts(colorMap)
            gltf.clearDragLines()
            gltf.resetColors()
            gltf.resetTransparency(parts)
            gltf.resetClippingPlanes()

            setCadPageState({
              isDragging: false,
              explodeObjectRef: null,
              selectedParts: [],
              loadedExplosion: '',
              explosions: {},
              hiddenParts: [],
              colorMap: {},
              transparentParts: [],
              highlightedPartUUID: null,
              isEditModeActive: false,
              crossSectionMap: [],
              clippingPlanes: [],
            })
            if (explosionsToolbar) {
              toggleExplosions()
            }
            if (sectionState && sectionState !== SectionState.DISABLED)
              toggleSectioning()
          }}
        />
      </div>
      <ExplosionsToolbar />
      {/* <div>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger>
            <ControlButton
              asChild
              icon={
                <div className="flex flex-row items-center">
                  <DrumIcon className="w-4 h-4" />
                  <CaretDownIcon />
                </div>
              }
              onClick={(e) => {
                e.stopPropagation()
                setWandSelected(null)
                setCadPageState({
                  selectedParts: [],
                  explosionsToolbar: false,
                })
              }}
              title="Display"
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex flex-row">
            <DropdownMenuItem>
              <ControlButton
                isActive={renderMode === 'full-color'}
                icon={<DrumTransparentIcon className="w-4 h-4" />}
                title="Color"
                onClick={() => {
                  setCadPageState({
                    renderMode: 'full-color',
                  })
                }}
              />
            </DropdownMenuItem>
            <DropdownMenuItem>
              <ControlButton
                isActive={renderMode === 'outline'}
                icon={<DrumOutlineIcon className="w-4 h-4" />}
                title="Outline"
                onClick={() => {
                  setCadPageState({
                    renderMode: 'outline',
                  })
                }}
              />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div> */}
      <CrossSectionButton />
    </>
  )
}

const getWandIcon = (wandSelected: WandButtonState) => {
  switch (wandSelected) {
    case null:
      return <WandFillIcon className="w-4 h-4" />
    case 'transparency':
      return <WandOutlineIcon className="w-4 h-4" />
    default:
      return <WandFillIcon className="w-4 h-4" color={wandSelected} />
  }
}
