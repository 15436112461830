import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { ErrorBoundary } from '@sentry/react'
import { ResizableBox } from 'react-resizable'
import dayjs from 'dayjs'

import { Image } from '@/components/pdf/Image'
import { useDocumentState } from '@/state/document'
import {
  ImageTemplate,
  TemplateAttribute,
  useUpdateTemplateElement,
  ViewTemplate,
} from '@/services/hooks/template_attributes'
import { useDeleteTemplateAttribute } from '@/services/queries/template_attributes'
import { cn } from '@/utils'
import { Spinner } from '@/components/ui/spinner'
import { useDocumentPageQuery } from '../queries'

type DraggableImageProps = {
  documentPageId: string
  userFacingDownloadUrl: string
  isAddingAnnotation: boolean
  downloadUrl: string
  type: 'image' | 'view'
  isReadOnly?: boolean
  className?: string
  timestamp?: string
  imageTemplate: TemplateAttribute<ImageTemplate | ViewTemplate>
}
export const DraggableImage = ({
  documentPageId,
  userFacingDownloadUrl,
  isAddingAnnotation,
  downloadUrl,
  type,
  isReadOnly,
  className,
  timestamp,
  imageTemplate,
}: DraggableImageProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const containerRef = useRef<HTMLDivElement>(null)
  const zoom = useDocumentState((state) => state.zoom)
  const zoomRatio = useMemo(() => zoom / 100, [zoom])
  const selectElement = useDocumentState((state) => state.setSelectedElement)
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === imageTemplate?.id,
    [selectedElement, documentPageId, imageTemplate?.id],
  )
  const { data } = useDocumentPageQuery()
  const isProjectTrackerImage = useMemo(
    () => data?.documentType === 'project_tracker',
    [data?.documentType],
  )
  const [{ position, delta }, setPosition] = useState({
    position: {
      x: 0,
      y: 0,
    },
    delta: INITIAL_DELTA,
  })
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  })
  const positionData = useMemo(
    () => imageTemplate?.template_values?.position,
    [imageTemplate],
  )
  const imageSize = useMemo(
    () => imageTemplate?.template_values?.size,
    [imageTemplate],
  )
  const imagePosition = useMemo(
    () => imageTemplate?.template_values?.imagePosition || 'relative',
    [imageTemplate],
  )
  const { mutateAsync: updateTemplate } = useUpdateTemplateElement({
    documentPageId,
  })

  const {
    isPending: isDeletingTemplate,
    mutateAsync: deleteTemplateAttribute,
  } = useDeleteTemplateAttribute()

  const { attributes, isDragging, listeners, transform, setNodeRef } =
    useDraggable({
      disabled: isAddingAnnotation || isReadOnly,
      id: imageTemplate?.id as string,
      data: { type },
    })

  const deleteImageHandler = useCallback(async () => {
    await deleteTemplateAttribute({
      templateAttributeId: imageTemplate?.id as string,
    })
  }, [imageTemplate, deleteTemplateAttribute])

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteImageHandler()
      }
    },
    [deleteImageHandler],
  )

  const updateImageSizeHandler = useCallback(
    async (size: { width: number; height: number }) => {
      await updateTemplate({
        payload: {
          size,
        },
        data_type: type,
        templateAttributeId: imageTemplate?.id as string,
      })
    },
    [type, imageTemplate?.id, updateTemplate],
  )

  useEffect(() => {
    if (isProjectTrackerImage || !imageSize) return
    setContainerDimensions(imageSize)
  }, [imageSize, isProjectTrackerImage])

  useEffect(() => {
    let resizeObserver: ResizeObserver
    if (containerRef.current && !imageSize) {
      const rect = containerRef.current.getBoundingClientRect()
      setContainerDimensions({
        width: rect.width,
        height: rect.height,
      })

      resizeObserver = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect
        setContainerDimensions({
          width,
          height,
        })
      })

      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [containerRef, isProjectTrackerImage, imageSize])

  useEffect(() => {
    if (isSelected) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected])

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: (transform?.x || 0) / zoomRatio,
        y: (transform?.y || 0) / zoomRatio,
      },
    }))
  }, [transform, zoomRatio])

  useEffect(() => {
    if (!positionData) return
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  return (
    <>
      <div
        className="flex flex-1 p-px box-content relative"
        ref={containerRef}
        style={{
          minHeight: 180,
          minWidth: 320,
        }}
      />
      <div
        {...attributes}
        {...listeners}
        className={cn(
          'border rounded-md flex flex-1 p-px box-content absolute',
          className,
          {
            'cursor-move': !isReadOnly,
            'cursor-default': isReadOnly,
            'shadow-md': isDragging,
            'shadow-gray-200': isDragging,
            'bg-sky-200/10': isDragging,
            'z-[1]': !isDragging,
            'z-[100]': isDragging,
          },
        )}
        style={{
          translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
          top: position?.y || 0,
          left: position?.x || 0,
          borderColor: isSelected ? '#818cf8' : 'transparent',
        }}
      >
        {isDeletingTemplate && (
          <div className="absolute h-full w-full bg-zinc-400/60">
            <Spinner />
          </div>
        )}
        <ResizableBox
          width={containerDimensions.width}
          height={containerDimensions.height}
          onResize={(event) => {
            event.stopPropagation()
          }}
          onResizeStart={(event) => {
            event.stopPropagation()
          }}
          maxConstraints={[740, 420]}
          onResizeStop={(_event, data) => {
            updateImageSizeHandler(data.size)
            setTimeout(() => {
              selectElement({
                documentPageId,
                id: imageTemplate?.id as string,
                isRemovable: true,
              })
            })
          }}
          resizeHandles={!isReadOnly && isSelected ? ['se'] : []}
        >
          <div
            ref={setNodeRef}
            className={cn(
              'flex flex-1 flex-col justify-center items-center h-full w-full',
              {
                'pointer-events-none': isAddingAnnotation || isDragging,
                'pointer-events-auto': !isAddingAnnotation && !isDragging,
              },
            )}
          >
            <div
              className={cn('h-full', {
                'h-full': imagePosition === 'relative',
              })}
            >
              <ErrorBoundary fallback={<div>Failed to load image</div>}>
                <a
                  href=""
                  download={userFacingDownloadUrl}
                  className={cn(
                    'h-full cursor-move flex justify-center flex-col items-center',
                    {
                      'cursor-move': !isReadOnly,
                      'cursor-default': isReadOnly,
                      'pointer-events-none': isDragging,
                    },
                  )}
                >
                  <Image
                    src={downloadUrl}
                    className={cn('max-h-full h-auto w-auto', {
                      'cursor-move': !isReadOnly,
                      'cursor-default': isReadOnly,
                    })}
                  />
                </a>
              </ErrorBoundary>
            </div>
            {timestamp && (
              <div className="text-xs mb-2 font-medium text-gray-500">
                {dayjs(timestamp).format('MMM DD, YYYY hh:mm A')}
              </div>
            )}
          </div>
        </ResizableBox>
      </div>
    </>
  )
}

export default DraggableImage
