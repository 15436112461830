import { useState, useEffect } from 'react'

export const useAssemblyPanelSize = () => {
  const [panelSize, setPanelSize] = useState(27.5)

  useEffect(() => {
    const calculateFixedPanelSizes = () => {
      const windowWidth = window.innerWidth

      const fixedWidthPx = 250
      const fixedPercentage = (fixedWidthPx / windowWidth) * 100

      setPanelSize(fixedPercentage)
    }

    calculateFixedPanelSizes()

    window.addEventListener('resize', calculateFixedPanelSizes)

    return () => {
      window.removeEventListener('resize', calculateFixedPanelSizes)
    }
  }, [])

  return panelSize
}
