import { ReactNode } from 'react'
import { Editor as TiptapEditor } from '@tiptap/react'

import { cn } from '@/utils'
import {
  CheckboxIcon,
  ListBulletIcon,
  FontBoldIcon,
  FontItalicIcon,
  UnderlineIcon,
  TextAlignLeftIcon,
  TextAlignCenterIcon,
  TextAlignRightIcon,
} from '@radix-ui/react-icons'
import { OrderedListIcon } from '@/components/icons/OrderedListIcon'
import { HighlightIcon } from '@/components/icons/HighlightIcon'

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/components/ui/menubar'
import { ExtensionsEnum, HIGHLIGHT_COLORS } from './constants'
import { IconsMenu } from './IconsMenu'
import { ScrewdriverIcon } from '../icons/Screwdriver'

const Trigger = ({
  children,
  onClick,
  isActive,
}: {
  children: ReactNode
  onClick?: () => void
  isActive?: boolean
}) => {
  return (
    <MenubarTrigger
      className={cn('bg-white rounded-full cursor-pointer hover:bg-white', {
        'bg-primary-45 text-white data-[state=open]:bg-primary-45 data-[state=open]:text-white':
          isActive,
      })}
      style={{ width: '30px', padding: '5px' }}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      {children}
    </MenubarTrigger>
  )
}

export const EditorMenuBar = ({
  editor,
  extensionsList,
}: {
  editor: TiptapEditor
  extensionsList: Array<ExtensionsEnum>
}) => {
  return (
    <Menubar className="bg-primary-20 border-primary-30 px-4 h-10">
      <MenubarMenu>
        <Trigger
          isActive={editor.isActive('bold')}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <FontBoldIcon className="w-5 h-5" />
        </Trigger>
      </MenubarMenu>
      <MenubarMenu>
        <Trigger
          isActive={editor.isActive('italic')}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <FontItalicIcon className="w-5 h-5" />
        </Trigger>
      </MenubarMenu>
      {extensionsList.includes(ExtensionsEnum.UNDERLINE) && (
        <MenubarMenu>
          <Trigger
            isActive={editor.isActive('underline')}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          >
            <UnderlineIcon className="w-5 h-5" />
          </Trigger>
        </MenubarMenu>
      )}
      {extensionsList.includes(ExtensionsEnum.TASK_LIST) && (
        <MenubarMenu>
          <Trigger>
            <ListBulletIcon className="w-5 h-5" />
          </Trigger>
          <MenubarContent className="min-w-[2rem]">
            <MenubarItem
              className={cn({
                'bg-primary-45 text-white': editor.isActive('bulletList'),
              })}
              onClick={() => {
                editor.chain().focus().toggleBulletList().run()
              }}
            >
              <ListBulletIcon className="w-5 h-5" />
            </MenubarItem>
            <MenubarItem
              className={cn({
                'bg-primary-45 text-white': editor.isActive('orderedList'),
              })}
              onClick={() => {
                editor.chain().focus().toggleOrderedList().run()
              }}
            >
              <OrderedListIcon className="w-4 h-4" />
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      )}
      {extensionsList.includes(ExtensionsEnum.TEXT_ALIGN) && (
        <MenubarMenu>
          <Trigger>
            <TextAlignLeftIcon className="w-5 h-5" />
            <MenubarContent className="min-w-[2rem]">
              <MenubarItem
                className={cn({
                  'bg-primary-45 text-white': editor.isActive({
                    textAlign: 'left',
                  }),
                })}
                onClick={() =>
                  editor.chain().focus().setTextAlign('left').run()
                }
              >
                <TextAlignLeftIcon className="w-5 h-5" />
              </MenubarItem>
              <MenubarItem
                className={cn({
                  'bg-primary-45 text-white': editor.isActive({
                    textAlign: 'center',
                  }),
                })}
                onClick={() =>
                  editor.chain().focus().setTextAlign('center').run()
                }
              >
                <TextAlignCenterIcon className="w-5 h-5" />
              </MenubarItem>
              <MenubarItem
                className={cn({
                  'bg-primary-45 text-white': editor.isActive({
                    textAlign: 'right',
                  }),
                })}
                onClick={() =>
                  editor.chain().focus().setTextAlign('right').run()
                }
              >
                <TextAlignRightIcon className="w-5 h-5" />
              </MenubarItem>
            </MenubarContent>
          </Trigger>
        </MenubarMenu>
      )}
      {extensionsList.includes(ExtensionsEnum.TASK_LIST) && (
        <MenubarMenu>
          <Trigger
            isActive={editor.isActive('taskList')}
            onClick={() => editor.chain().focus().toggleTaskList().run()}
          >
            <CheckboxIcon className="w-5 h-5" />
          </Trigger>
        </MenubarMenu>
      )}
      {extensionsList.includes(ExtensionsEnum.HIGHLIGHT) && (
        <MenubarMenu>
          <Trigger>
            <HighlightIcon
              className="w-5 h-5"
              selectedColor={editor.getAttributes('highlight')?.color}
            />
            <MenubarContent className="min-w-[2rem]">
              {HIGHLIGHT_COLORS.map((color) => (
                <MenubarItem
                  key={color}
                  className={cn({
                    'bg-primary-45 text-white':
                      editor.getAttributes('highlight')?.color === color,
                  })}
                  onClick={() =>
                    editor.chain().focus().toggleHighlight({ color }).run()
                  }
                >
                  <span
                    className="w-5 h-5 rounded-full"
                    style={{
                      backgroundColor: color,
                    }}
                  />
                </MenubarItem>
              ))}
            </MenubarContent>
          </Trigger>
        </MenubarMenu>
      )}
      {extensionsList.includes(ExtensionsEnum.ICONS) && (
        <MenubarMenu>
          <Trigger>
            <ScrewdriverIcon className="w-5 h-5" />
          </Trigger>
          <IconsMenu editor={editor} />
        </MenubarMenu>
      )}
    </Menubar>
  )
}
