import { useQuery } from '@tanstack/react-query'
import type { CommandItem } from '../commands/types'
import {
  useFullPartsBOM,
  useFullToolsBOM,
} from '@/pages/DocumentPage/components/OperationDocument/hooks/useBOM'
import commands from '../commands'

export const useCommandItems = ({
  commandName,
  documentPageId,
}: {
  commandName: string
  documentPageId: string
}) => {
  const bom = useFullPartsBOM()
  const toolsBom = useFullToolsBOM()

  return useQuery({
    staleTime: 0,
    queryKey: ['editorCommands', commandName, documentPageId],
    queryFn: async (): Promise<CommandItem[]> => {
      const command = commands.find((command) => command.name === commandName)
      if (!command) {
        return []
      }
      return command.getItems({
        documentPageId,
        bom,
        toolsBom,
      })
    },
  })
}
