import logoUrl from '@/assets/q20-logo3.png'
import { LoginForm } from '@/pages/LoginPage/components/LoginForm'

export const LoginPage = () => {
  return (
    <div>
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto w-32" src={logoUrl} alt="Quarter20" />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <LoginForm />
        </div>
        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?
          <a
            href="https://www.buildquarter20.com/home/#beta"
            className="font-semibold leading-6 text-primary-50 ml-1"
          >
            Sign up today
          </a>
        </p>
      </div>
    </div>
  )
}
