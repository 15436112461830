import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CSRF_TOKEN_NAME } from '@/constants'

export const acceptTermsOfService = async () => {
  await getCsrf()
  const response = await client.POST(
    '/api/v1/accounts/terms_of_service_agreement',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
    },
  )

  if (response.error || !response.response.ok) {
    throw new Error('Failed to accept terms of service')
  }

  return response.data
}

export const getTermsOfService = async () => {
  await getCsrf()
  const response = await client.GET(
    '/api/v1/accounts/terms_of_service_agreement',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
    },
  )

  if (response.error || !response.response.ok) {
    throw new Error('Failed to get terms of service')
  }

  return response.data
}
