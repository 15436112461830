import type { DocumentPage } from '@/lib/api/client'
import type { Step } from './types'

export const convertDocumentToStep = (page: DocumentPage): Step => {
  const assembly_group_ids = (page.documentpageassemblygroupids || []).map(
    (id) => id.assembly_group_id,
  )
  return {
    ...page,
    assembly_group_ids,
  }
}
