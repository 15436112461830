import QUERY_KEYS from '../queryKeys'
import { listCads } from '@/lib/api/client'
import { useQuery } from '@tanstack/react-query'

export const useListCads = ({ projectId }: { projectId?: string | null }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CADS, { projectId }],
    queryFn: async () => {
      if (!projectId) return
      return listCads(projectId)
    },
    enabled: Boolean(projectId),
  })
}
