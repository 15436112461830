export const DocumentTextIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.14286 10.3333H8.85714M4.14286 8.33333H8.85714M7.28601 1.00058C7.21095 1 7.12651 1 7.03013 1H3.51444C2.63436 1 2.19399 1 1.85784 1.14533C1.56216 1.27316 1.32193 1.47698 1.17128 1.72786C1 2.01308 1 2.38673 1 3.13346V10.8668C1 11.6135 1 11.9867 1.17128 12.2719C1.32193 12.5228 1.56216 12.727 1.85784 12.8548C2.19366 13 2.6335 13 3.51188 13L9.48814 13C10.3665 13 10.8057 13 11.1415 12.8548C11.4372 12.727 11.6782 12.5228 11.8289 12.2719C12 11.987 12 11.6143 12 10.8691V5.21712C12 5.13535 11.9999 5.06369 11.9992 5M7.28601 1.00058C7.5103 1.00232 7.65164 1.00938 7.78714 1.03698C7.94748 1.06964 8.10117 1.12351 8.24177 1.19661C8.4003 1.27904 8.53642 1.39454 8.80804 1.625L11.2638 3.70865C11.5356 3.93926 11.6707 4.05424 11.7679 4.1888C11.854 4.30809 11.9178 4.43817 11.9563 4.57422C11.9888 4.68919 11.9972 4.8097 11.9992 5M7.28601 1.00058L7.28571 2.86681C7.28571 3.61354 7.28571 3.98677 7.45699 4.27198C7.60765 4.52287 7.84787 4.72699 8.14355 4.85482C8.47937 5 8.91922 5 9.79758 5H11.9992"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
