import { Plugin } from '@tiptap/pm/state'
import Highlight from '@tiptap/extension-highlight'

export const Marker = Highlight.extend({
  name: 'slash-command-pill',
  inclusive: false,

  addAttributes() {
    if (!this.options.multicolor) {
      return {}
    }

    return {
      href: {
        default: null,
      },
      color: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {}
          }

          return {
            'data-color': attributes.color,
            style: `background-color: ${attributes.color}; color: inherit`,
          }
        },
      },
    }
  },
  // @ts-expect-error - tippy types are wrong, getAttrs can return a boolean
  // Docs: https://tiptap.dev/docs/editor/extensions/custom-extensions/extend-existing#using-getattrs
  parseHTML() {
    return [
      {
        tag: 'mark',
        getAttrs: (element) => element.classList.contains('slash-command-pill'),
      },
    ]
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleClick(view, pos) {
            const node = view.state.doc.nodeAt(pos)

            if (node) {
              const highlightMark = node.marks.find(
                (mark) => mark.type.name === 'slash-command-pill',
              )

              // If the tiptap node that was clicked is a "highlight" mark,
              // then we can assume the mark is either a part or a tool.
              if (highlightMark) {
                const attrs = highlightMark.attrs

                if (attrs.href) {
                  const targetUrl = new URL(attrs.href)

                  // If the current URL is not the same as the target URL,
                  // than we should navigate to the target URL.
                  if (window.location.pathname !== targetUrl.pathname) {
                    window.location.href = attrs.href

                    // Otherwise, we should scroll to the target element and
                    // flash the element to indicate that it was clicked.
                  } else {
                    const el = document.getElementById(targetUrl.hash.slice(1))
                    if (el) {
                      el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest',
                      })

                      // Flashing the element with green background for x seconds
                      el.style.transition = 'background-color 0.5s ease-in-out'
                      el.style.backgroundColor = attrs.color ?? 'yellow'
                      setTimeout(() => {
                        el.style.backgroundColor = ''
                        el.style.border = ''
                      }, 2000)
                    }
                  }
                }
                return true
              }
            }

            return false
          },
        },

        appendTransaction(transactions, oldState, newState) {
          let tr = newState.tr
          let markDeleted = false

          transactions.forEach((transaction) => {
            if (transaction.steps.length > 0) {
              newState.doc.descendants((node, pos) => {
                if (node.isText) {
                  const highlightMark = node.marks.find(
                    (mark) => mark.type.name === 'slash-command-pill',
                  )

                  if (highlightMark) {
                    const oldNode = oldState.doc.nodeAt(pos)

                    if (oldNode && oldNode.isText) {
                      const oldContent = oldNode.textContent.includes(':')
                        ? oldNode.textContent.trim().split(':')[1] || ''
                        : oldNode.textContent
                      const oldTextLength = oldContent.length
                      const newTextLength = node.textContent.length

                      // Check if the text length has decreased
                      if (newTextLength < oldTextLength) {
                        const from = pos
                        const to = pos + node.nodeSize
                        tr = tr.removeMark(from, to, highlightMark.type)
                        tr = tr.delete(from, to)
                        markDeleted = true
                      }
                    }
                  }
                }
              })
            }
          })

          return markDeleted ? tr : null
        },
      }),
    ]
  },
})
