import createClient from 'openapi-fetch'
import type { paths } from '@/lib/api/types'
import { BASE_URL } from '@/constants'

/**
 * The `createClient` function is a factory function that creates a new API client
 */
export const client = createClient<paths>({
  baseUrl: BASE_URL,
  credentials: 'include',
})
