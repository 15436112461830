import { useMemo } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { BillOfMaterials, ToolsBillOfMaterials } from '@/state'
import { DocumentTemplate } from './DocumentTemplate'
import { toChunks } from '@/utils'
import {
  useFullPartsBOM,
  useFullToolsBOM,
} from './OperationDocument/hooks/useBOM'

export const MAX_PARTS_PER_PAGE = 12
export const BillOfMaterialsDocument = ({
  versionName,
  createdAt,
  isLatestDocumentVersion,
}: {
  versionName: string
  createdAt: string
  isLatestDocumentVersion?: boolean
}) => {
  const partsBom = useFullPartsBOM()
  const toolsBom = useFullToolsBOM()

  const partsBomChunks: Array<BillOfMaterials> = useMemo(
    () =>
      toChunks({
        array: partsBom,
        chunkSize: MAX_PARTS_PER_PAGE,
      }),
    [partsBom],
  )

  const toolsBomChunks: Array<ToolsBillOfMaterials> = useMemo(
    () =>
      toChunks({
        array: toolsBom,
        chunkSize: MAX_PARTS_PER_PAGE,
      }),
    [toolsBom],
  )

  return (
    <>
      {partsBomChunks.map((chunk, i) => (
        <DocumentTemplate
          id={`parts-bom--${i}`}
          title="Bill of Materials"
          createdAt={createdAt}
          versionName={versionName}
          isLatestDocumentVersion={isLatestDocumentVersion}
          key={i}
          order={3 + i}
        >
          <div id="bom">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Part name</TableHead>
                  <TableHead>Quantity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {chunk.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell className="py-1 px-2.5">
                      {item.partName}
                    </TableCell>
                    <TableCell className="py-1 px-2.5">
                      {item.quantity ? item.quantity : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DocumentTemplate>
      ))}
      {toolsBomChunks.map((chunk, i) => (
        <DocumentTemplate
          id={`tools-bom--${i}`}
          title="Tools Bill of Materials"
          createdAt={createdAt}
          versionName={versionName}
          isLatestDocumentVersion={isLatestDocumentVersion}
          key={i}
          order={4 + partsBomChunks.length + i}
        >
          <div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Tool name</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {chunk.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell className="py-1 px-2.5">
                      {item.toolName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DocumentTemplate>
      ))}
    </>
  )
}
