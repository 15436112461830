import { Button } from '@/components/ui/button'
import {
  CollapsibleTrigger,
  CollapsibleTriangleIcon,
} from '@/components/ui/collapsible'
import { ErrorBoundary } from 'react-error-boundary'

export const CollapsibleTreeNodeButton = ({
  enabled,
}: {
  enabled?: boolean
}) => {
  return enabled ? (
    <ErrorBoundary FallbackComponent={CollapsibleTriggerPlaceHolder}>
      <CollapsibleTrigger className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 !py-0 !px-2 h-6 cursor-pointer">
        <Button variant="ghost" className="px-2">
          <CollapsibleTriangleIcon className="w-5 h-5" />
        </Button>
      </CollapsibleTrigger>
    </ErrorBoundary>
  ) : (
    <CollapsibleTriggerPlaceHolder />
  )
}

const CollapsibleTriggerPlaceHolder = () => {
  return (
    <div
      style={{
        width: 24,
        height: 24,
      }}
    ></div>
  )
}
