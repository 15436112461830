import { cn } from '@/utils'

export const SaveIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M13.4444 17L4.55556 16.9998M13.4444 17L14.1583 16.9998C15.152 16.9998 15.6489 16.9998 16.0288 16.8063C16.3633 16.6358 16.636 16.3637 16.8064 16.0292C17 15.6493 17 15.1519 17 14.1582V6.52843C17 6.12948 17 5.92902 16.9575 5.73866C16.9195 5.56884 16.8571 5.40575 16.7717 5.25413C16.677 5.08595 16.544 4.93822 16.2826 4.64777L13.8335 1.92658C13.5306 1.58998 13.377 1.41938 13.1927 1.29687C13.0269 1.18667 12.8438 1.10541 12.6509 1.05588C12.4333 1 12.1998 1 11.7334 1H3.84462C2.84897 1 2.35077 1 1.97049 1.19376C1.63598 1.3642 1.36421 1.63597 1.19377 1.97048C1 2.35076 1 2.84895 1 3.84459V14.1556C1 15.1512 1 15.6484 1.19377 16.0286C1.36421 16.3631 1.63598 16.6358 1.97049 16.8063C2.3504 16.9998 2.84799 16.9998 3.8417 16.9998H4.55556M13.4444 17V13.6193C13.4444 12.6256 13.4444 12.1281 13.2509 11.7481C13.0804 11.4136 12.8077 11.1419 12.4732 10.9714C12.093 10.7777 11.5958 10.7777 10.6002 10.7777H7.40017C6.40452 10.7777 5.90633 10.7777 5.52604 10.9714C5.19153 11.1419 4.91976 11.4136 4.74932 11.7481C4.55556 12.1284 4.55556 12.6266 4.55556 13.6223V16.9998M11.6667 4.55552H6.33333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
