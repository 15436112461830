import { useState } from 'react'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'

import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { CreatorsFilters } from './CreatorsFilters'

export const ProjectsFilters = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  return (
    <Sheet open={isPanelOpen} onOpenChange={setIsPanelOpen}>
      <SheetTrigger asChild>
        <Button id="filters-button" className="rounded-full" variant="ghost">
          <div className="relative flex items-center gap-2">
            <AdjustmentsHorizontalIcon className="h-5 w-5" />
            <span>Filter</span>
          </div>
        </Button>
      </SheetTrigger>
      <SheetContent className="max-w-lg">
        <SheetHeader>
          <SheetTitle>Filter by:</SheetTitle>
        </SheetHeader>
        <div>
          <div className="flex justify-start my-6">
            <CreatorsFilters />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
