import type { Image } from '@/lib/api/client'
import { Screenshot } from '@/pages/CADPage/components/ScreenshotPanelButton/Screenshot'
import { Skeleton } from '@/components/ui/skeleton'
import { cn } from '@/utils'

export type ImagesListProps = {
  images: Image[]
  isLoading: boolean
  isReadOnly: boolean
  isDisabled?: boolean
  onClick: (image: Image) => void
}
export const ImagesList = ({
  images,
  isLoading,
  isReadOnly,
  isDisabled,
  onClick,
}: ImagesListProps) => {
  if (isLoading) {
    return (
      <div className="grid grid-cols-3 auto-rows-max gap-4 overflow-y-auto h-full w-full mt-4 pb-16">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )
  }

  if (images.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center space-y-2 mt-10">
        <h1 className="text-md font-semibold text-center">No images found.</h1>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'grid grid-cols-3 auto-rows-max gap-4 overflow-y-auto h-full w-full mt-4 pb-16',
        {
          'pointer-events-none': isDisabled || isLoading,
        },
      )}
    >
      {images.map((view) => (
        <Screenshot
          isLoading={isDisabled}
          key={view.id}
          view={view}
          isReadOnly={isReadOnly}
          onClick={() => {
            onClick(view)
          }}
        />
      ))}
    </div>
  )
}
