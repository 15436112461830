import { ImageIcon } from '@radix-ui/react-icons'
import { useShallow } from 'zustand/react/shallow'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'

import { useScreenshots } from '../ScreenshotPanelButton/hooks/useScreenshots'
import { useCadPageParams } from '../../hooks'
import { useCADQuery } from '@/services/queries/cads'
import { useCADPageStore } from '../../state'

interface StepCardScreenshotsButtonProps {
  documentPageId: string
}

export const StepCardScreenshotsButton = ({
  documentPageId,
}: StepCardScreenshotsButtonProps) => {
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const { isLoading: isCadLoading, data: cadData } = useCADQuery()

  const { documentId, dv: selectedDocumentVersionId } = useCadPageParams()

  const cadVersionId = cadData?.version?.id

  const { isLoading: isLoadingScreenshots, data: views } = useScreenshots({
    documentId,
    cadVersionId: cadVersionId,
    documentVersionId: selectedDocumentVersionId,
  })

  const isLoading = isCadLoading || isLoadingScreenshots

  const screenshots = (views || []).filter(
    (view) => view.document_page === documentPageId,
  )

  if (isLoading || screenshots.length === 0) {
    return null
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            size="sm"
            variant="ghost"
            className="px-1 cursor-pointer flex flex-col relative text-gray-400"
            onMouseDown={(e) => {
              e.stopPropagation()
              setCadPageState({
                filterScreenshotsByDocumentPageId: documentPageId,
              })
              const screenshotButton =
                document.getElementById('screenshot-button')
              screenshotButton?.click()
            }}
          >
            <ImageIcon className="w-4 h-4" />
            <span
              className="bg-gray-200 rounded-full px-1 absolute text-gray-700"
              style={{ fontSize: 8, top: -5, right: -3 }}
            >
              {screenshots.length}
            </span>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>{'Open Step Screenshots'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
