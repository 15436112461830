import { useEffect } from 'react'

import { GLTFObject } from '@/lib/cad/GLTFObject'

export const useMergeMeshes = (gltf?: GLTFObject) => {
  useEffect(() => {
    if (gltf) {
      const nMeshesMerged = gltf.mergeModelMeshes()
      console.log('meshes merged: ', nMeshesMerged)
    }
  }, [gltf])
}
