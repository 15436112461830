import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { DocumentInput, DocumentTypeChoices } from './types'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * Create a new document
 * @param versionId
 * @param values
 * @returns
 */
export const createDocument = async (
  projectId: string,
  values: {
    name: string
    cad_id: string
    document_type: DocumentTypeChoices
  },
) => {
  await getCsrf()
  const resp = await client.POST('/api/v1/projects/{project_id}/documents', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        project_id: projectId,
      },
    },
    body: values,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create document')
  }

  return resp.data
}

/**
 * Get a document
 * @param documentId
 * @returns
 */
export const getDocument = async (documentId: string) => {
  const resp = await client.GET('/api/v1/projects/documents/{document_id}', {
    params: {
      path: {
        document_id: documentId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get document')
  }

  return resp.data
}

/**
 * List all documents in a project version
 * @param projectId
 * @returns
 */
export const listDocuments = async (projectId: string) => {
  const resp = await client.GET('/api/v1/projects/{project_id}/documents', {
    params: {
      path: {
        project_id: projectId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get documents')
  }

  return resp.data
}

/**
 * Update a document
 * @param documentId
 * @param values
 * @returns
 */
export const updateDocument = async (
  documentId: string,
  values: DocumentInput,
) => {
  await getCsrf()
  const resp = await client.PUT('/api/v1/projects/documents/{document_id}', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        document_id: documentId,
      },
    },
    body: values,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to update document')
  }

  return resp.data
}

/**
 * Delete a document
 * @param documentId
 * @returns
 */
export const deleteDocument = async (documentId: string) => {
  await getCsrf()
  const resp = await client.DELETE('/api/v1/projects/documents/{document_id}', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        document_id: documentId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to delete document')
  }

  return resp.data
}
