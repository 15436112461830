import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react'
import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'

import { STROKE_COLOR_OPTIONS } from '@/pages/DocumentPage/components/Annotations/constants'
import { cn } from '@/utils'
import { EmojiItem } from '@tiptap-pro/extension-emoji'

const EmojiList = forwardRef(({ items, command }: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = useCallback(
    (index) => {
      const item = items[index]

      if (item) {
        const { emoji, fallbackImage, color, name } = item
        command({ icon: emoji || fallbackImage, color, name })
      }
    },
    [items, command],
  )

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length)
  }, [selectedIndex, items.length])

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % items.length)
  }, [selectedIndex, items.length])

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex)
  }, [selectItem, selectedIndex])

  useEffect(() => setSelectedIndex(0), [items])

  useImperativeHandle(ref, () => {
    return {
      onKeyDown: (x) => {
        if (x.event.key === 'ArrowUp') {
          upHandler()
          return true
        }

        if (x.event.key === 'ArrowDown') {
          downHandler()
          return true
        }

        if (x.event.key === 'Enter') {
          enterHandler()
          return true
        }

        return false
      },
    }
  }, [upHandler, downHandler, enterHandler])

  return (
    <div className="dropdown-menu bg-slate-50 shadow-md rounded-xl flex flex-col gap-0.5 overflow-auto p-2 relative min-w-[200px]">
      {items.length ? (
        items.map((item, index) => (
          <button
            className={cn(
              'px-2 py-1 rounded-md flex items-center gap-1 hover:bg-slate-300 text-left width-full',
              {
                'bg-slate-200': index === selectedIndex,
                'bg-transparent': index !== selectedIndex,
              },
            )}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.fallbackImage ? (
              <img className="w-4 h-4" src={item.fallbackImage} />
            ) : (
              <span
                className="text-xl"
                style={{
                  color: item.color,
                }}
              >
                {item.emoji}
              </span>
            )}
            :{item.name}:
          </button>
        ))
      ) : (
        <span className="text-sm px-2">No icons found...</span>
      )}
    </div>
  )
})

EmojiList.displayName = 'EmojiList'

export default {
  items: ({ editor, query }) => {
    if (!query) {
      return editor.storage.emoji.emojis
        .reduce((accum: Array<EmojiItem>, item: EmojiItem) => {
          const exists = accum.find((i) =>
            i.shortcodes.includes(item.shortcodes[0]),
          )
          if (!exists) {
            return [...accum, item]
          }
          return accum
        }, [] as Array<EmojiItem>)
        .slice(0, 6)
    }

    return editor.storage.emoji.emojis
      .filter(({ shortcodes, tags }) => {
        return (
          shortcodes.find((shortcode) =>
            shortcode.includes(query.toLowerCase()),
          ) || tags.find((tag) => tag.includes(query.toLowerCase()))
        )
      })
      .slice(0, STROKE_COLOR_OPTIONS.length)
  },

  allowSpaces: false,

  render: () => {
    let component
    let popup

    return {
      onStart: (props) => {
        props.editor.storage.isSelecting = true
        component = new ReactRenderer(EmojiList, {
          props,
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()
          component.destroy()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit(props) {
        props.editor.storage.isSelecting = false
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}
