import { useEffect } from 'react'
import { Mesh } from 'three'

import { GLTFObject } from '@/lib/cad/GLTFObject'
import { useCADPageStore } from '@/pages/CADPage/state'

export const useWireframe = (gltf?: GLTFObject) => {
  const explosions = useCADPageStore((state) => state.explosions)

  useEffect(() => {
    gltf?.explodeWireframes(explosions)
  }, [explosions, gltf])

  useEffect(() => {
    if (gltf) {
      gltf.scene.traverse((object) => {
        if (object instanceof Mesh) {
          object.geometry.computeBoundsTree()
        }
      })

      gltf.buildWireframe()
    }
    return () => {
      if (gltf) {
        gltf.scene.traverse((object) => {
          if (object instanceof Mesh) {
            object.geometry.disposeBoundsTree()
          }
        })
      }
    }
  }, [gltf])
}
