import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { RawAssemblyTree } from '@/state'
import { CSRF_TOKEN_NAME } from '@/constants'

/**
 * Mark a CAD version as uploaded
 * @param cadVersionId
 * @returns
 */
export const cadVersionUploaded = async (cadVersionId: string) => {
  await getCsrf()
  return client.PUT('/api/v1/projects/cadversions/{cad_version_id}/uploaded', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        cad_version_id: cadVersionId,
      },
    },
  })
}

/**
 * Get a CAD version
 * @param cadId
 * @returns
 */
export const listCadVersions = async (cadId: string) => {
  const resp = await client.GET('/api/v1/projects/{cad_id}/cadversions', {
    params: {
      path: {
        cad_id: cadId,
      },
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get CAD versions')
  }

  return resp.data.sort(
    (a, b) => (b.version_number || 0) - (a.version_number || 0),
  )
}

/**
 * Get a CAD version
 * @param cadVersionId
 * @returns
 */
export const getCadVersion = async (cadVersionId: string) => {
  const resp = await client.GET(
    '/api/v1/projects/cadversions/{cad_version_id}',
    {
      params: {
        path: {
          cad_version_id: cadVersionId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get CAD version')
  }

  return resp.data
}

/**
 * Create a new CAD version
 * @param cadId
 * @param values
 * @returns
 */
export const createCadVersion = async (
  cadId: string,
  values: { filename: string; comment: string },
) => {
  await getCsrf()
  const resp = await client.POST('/api/v1/projects/{cad_id}/cadversions', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    params: {
      path: {
        cad_id: cadId,
      },
    },
    body: values,
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create CAD version')
  }

  return resp.data
}

export const updateCadVersion = async (
  cadVersionId: string,
  values: { assembly_tree?: RawAssemblyTree | null; filename?: string },
) => {
  await getCsrf()
  const resp = await client.PUT(
    '/api/v1/projects/cadversions/{cad_version_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          cad_version_id: cadVersionId,
        },
      },
      // @ts-expect-error - TODO: fix this
      body: values,
    },
  )

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to update CAD version')
  }

  return resp.data
}
