import { useQuery } from '@tanstack/react-query'
import { getCad } from '@/lib/api/client'

export const useCad = ({ cadId }: { cadId?: string | null }) => {
  return useQuery({
    queryKey: ['cad', cadId],
    queryFn: () => (cadId ? getCad(cadId) : undefined),
    enabled: !!cadId,
  })
}
