import { ReactNode } from 'react'
import { Spinner } from '../ui/spinner'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'

interface CadPageContainerProps {
  children: ReactNode
}

export const CadPageContainer = ({ children }: CadPageContainerProps) => {
  const { isLoading, data: readOnlyData } = useIsReadOnly()
  const isReadOnly = readOnlyData.isReadOnly

  return (
    <div
      className="w-full flex bg-white"
      style={{
        height: isReadOnly ? 'calc(100vh - 115px)' : 'calc(100vh - 65px)',
      }}
    >
      {isLoading ? <Spinner /> : children}
    </div>
  )
}
