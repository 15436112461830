import { useCallback } from 'react'
import { v4 as generateUUID } from 'uuid'
import { useCreateDocumentPage } from '../document_pages'

export const useCreateOperationStep = () => {
  const { isPending, mutate: createDocumentPage } = useCreateDocumentPage()

  const mutate = useCallback(
    ({ documentVersionId }: { documentVersionId: string }) => {
      const id = generateUUID()
      return createDocumentPage({
        documentVersionId,
        values: {
          name: `Step ${id}`,
          assembly_group_id: id,
          template_values: {},
        },
      })
    },
    [createDocumentPage],
  )

  return {
    isPending,
    mutate,
  }
}
