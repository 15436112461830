import { useCallback, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { useAssemblyTree } from '@/state'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useGetInclusiveGroupIds } from '@/services/hooks/steps'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const useCrossSection = () => {
  const inclusiveGroupIds = useGetInclusiveGroupIds()
  const { data } = useDocumentPageQuery()

  const clippingPlanes = useCADPageStore((state) => state.clippingPlanes)
  const sectioning = useCADPageStore((state) => state.sectionState)
  const getCadPageState = useCADPageStore((state) => state.getState)
  const setCadPageState = useCADPageStore((state) => state.setState)
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const assemblyTree = useAssemblyTree(useShallow((state) => state.tree))

  const activeClippingPlane = 0
  const documentType = data?.documentType

  const updateCrossSectionMap = useCallback(() => {
    if (!assemblyTree || !documentType) return

    const parts =
      assemblyTree.nodes
        .filter((node) => node.uuid !== assemblyTree.root)
        .map((node) => node.instance)
        .filter((instance) => instance !== undefined) ?? []

    const ids =
      inclusiveGroupIds
        ?.map((id) => getNode(id)?.instance)
        .filter((instance) => instance !== undefined) ?? []

    const { crossSectionMap, exclusiveCrossSectionMap } = getCadPageState()

    // Inclusive parts list
    const includedParts =
      documentType === 'work_instructions' && ids.length ? ids : parts
    crossSectionMap[activeClippingPlane] = includedParts

    // Exclusive parts list
    const excludedParts = parts.filter((part) => !includedParts.includes(part))
    exclusiveCrossSectionMap[activeClippingPlane] = excludedParts

    setCadPageState({ crossSectionMap, exclusiveCrossSectionMap })
  }, [assemblyTree, documentType, inclusiveGroupIds])

  useEffect(() => {
    updateCrossSectionMap()
  }, [clippingPlanes, sectioning, inclusiveGroupIds])

  return updateCrossSectionMap
}
