import pako from 'pako'

export const compressFile = async (file: File) => {
  const buf = await file.arrayBuffer()
  const packedBuf = pako.gzip(buf, {
    level: 1,
  })
  return new File([packedBuf], `${file.name}.gz`, {
    type: 'application/gzip',
  })
}
