import { DocumentTemplate } from './DocumentTemplate'

export const TitleDocument = ({
  documentName,
  versionName,
  createdAt,
  isLatestDocumentVersion,
  parentRef,
}: {
  documentName: string
  versionName: string
  createdAt: string
  isLatestDocumentVersion?: boolean
  parentRef: any
}) => {
  return (
    <div ref={parentRef}>
      <DocumentTemplate
        id="cover"
        createdAt={createdAt}
        versionName={versionName}
        order={1}
        isLatestDocumentVersion={isLatestDocumentVersion}
      >
        <div className="mt-20 flex flex-col space-y-2">
          <h1 className="text-3xl font-bold">{documentName}</h1>
          <h2 className="text-lg">Assembly instructions</h2>
        </div>
      </DocumentTemplate>
    </div>
  )
}
