import { cn } from '@/utils'
export const SectionRotateIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-gray-800', className)}
    >
      <path
        d="M15.5001 18.3007C15.5001 18.3007 15.3288 19.2248 14.7187 20.0658C14.1186 20.893 13.5437 21.0983 13.0548 20.9605C12.5211 20.8101 11.8941 20.2017 11.3321 18.9485C10.7783 17.7133 10.3723 16.0299 10.1963 14.1187C10.0208 12.2124 10.0869 10.2176 10.3825 8.41883C10.6796 6.61053 11.1842 5.14796 11.7856 4.195C12.3862 3.24349 12.9798 2.94518 13.4696 3.00802C13.9819 3.07377 14.601 3.55912 15.1832 4.7062"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.9"
        d="M13.6608 14.5699C15.966 14.4639 18.0487 14.0838 19.5596 13.522C20.3157 13.2409 20.864 12.9381 21.222 12.6504C21.5871 12.357 21.6777 12.1438 21.6952 12.0497C21.706 11.9921 21.7133 11.8696 21.5283 11.6448C21.3288 11.4022 20.949 11.1045 20.3233 10.8011C19.0749 10.1959 17.178 9.72673 14.9305 9.51774C12.6957 9.30994 10.3232 9.37888 8.22903 9.70941C6.10739 10.0443 4.51517 10.6093 3.6417 11.2263C3.2083 11.5324 3.05902 11.7755 3.01703 11.9031C2.99118 11.9817 2.97893 12.0789 3.10168 12.2616C3.24856 12.4801 3.56393 12.7677 4.12887 13.0721C4.68212 13.3702 5.41398 13.6517 6.31157 13.891"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
