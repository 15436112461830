import { ReactNode } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { cn } from '@/utils'
import type { Step } from '@/services/queries/operation_steps/types'
import { DRAGGABLE_TYPE as DRAGGABLE_ASSEMBLY_TREE_NODE_TYPE } from '../AssemblyTree/constants'

export const StepCardDropZone = ({
  children,
  className,
  step,
  stepNumber,
}: {
  children: ReactNode
  className?: string
  step: Step
  stepNumber: number
}) => {
  const droppable = useDroppable({
    id: `step-card-drop-zone-${stepNumber}`,
    data: { type: 'step-card-drop-zone', step },
  })

  const dragType = droppable.active?.data?.current?.type as string | undefined

  const isDroppable = dragType === DRAGGABLE_ASSEMBLY_TREE_NODE_TYPE

  return (
    <div
      className={cn(className, 'border border-transparent rounded-lg', {
        'bg-teal-50': isDroppable && droppable.isOver,
        'border-teal-600': isDroppable && droppable.isOver,
      })}
      ref={droppable.setNodeRef}
    >
      {children}
    </div>
  )
}
