import { useMutation } from '@tanstack/react-query'
import { createDocumentPageAssemblyGroupID } from '@/lib/api/client'
import queryClient from '@/queryClient'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useCADQuery } from '../cads'

import type { DocumentPage } from '@/lib/api/client'

import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages/queryKeys'
import { useUpdateStepPartsFromCAD } from '@/pages/CADPage/components/AssemblyOrderPanel/StepCardList/hooks/useUpdateStepPartsFromCAD'

export const useAddPartToOperation = () => {
  const updatePartsFromCAD = useUpdateStepPartsFromCAD()
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const { data: cadData } = useCADQuery()
  const assemblyTree = cadData.rawAssemblyTree
  const gltf = cadData.gltf

  return useMutation({
    mutationFn: async ({
      documentPageId,
      documentVersionId,
      assemblyGroupId,
    }: {
      documentPageId: string
      documentVersionId: string
      assemblyGroupId: string
    }) => {
      await createDocumentPageAssemblyGroupID(documentPageId, assemblyGroupId)
      return { documentPageId, documentVersionId, assemblyGroupId }
    },

    onSuccess: ({ assemblyGroupId, documentPageId, documentVersionId }) => {
      const isActive = activeOperationStep?.stepId === documentPageId
      if (isActive && assemblyTree && gltf) {
        const node = assemblyTree.nodes.find(
          (node) => node.uuid === assemblyGroupId,
        )
        if (node) {
          if (activeOperationStep.selectFromCad) {
            updatePartsFromCAD()
          } else {
            gltf.setVisibility(node.instance, true)
          }
        }
      }

      queryClient.invalidateQueries({
        queryKey: [
          DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId },
        ],
        refetchType: 'all',
      })
    },

    onMutate: ({ assemblyGroupId, documentPageId, documentVersionId }) => {
      const queryKey = [
        DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
        { documentVersionId },
      ]

      const previousDocumentPages = (queryClient.getQueryData(queryKey) ||
        []) as DocumentPage[]

      queryClient.setQueryData(queryKey, (old: DocumentPage[]) => {
        if (!old) return []

        return old.map((doc) => {
          if (doc.id === documentPageId) {
            return {
              ...doc,
              documentpageassemblygroupids: [
                ...doc.documentpageassemblygroupids,
                {
                  assembly_group_id: assemblyGroupId,
                  document_page: documentPageId,
                },
              ],
            }
          }
          return doc
        })
      })

      return { previousDocumentPages }
    },

    onError: (error, { documentVersionId }, context) => {
      console.error('Failed to add part to operation', error)

      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        context?.previousDocumentPages || [],
      )
    },
  })
}
