import {
  Label,
  TemplateAttribute,
  useUpdateTemplateElement,
} from '@/services/hooks/template_attributes'
import { useMutation } from '@tanstack/react-query'
import { Editor as TipTapEditor } from '@tiptap/react'

export const useEditDocumentPageLabel = ({
  labelTemplate,
  documentPageId,
}: {
  labelTemplate: TemplateAttribute<Label>
  documentPageId: string
}) => {
  const { mutateAsync: updateLabel, isPending: isUpdatingLabel } =
    useUpdateTemplateElement({
      documentPageId,
    })

  return useMutation({
    mutationFn: async ({ editor }: { editor: TipTapEditor }) => {
      const html = editor.getHTML()

      const hasChanged = labelTemplate?.template_values?.content !== html
      const isSlashCommandsActive =
        editor.extensionStorage?.slashCommand?.isActive
      const shouldUpdate =
        hasChanged && !isSlashCommandsActive && !isUpdatingLabel

      if (shouldUpdate) {
        await updateLabel({
          templateAttributeId: labelTemplate?.id as string,
          data_type: 'label',
          payload: {
            content: editor.getHTML(),
          },
        })
      }

      return {}
    },
  })
}
