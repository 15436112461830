import { ReactNode } from 'react'

import { NavbarMenu } from './NavbarMenu'
import { NavHomeButton } from './NavHomeButton'

interface NavbarProps {
  hardReload?: boolean
  children?: ReactNode
  logoWidth?: number
}

export const Navbar = ({ hardReload, children, logoWidth }: NavbarProps) => {
  return (
    <div className="relative w-full h-full bg-primary-40">
      <div className="px-8 py-0 flex items-center justify-between border-b">
        <div className="flex items-center space-x-4 w-full">
          <NavHomeButton hardReload={hardReload} logoWidth={logoWidth} />
          {children}
          <div className="flex items-center space-x-2">
            <NavbarMenu />
          </div>
        </div>
      </div>
    </div>
  )
}
