import * as React from 'react'
import * as SwitchPrimitive from '@radix-ui/react-switch'

import { cn } from '@/utils'

export const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitive.Root> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <SwitchPrimitive.Root
    ref={ref}
    className={cn(
      'h-6 w-11 bg-gray-300 rounded-full relative outline-none shadow-sm shadow-gray-300 items-center focus:shadow-gray-500  data-[state=checked]:bg-gray-300',
      className,
    )}
    {...props}
  />
))
Switch.displayName = SwitchPrimitive.Root.displayName

export const SwitchThumb = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitive.Thumb>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitive.Thumb> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <SwitchPrimitive.Thumb
    ref={ref}
    className={cn(
      'block h-5 w-5 rounded-full bg-white shadow-md transition-transform duration-100 data-[state=unchecked]:translate-x-1 data-[state=checked]:translate-x-5',
      className,
    )}
    {...props}
  />
))
SwitchThumb.displayName = SwitchPrimitive.Thumb.displayName
