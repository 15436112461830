import { Fragment, useMemo } from 'react'

import { DEFAULT_LINE_HEIGHT, DEFAULT_STROKE_COLOR } from './constants'
import { calculateLineValues } from './utils'
import { IconStrokes } from './enums'
import { Point } from './queries'
import { Input } from '@/components/ui/input'
import { cn } from '@/utils'

export type LineProps = {
  startPoint: Point
  endPoint: Point
  strokeColor?: string
  strokeWidth?: number | string
  strokeOpacity?: number
  isArrow?: boolean
  isBubble?: boolean
  bubbleValue?: string
  renderWrapper?: boolean
  withText?: boolean
  textPosition?: string
  text?: string
  updateText?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onTextBlur?: () => void
}

export const Line = ({
  startPoint,
  endPoint,
  strokeColor = DEFAULT_STROKE_COLOR,
  strokeWidth = IconStrokes['default'],
  strokeOpacity = 1,
  isArrow = false,
  isBubble = false,
  bubbleValue = '',
  renderWrapper = false,
  withText,
  textPosition,
  text,
  updateText,
  onTextBlur,
}: LineProps) => {
  const Container = renderWrapper ? 'div' : Fragment
  const { lineSize, inclination } = useMemo(
    () => calculateLineValues({ startPoint, endPoint }),
    [startPoint, endPoint],
  )

  const wrapperProps = {}

  if (renderWrapper) {
    wrapperProps['style'] = {
      top: startPoint.y - DEFAULT_LINE_HEIGHT,
      left: startPoint.x - 4,
      transformOrigin: '2px center',
      transform: `rotate(${inclination}deg)`,
    }
    wrapperProps['className'] = 'absolute p-0.5'
  }

  return (
    <Container {...wrapperProps}>
      {withText && (
        <Input
          placeholder="Add text..."
          className={cn(
            'absolute border-0 shadow-none w-auto w-[100px] px-3 py-2 text-xs h-5 bg-white text-center',
            {
              'inset-x-0': textPosition === 'middle',
              'mx-auto': textPosition === 'middle',
            },
          )}
          value={text}
          onChange={updateText}
          onBlur={onTextBlur}
          maxLength={10}
          style={{
            transform: `translate(${
              textPosition === 'middle' ? '0, -30%' : '-60%, 0'
            }) rotate(${-inclination}deg)`,
          }}
          onMouseDown={(e) => e.stopPropagation()}
        />
      )}
      <svg
        width={lineSize}
        height={DEFAULT_LINE_HEIGHT}
        strokeOpacity={strokeOpacity}
        stroke={strokeColor}
        fill="none"
        className="overflow-visible"
      >
        <line
          strokeWidth={strokeWidth}
          x1={isBubble ? 10 : 0}
          y1={DEFAULT_LINE_HEIGHT / 2}
          x2={lineSize - (isArrow ? 10 : 0)}
          y2={DEFAULT_LINE_HEIGHT / 2}
        />
        {isBubble && (
          <>
            <circle r="10" cx="0" cy="5" strokeWidth={strokeWidth} />
            {bubbleValue && (
              <text
                fill={strokeColor}
                x="-4"
                y="9"
                className="text-xs"
                style={{
                  transformOrigin: '0px',
                  transform: `rotate(${-inclination}deg)`,
                }}
              >
                {bubbleValue}
              </text>
            )}
          </>
        )}
        {isArrow && (
          <path
            d="M 0 0 L 10 5 L 0 10 z"
            strokeWidth={strokeWidth}
            fill={strokeColor}
            transform={`translate(${lineSize - 10}, 0)`}
            style={{
              opacity: strokeOpacity,
            }}
          />
        )}
        {textPosition === 'middle' && (
          <path
            d="M 0 0 L 10 5 L 0 10 z"
            strokeWidth={strokeWidth}
            fill={strokeColor}
            transform={`translate(${lineSize - 10}, 0)`}
            style={{
              opacity: strokeOpacity,
              transform: 'translate(10px, 0) rotate(180deg)',
              transformOrigin: '0px',
            }}
          />
        )}
      </svg>
    </Container>
  )
}
