import { useQuery, useMutation } from '@tanstack/react-query'

import {
  createDocument,
  listDocuments,
  getDocument,
  deleteDocument,
  Document,
  DocumentTypeChoices,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import DOCUMENT_QUERY_KEYS from './queryKeys'
import PROJECT_QUERY_KEYS from '../projects/queryKeys'

type mutationDocumentProps = {
  onSuccess?: (response?: { document: Document; projectId: string }) => void
}

export const useCreateDocument = (props?: mutationDocumentProps) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT],
    mutationFn: async ({
      projectId,
      values,
    }: {
      projectId: string
      values: {
        name: string
        cad_id: string
        document_type: DocumentTypeChoices
      }
    }) => {
      const document = await createDocument(projectId, values)

      return {
        document,
        projectId,
      }
    },
    onSuccess: ({ document, projectId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
      })
      queryClient.setQueryData(
        [DOCUMENT_QUERY_KEYS.DOCUMENT, { documentId: document?.id }],
        document,
      )
      if (props?.onSuccess)
        props.onSuccess({
          document,
          projectId,
        })
    },
  })
}

export const useDeleteDocument = (props?: mutationDocumentProps) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_DOCUMENT],
    mutationFn: async ({
      documentId,
      projectId,
    }: {
      documentId: string
      projectId: string
    }) => {
      await deleteDocument(documentId)

      return {
        documentId,
        projectId,
      }
    },
    onSuccess: ({ documentId, projectId }) => {
      queryClient.setQueryData(
        [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
        (documents: Array<Document> | undefined) =>
          documents?.filter((document) => document.id !== documentId),
      )

      queryClient.invalidateQueries({
        queryKey: [PROJECT_QUERY_KEYS.PROJECT, { projectId }],
      })

      queryClient.invalidateQueries({
        queryKey: [PROJECT_QUERY_KEYS.PROJECTS],
      })

      queryClient.removeQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT, { documentId }],
      })
      if (props?.onSuccess) props.onSuccess()
    },
  })
}

export const useListDocuments = ({ projectId }: { projectId: string }) => {
  return useQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
    queryFn: () => listDocuments(projectId),
  })
}

export const listDocumentsQuery = ({ projectId }: { projectId: string }) => {
  return queryClient.fetchQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
    queryFn: () => listDocuments(projectId),
  })
}

export const useDocument = ({ documentId }: { documentId: string }) => {
  return useQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT, { documentId }],
    queryFn: () => getDocument(documentId),
  })
}

export { DOCUMENT_QUERY_KEYS as QUERY_KEYS, MUTATION_KEYS }
