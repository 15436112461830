import Emoji from '@tiptap-pro/extension-emoji'

export const Icons = Emoji.extend({
  addCommands() {
    return {
      addIcon:
        (icon, color) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              name: icon,
              color: color,
            },
          })
        },
    } as any
  },
})
