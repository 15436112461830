import SortableList from 'react-sortable-dnd-list'
import { StepCard } from '../StepCard'

import { useToggleStepCardsList } from './hooks/useToggleStepCardsList'

import { CARD_PLACEHOLDER } from './constants'

import type { Step } from '@/services/queries/operation_steps/types'
import type { StepCardItem } from './types'

export const StepCardList = ({
  documentVersionId,
  steps,
  isReadOnly,
}: {
  documentVersionId: string
  steps: Step[]
  isReadOnly?: boolean
}) => {
  const toggleStepCardsList = useToggleStepCardsList()

  const stepsKey = steps.length === 0 ? 'steps-list-0' : 'steps-list-ready'

  const items = [CARD_PLACEHOLDER, ...steps]

  return (
    <SortableList
      key={stepsKey}
      className="flex flex-col space-y-2 h-full overflow-y-auto"
      animationDuration={0}
      itemComponent={StepCardItemContainer}
      itemComponentProps={{ isReadOnly }}
      value={items}
      onChange={(rawReorderedSteps: StepCardItem[]) => {
        toggleStepCardsList({
          steps: rawReorderedSteps,
          previousSteps: steps,
          documentVersionId,
          isReadOnly: Boolean(isReadOnly),
        })
      }}
    />
  )
}

export const StepCardItemContainer = ({
  dragging,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dragged,
  children: step,
  isReadOnly,
  ...rest
}: {
  dragging: boolean
  dragged: boolean
  isReadOnly?: boolean
  children: StepCardItem
}) => {
  return (
    <div {...rest} className={`${dragging ? 'is-dragging' : ''}`}>
      {step === CARD_PLACEHOLDER ? null : (
        <StepCard
          stepNumber={step.order_number as number}
          step={step}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  )
}
