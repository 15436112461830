import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { usePDFifyStore } from '@/components/pdf/state'

import { toPng } from 'html-to-image'

export const usePDFDownloadTrigger = () => {
  const pageRefs = usePDFifyStore((state) => state.pageRefs)
  const setImageUrls = usePDFifyStore(useShallow((state) => state.setImageUrls))
  const setIsGenerating = usePDFifyStore(
    useShallow((state) => state.setIsGenerating),
  )

  return useCallback(async () => {
    setImageUrls([])
    setIsGenerating(true)

    const pageEls = Object.values(pageRefs)
      .sort((a, b) => a.order - b.order)
      .map((page) => page.ref)

    const imgUrls: string[] = []

    for (const page of pageEls) {
      const blob = await toPng(page, {
        quality: 1,
        pixelRatio: 2,
        backgroundColor: 'white',
      })

      if (!blob) {
        throw new Error(
          'Failed to generate PDF: failed to convert page to image',
        )
      }

      imgUrls.push(blob)
    }

    setImageUrls(imgUrls)
    setIsGenerating(false)
  }, [pageRefs, setImageUrls, setIsGenerating])
}
