export enum MenuIconSizes {
  default = 'w-5 h-5',
  small = 'w-2 h-2',
  medium = 'w-4 h-4',
  large = 'w-6 h-6',
}

export enum AnnotationSizes {
  default = 'w-7 h-7',
  small = 'w-6 h-6',
  medium = 'w-20 h-20',
  large = 'w-36 h-36',
}

export enum IconStrokes {
  default = 2,
  small = 3,
  medium = 1.25,
  large = 0.75,
}
