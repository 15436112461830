const getMimeType = (file: File): string => {
  const supportedMimeTypes = new Set([
    'image/png',
    'image/jpeg',
    'image/gif',
    'application/gzip',
  ])
  if (supportedMimeTypes.has(file.type)) {
    return file.type
  }

  const stepExtensions = new Set(['step', 'stp'])
  const toks = file.name.split('.').pop()
  const ext = toks ? toks.toLowerCase() : ''
  if (stepExtensions.has(ext)) {
    return 'model/step'
  }

  return 'application/octet-stream'
}

/**
 * Upload a file to the server
 * @param uploadUrl
 * @param file
 * @returns
 */
export const uploadFile = async (uploadUrl: string, file: File) => {
  return fetch(uploadUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': getMimeType(file),
    },
  })
}
