import { Object3D } from 'three'
import { ThreeEvent } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'

import { HIGHLIGHT_BLUE, HIGHLIGHT_GREEN } from '@/constants'
import { useCADPageStore } from '../state'
import { Step } from '@/services/queries/operation_steps/types'
import { useCADQuery } from '@/services/queries/cads'
import { useAssemblyTree } from '@/state'
import { useAutoAddPartToStep } from '../components/AssemblyOrderPanel/StepCardList/hooks/useAutoAddPartToStep'
import { useExplosionsControls } from '../components/CADModel/hooks/useExplosionsControls'

export type SelectionEvent =
  | React.MouseEvent<HTMLSpanElement, MouseEvent>
  | ThreeEvent<PointerEvent>

interface SelectableOptions {
  onRangeSelect?: (uuids: string[]) => void
  onMultiSelect?: (uuids: string[]) => void
  onSelect?: (
    uuid: string,
    options?: { object?: Object3D; step?: Step },
  ) => void
  onReset?: (uuid: string, object?: Object3D) => void
  onRangeDeselect?: (uuid: string, object?: Object3D) => void
}

export const useSelectable = ({
  onSelect,
  onReset,
  onRangeSelect,
  onRangeDeselect,
  onMultiSelect,
}: SelectableOptions) => {
  const { isLoading: isLoadingCAD, data } = useCADQuery()
  const gltf = data?.gltf

  const getTree = useAssemblyTree(useShallow((state) => state.getAssemblyTree))
  const getNode = useAssemblyTree(useShallow((state) => state.getNode))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const selected = useCADPageStore((state) => state.selectedParts)
  const setState = useCADPageStore(useShallow((state) => state.setState))
  const pushSelectedPart = useCADPageStore(
    useShallow((state) => state.pushSelectedPart),
  )

  const autoAddPartToStep = useAutoAddPartToStep()
  const handleExplosions = useExplosionsControls()

  return {
    size: selected.length,
    selected,
    select: (uuid: string) => pushSelectedPart(uuid),
    reset: () => setState({ selectedParts: [] }),
    isSelected: (uuid: string) => selected.includes(uuid),
    handlers: (uuid: string, options?: { object?: Object3D; step?: Step }) => ({
      onPointerDown: (e: SelectionEvent) => {
        e.stopPropagation()

        const rightClick = e.button === 2
        const commandKey = e.metaKey || e.ctrlKey || e.shiftKey

        const selectFromCAD = getCadPageState().operationStep?.selectFromCad
        const { explosionsToolbar } = getCadPageState()

        if (commandKey || selectFromCAD) {
          if (selected.includes(uuid)) {
            setState({ selectedParts: selected.filter((id) => id !== uuid) })
            if (typeof onRangeDeselect === 'function') {
              onRangeDeselect(uuid, options?.object)
            }
          } else {
            if (selected.length === 1 && typeof onRangeSelect === 'function') {
              onRangeSelect([...new Set([...selected, uuid])])
            }
            if (selected.length > 1 && typeof onMultiSelect === 'function') {
              onMultiSelect([...new Set([...selected, uuid])])
            }
            pushSelectedPart(uuid)
          }

          if (selectFromCAD) {
            autoAddPartToStep(uuid)
          }

          if (explosionsToolbar) {
            handleExplosions()
          }
          return
        }

        if (selected.length === 1 && selected[0] === uuid && !rightClick) {
          setState({ selectedParts: [] })
          if (typeof onReset === 'function') {
            onReset(uuid, options?.object)
          }

          if (explosionsToolbar) {
            handleExplosions()
          }
          return
        }

        if (!rightClick) {
          setState({ selectedParts: [uuid] })
          if (typeof onSelect === 'function') {
            onSelect(uuid, options)
          }

          if (explosionsToolbar) {
            handleExplosions()
          }
          return
        }

        if (rightClick && selected.length === 0) {
          setState({ selectedParts: [uuid] })
          if (typeof onSelect === 'function') {
            onSelect(uuid, options)
          }
          return
        }
      },
    }),
    upAndOutHandlers: () => ({
      onPointerOut: (e: SelectionEvent) => {
        e.stopPropagation()
        const { isRotating, isDragging, colorMap, operationStep } =
          getCadPageState()
        const tree = getTree()

        if (isLoadingCAD || isRotating || isDragging || !gltf || !tree) return

        gltf.unhighlightParts(colorMap)

        document.body.style.cursor = 'default'

        setCadPageState({ highlightedPartUUID: null })

        if (selected.length > 0) {
          const tree = getTree()
          selected.forEach((uuid) => {
            const highlightColor = operationStep?.selectFromCad
              ? HIGHLIGHT_GREEN
              : HIGHLIGHT_BLUE

            const node = getNode(uuid)
            if (tree && node) {
              gltf.highlightPart(tree, node.instance, highlightColor)
            }
          })
        }
      },
    }),
  }
}
