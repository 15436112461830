import { useMutation } from '@tanstack/react-query'
import { Editor as TipTapEditor } from '@tiptap/react'
import { useUpdateTemplateElement } from '@/services/hooks/template_attributes'
import { useDocPageNote } from './useDocPageNote'

export const useEditDocPageNote = ({
  documentPageId,
  notesId,
}: {
  documentPageId: string
  notesId?: string
}) => {
  const { mutateAsync: updateTemplate, isPending: isUpdatingTemplate } =
    useUpdateTemplateElement({
      documentPageId,
    })

  const notesTemplate = useDocPageNote({
    documentPageId,
    notesId,
  })

  return useMutation({
    mutationFn: async ({
      editor,
      notesPosition,
    }: {
      editor: TipTapEditor
      notesPosition: 'relative' | 'absolute'
    }) => {
      const html = editor.getHTML()

      const hasChanged = notesTemplate?.template_values?.content !== html
      const isSlashCommandsActive =
        editor.extensionStorage?.slashCommand?.isActive
      const shouldUpdate =
        hasChanged && !isSlashCommandsActive && !isUpdatingTemplate

      if (shouldUpdate) {
        await updateTemplate({
          payload: {
            content: editor.getHTML(),
            notesPosition,
          },
          data_type: 'note',
          templateAttributeId: notesTemplate?.id as string,
        })
      }

      return {}
    },
  })
}
