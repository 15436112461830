import { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'
import { useToast } from '@/components/ui/use-toast'
import clone from 'lodash.clone'

import {
  cadVersionUploaded,
  uploadFile,
  updateCadVersion,
  listDocumentPages,
  listDocumentVersions,
  getDocument,
  getCad,
  View,
} from '@/lib/api/client'
import { getProjectQuery } from '@/services/queries/projects'
import { getCadVersionQuery } from '@/services/queries/cad_versions'
import {
  listCadVersionsQuery,
  getLatestCadVersion,
} from '@/services/queries/cad_versions'
import { QUERY_KEYS as DOCUMENTS_QUERY_KEYS } from '@/services/queries/documents'
import { getViewQuery, listPageViewsQuery } from '@/services/queries/views'
import { useAssemblyTree, RawAssemblyTree } from '@/state'
import { compressFile } from '@/lib/gzip'

import { useDocumentPageParams } from './hooks'

export const useDocumentPageQuery = () => {
  const { projectId, documentId, cv, dv } = useDocumentPageParams()
  const setAssemblyTree = useAssemblyTree(
    useShallow((state) => state.setAssemblyTree),
  )

  const query = useQuery({
    staleTime: 0,
    refetchOnMount: true,
    queryKey: [
      DOCUMENTS_QUERY_KEYS.DOCUMENT_PAGE,
      {
        projectId,
        documentId,
        cadVersion: cv,
        documentVersion: dv,
      },
    ],
    queryFn: async () => {
      const project = await getProjectQuery({ projectId })

      const latestDocument = documentId
        ? await getDocument(documentId)
        : undefined

      const latestCAD = latestDocument?.cad
        ? await getCad(latestDocument.cad)
        : undefined

      const documentVersionsResp = documentId
        ? await listDocumentVersions(documentId)
        : []

      const documentVersions = documentVersionsResp.sort(
        (a, b) => (b.version_number || 0) - (a.version_number || 0),
      )

      const latestDocumentVersion =
        documentVersions.length > 0 ? documentVersions[0] : undefined
      const docVersion = dv
        ? documentVersions.find((v) => v.id === dv) || latestDocumentVersion
        : latestDocumentVersion

      const cleanDocumentVersion = clone(docVersion)

      if (docVersion) {
        const documentVersionIndex = documentVersions.findIndex(
          (v) => v.id === docVersion?.id,
        )

        let versionName
        if (documentVersionIndex === 0) {
          versionName = 'Current'
        } else {
          const prevVersion = documentVersions[documentVersionIndex - 1]
          versionName = prevVersion.name || `V${prevVersion.version_number}`
        }
        cleanDocumentVersion.name = versionName
      }

      const cadVersions = latestCAD?.id
        ? await listCadVersionsQuery({ cadId: latestCAD?.id })
        : []

      const latestCadVersion = docVersion?.cad_version
        ? await getCadVersionQuery({
            cadVersionId: docVersion.cad_version,
          })
        : await getLatestCadVersion({
            cadId: latestCAD?.id,
          })

      const documentPages = docVersion
        ? await listDocumentPages(docVersion.id as string)
        : []
      const views = await listPageViewsQuery({
        cadVersionId: latestCadVersion?.id as string,
        documentVersionId: docVersion?.id as string,
      })

      const isLatestCadVersion = !cv || cv === latestCadVersion?.id
      const isLatestDocumentVersion =
        latestDocumentVersion && (!dv || dv === latestDocumentVersion.id)

      const rawAssemblyTree = latestCadVersion?.assembly_tree as
        | RawAssemblyTree
        | undefined

      return {
        documentId: docVersion?.document,
        documentPages: documentPages,
        documentVersions,
        documentVersion: cleanDocumentVersion,
        documentType: latestDocument?.document_type,
        doc: latestDocument,
        views,
        cadVersions,
        project,
        rawAssemblyTree,
        version: latestCadVersion,
        isLatestCadVersion,
        isLatestDocumentVersion,
      }
    },
  })

  useEffect(() => {
    if (query.data?.rawAssemblyTree && query.data?.documentPages) {
      setAssemblyTree(query.data.rawAssemblyTree, query.data.documentPages)
    }
  }, [query.data?.rawAssemblyTree, query.data?.documentPages, setAssemblyTree])

  return query
}

export const useDocumentViewsQuery = (viewIds: string[]) => {
  return useQuery({
    queryKey: [
      DOCUMENTS_QUERY_KEYS.DOCUMENT_VIEW,
      { viewIds: viewIds.join(',') },
    ],
    queryFn: async () => {
      const views: View[] = []
      for (const viewId of viewIds) {
        const view = await getViewQuery({ viewId })
        views.push(view)
      }
      return { views }
    },
    enabled: Array.isArray(viewIds),
  })
}

export const useReuploadCadMutation = () => {
  const { toast } = useToast()
  const getAssemblyTree = useAssemblyTree((state) => state.getAssemblyTree)

  return useMutation({
    mutationFn: async ({
      projectId,
      documentId,
      cadVersionId,
      file,
    }: {
      projectId: string
      documentId: string
      cadVersionId: string
      file: File
    }) => {
      const cadVersion = await getCadVersionQuery({ cadVersionId })
      const uploadUrl = cadVersion.upload_url
      if (!uploadUrl) {
        throw new Error('Failed to get upload URL')
      }

      const assemblyTree = getAssemblyTree() || {}
      if (Object.keys(assemblyTree).length === 0) {
        throw new Error('No assembly tree found')
      }

      const uploadedUrlObj = new URL(uploadUrl)
      const usesFileCompression = uploadedUrlObj.pathname.endsWith('.gz')

      const updatedFile = usesFileCompression ? await compressFile(file) : file

      await updateCadVersion(cadVersionId, {
        assembly_tree: assemblyTree as RawAssemblyTree,
        filename: updatedFile.name,
      })
      await uploadFile(uploadUrl, updatedFile)
      await cadVersionUploaded(cadVersionId as string)

      return { projectId, cadVersionId, documentId }
    },
    onSuccess: ({ projectId, documentId }) => {
      window.location.href = `/p/${projectId}/document/${documentId}/cad`
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}
