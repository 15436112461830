import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { DocumentPage } from '@/lib/api/client'
import { useDocumentState } from '@/state'
import { ColumnDef } from '@tanstack/react-table'
import {
  TemplateAttribute,
  Table,
  useDeleteTable,
  useUpdateTable,
} from '@/services/hooks/template_attributes'
import { EditableTable, createNewColumn } from './EditableTable'

type TableData = {
  [accessor: string]: any
}
type GenericTableProps = {
  isReadOnly?: boolean
  documentPage: DocumentPage
  tableId: string
  tableTemplate: TemplateAttribute<Table>
}
export const GenericTable = ({
  isReadOnly = false,
  documentPage,
  tableId,
  tableTemplate,
}: GenericTableProps) => {
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPage.id &&
      selectedElement?.id === tableId,
    [selectedElement, documentPage.id, tableId],
  )

  const tableType = useMemo(() => 'generic-table', [])

  const selectTable = useCallback(() => {
    setSelectedElement({
      documentPageId: documentPage.id as string,
      id: tableId,
      isRemovable: false,
    })
  }, [documentPage.id, tableId, setSelectedElement])

  const initialTableData = useMemo(
    () => tableTemplate?.template_values.rows,
    [tableTemplate?.template_values.rows],
  )
  const initialColumns = useMemo(
    () => tableTemplate?.template_values.columns,
    [tableTemplate?.template_values.columns],
  )
  const tablePosition = useMemo(
    () => tableTemplate?.template_values.position,
    [tableTemplate?.template_values.position],
  )
  const columnsSize = useMemo(
    () => tableTemplate?.template_values.columnsSize,
    [tableTemplate?.template_values.columnsSize],
  )

  const [tableData, setTableData] = useState<Array<TableData>>([])
  const [columnsDef, setColumnsDef] = useState<Array<ColumnDef<TableData>>>([])
  const [columnsData, setColumnsData] = useState<TableData>({})

  const { mutateAsync: updateTable } = useUpdateTable()
  const { mutateAsync: deleteTable, isPending: isDeletingTable } =
    useDeleteTable()

  const isDeleting = useMemo(() => isDeletingTable, [isDeletingTable])

  const deleteTableHandler = useCallback(async () => {
    await deleteTable(tableId)
  }, [deleteTable, tableId])

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteTableHandler()
      }
    },
    [deleteTableHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  useEffect(() => {
    if (initialColumns) {
      setColumnsData((prev) => ({
        ...prev,
        ...initialColumns.reduce(
          (accum, column) => ({
            ...accum,
            ...column,
          }),
          {},
        ),
      }))

      initialColumns.forEach((column) => {
        Object.keys(column).forEach((accessorKey) => {
          const columnDef = createNewColumn(
            accessorKey,
            onCellChange,
            selectTable,
            isReadOnly,
            documentPage.id as string,
          )
          setColumnsDef((prev) => {
            return [...prev, columnDef]
          })
        })
      })
    }

    return () => {
      setColumnsDef([])
    }
  }, [initialColumns])

  useEffect(() => {
    if (initialTableData) {
      setTableData((prev) => [
        ...prev.map((row, i) => ({
          ...row,
          ...(initialTableData[i] || {}),
        })),
        ...initialTableData,
      ])
    }

    return () => {
      setTableData([])
    }
  }, [initialTableData])

  const onCellChange = useCallback(
    (rowIndex, columnId, value) => {
      setTableData((prev) =>
        prev.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row,
        ),
      )
    },
    [setTableData],
  )

  const onHeaderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, accessorKey: string) => {
      const target = event.target
      const value = target.value

      setColumnsData((prev) => ({
        ...prev,
        [accessorKey]: value,
      }))
    },
    [],
  )

  const saveTableData = useCallback(() => {
    const cleanColumns = columnsDef.reduce((accum, colDef: any) => {
      const columnAccessor = Object.keys(columnsData).find(
        (accessorKey) => accessorKey === colDef.accessorKey,
      )

      if (!columnAccessor) return accum

      const column = columnsData[columnAccessor]

      return [
        ...accum,
        {
          [columnAccessor]: column,
        },
      ]
    }, [] as Array<TableData>)
    updateTable({
      documentPageId: documentPage.id as string,
      templateAttributeId: tableId,
      tableData: {
        type: tableType,
        columns: cleanColumns,
        rows: tableData,
      },
    })
  }, [
    documentPage.id,
    tableId,
    tableData,
    columnsData,
    updateTable,
    tableType,
    columnsDef,
  ])

  const addNewColumn = useCallback(
    ({
      prevAccessorKey,
      newColumn,
    }: {
      prevAccessorKey: string
      newColumn: ColumnDef<any>
    }) => {
      const accessorKey = (newColumn as { accessorKey: string }).accessorKey
      const columnBefore =
        columnsDef.findIndex(
          (column) => (column as any).accessorKey === prevAccessorKey,
        ) || columnsDef.length - 1

      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore + 1),
        newColumn as ColumnDef<TableData>,
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.map((row) => ({
          ...row,
          [accessorKey]: '',
        })),
      )
    },
    [columnsDef],
  )

  const removeColumn = useCallback(
    (accessorKey: string) => {
      if (columnsDef.length === 1) {
        deleteTableHandler()
        return
      }

      const columnBefore = columnsDef.findIndex(
        (column) => (column as any).accessorKey === accessorKey,
      )

      if (columnBefore === -1) return
      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore),
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.filter((row) => Object.keys(row).includes(accessorKey)),
      )
      setColumnsData((prev) => {
        const newColumns = { ...prev }
        delete newColumns[accessorKey]
        return newColumns
      })

      const filteredRows = tableData
        .map((row) => {
          return Object.keys(row).reduce((acc, currAccessorKey) => {
            if ((currAccessorKey as string) !== accessorKey) {
              acc[currAccessorKey] = row[currAccessorKey]
            }

            return acc
          }, {})
        })
        .filter((row) => Object.keys(row).length)

      const filteredColumns = columnsDef
        .filter(
          (column) =>
            ((column as any).accessorKey as string).includes('custom-column') &&
            ((column as any).accessorKey as string) !== accessorKey,
        )
        .map((column) => {
          const accessorKey = (column as any).accessorKey
          return { [accessorKey]: columnsData[accessorKey] }
        })

      updateTable({
        documentPageId: documentPage.id as string,
        templateAttributeId: tableId,
        tableData: {
          type: tableType,
          columns: filteredColumns,
          rows: filteredRows,
        },
      })
    },
    [
      tableType,
      columnsDef,
      columnsData,
      tableData,
      updateTable,
      tableId,
      documentPage.id,
    ],
  )

  const removeRow = useCallback(
    (rowIndex) => {
      const filteredRows = tableData.filter((_, index) => index !== rowIndex)
      setTableData((prev) => prev.filter((_, index) => index !== rowIndex))

      updateTable({
        documentPageId: documentPage.id as string,
        templateAttributeId: tableId,
        tableData: {
          type: tableType,
          rows: filteredRows,
        },
      })
    },
    [tableData, updateTable, tableId, documentPage.id, tableType],
  )

  const addNewRow = useCallback((newRow: any) => {
    setTableData((prev) => [...prev, newRow])
  }, [])

  useEffect(() => {
    if (columnsSize) {
      setColumnsDef((prevColumns) =>
        prevColumns.map((column) => {
          const accessor = (column as any).accessorKey
          const size = (columnsSize as any)[accessor]
          if (size) {
            return {
              ...column,
              size,
            }
          }
          return column
        }),
      )
    }
  }, [columnsSize])

  return (
    <EditableTable
      saveTableData={saveTableData}
      onCellChange={onCellChange}
      removeRow={removeRow}
      addRow={addNewRow}
      onHeaderChange={onHeaderChange}
      saveCustomColumns={saveTableData}
      removeColumn={removeColumn}
      addColumn={addNewColumn}
      documentPageId={documentPage?.id as string}
      tableData={tableData}
      defaultRows={0}
      defaultColumns={0}
      columnsDef={columnsDef as any}
      columnsData={columnsData}
      positionData={tablePosition}
      columnsSize={columnsSize || {}}
      templateId={tableTemplate?.id as string}
      isDeleting={isDeleting}
      isReadOnly={isReadOnly}
      id={tableId}
      type={tableType}
      maxRows={10}
      maxColumns={5}
    />
  )
}

export default GenericTable
