import { useEffect } from 'react'
import { useLocation } from 'wouter'
import { useShallow } from 'zustand/react/shallow'
import queryClient from '@/queryClient'
import { QUERY_KEYS as CAD_QUERY_KEYS } from '@/services/queries/cads'
import { useCADPageStore } from '@/pages/CADPage/state'

const useCADGC = () => {
  const [location] = useLocation()
  const resetCadPageStore = useCADPageStore(useShallow((state) => state.reset))

  useEffect(() => {
    const isCadPage = location.match(
      /^\/p\/[0-9a-fA-F-]+\/document\/[0-9a-fA-F-]+\/cad$/,
    )
    const isDocumentPage = location.match(
      /^\/p\/[0-9a-fA-F-]+\/document\/[0-9a-fA-F-]+\/edit$/,
    )
    const shouldGC = !isCadPage && !isDocumentPage
    if (shouldGC) {
      queryClient.removeQueries({
        queryKey: [CAD_QUERY_KEYS.GLTF],
      })
      resetCadPageStore()
    }
  }, [location, resetCadPageStore])
}

export const GarbageCollector = () => {
  useCADGC()

  return null
}
