import { cn } from '@/utils'
import {
  createContext,
  useContext,
  useState,
  RefAttributes,
  useEffect,
} from 'react'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { TriangleRightIcon, TriangleDownIcon } from '@radix-ui/react-icons'

const CollapsibleContext = createContext<{
  open: boolean
  setOpen?: (isOpen: boolean) => void
}>({ open: false })

const useCollapsibleContext = () => useContext(CollapsibleContext)

const Collapsible = (
  props: CollapsiblePrimitive.CollapsibleProps &
    RefAttributes<HTMLDivElement> & {
      onOpenChange?: (isOpen: boolean) => void
    },
) => {
  const [open, setOpen] = useState(props.defaultOpen ?? false)

  useEffect(() => {
    if (props.onOpenChange) props.onOpenChange(open)
  }, [open, props.onOpenChange])

  return (
    <CollapsibleContext.Provider value={{ open, setOpen }}>
      <CollapsiblePrimitive.Root {...props} open={open} />
    </CollapsibleContext.Provider>
  )
}

const CollapsibleTrigger = (
  props: CollapsiblePrimitive.CollapsibleTriggerProps &
    RefAttributes<HTMLButtonElement>,
) => {
  const { open, setOpen } = useCollapsibleContext()
  return (
    <CollapsiblePrimitive.CollapsibleTrigger
      asChild
      {...props}
      onMouseDown={(e) => {
        e.stopPropagation()
        if (props.onMouseDown) props.onMouseDown(e)
        if (setOpen) setOpen(!open)
      }}
    />
  )
}

const CollapsibleTriangleIcon = ({ className }: { className?: string }) => {
  const { open } = useCollapsibleContext()
  const TriangleIcon = open ? TriangleDownIcon : TriangleRightIcon
  return <TriangleIcon className={cn('w-5 h-5 text-gray-600', className)} />
}

const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent

export {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleTriangleIcon,
  CollapsibleContent,
}
