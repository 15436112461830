import { useMemo } from 'react'
import { cn } from '@/utils'
import { useShallow } from 'zustand/react/shallow'
import { useCADPageStore } from '../../state'
import {
  useDeleteOperationStep,
  useDeletePartFromOperation,
} from '@/services/queries/operation_steps'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

import { Skeleton } from '@/components/ui/skeleton'
import { OpenDocumentPageButton } from './OpenDocumentPageButton'
import { SecondaryActionsButton } from './SecondaryActionsButton'
import { StepCardScreenshotsButton } from './StepCardScreenshotsButton'
import { AddPartFromCADButton } from './AddPartFromCADButton'
import { StepPart } from './StepPart'
import { StepCardDropZone } from './StepCardDropZone'
import { StepCardContainer } from './StepCardContainer'
import { StepCardNote } from './StepCardNote'

import type { Step } from '@/services/queries/operation_steps/types'
import { useSelectable } from '../../hooks'

export const StepCard = ({
  stepNumber,
  step,
  isReadOnly,
}: {
  stepNumber: number
  step: Step
  isReadOnly?: boolean
}) => {
  const { isLoading: isCadLoading, data: cadData } = useCADQuery()
  const { isLoading: isDocumentLoading, data: docData } = useDocumentPageQuery()
  const { mutate: deletePartFromOperation } = useDeletePartFromOperation()
  const { mutate: deleteOperationStep } = useDeleteOperationStep()
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const gltf = cadData.gltf
  const assemblyTree = cadData.rawAssemblyTree || { root: '', nodes: [] }
  const assemblyGroupIds = step.assembly_group_ids

  const isProjectTracker = useMemo(
    () => docData?.documentType === 'project_tracker',
    [docData?.documentType],
  )

  const isWorkInstructions = useMemo(
    () => docData?.documentType === 'work_instructions',
    [docData?.documentType],
  )

  const selectable = useSelectable({})
  const activeSelectFromCADStep =
    activeOperationStep?.stepId === step.id &&
    activeOperationStep?.selectFromCad
  const parts =
    assemblyTree?.nodes?.filter((node) =>
      assemblyGroupIds.includes(node.uuid),
    ) || []

  const isLoading = isCadLoading || isDocumentLoading

  if (isLoading) {
    return (
      <div className="border border-gray-300 rounded-lg p-3 bg-white text-sm">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )
  }

  return (
    <StepCardContainer step={step} gltf={gltf}>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center space-x-2">
          <div
            className={cn(
              'bg-primary-10 text-primary-50 rounded-lg px-2 py-1 inline-block',
              {
                'bg-teal-600 text-white': activeSelectFromCADStep,
              },
            )}
          >
            {isProjectTracker ? 'Note' : 'Step'} {stepNumber}
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="mr-1">
            <StepCardScreenshotsButton documentPageId={step.id as string} />
          </div>
          <OpenDocumentPageButton documentPageId={step.id as string} />
          {isWorkInstructions && !isReadOnly && (
            <AddPartFromCADButton step={step} />
          )}
          {!isReadOnly && (
            <SecondaryActionsButton
              onDelete={() => {
                const isActive = activeOperationStep?.stepId === step.id
                if (isActive) {
                  setCadPageState({
                    operationStep: null,
                  })
                }
                deleteOperationStep({ stepId: step.id as string })
              }}
            />
          )}
        </div>
      </div>

      <div className="py-2">
        <StepCardDropZone stepNumber={stepNumber} step={step}>
          {gltf &&
            parts.length > 0 &&
            parts.map((part, i) => {
              return (
                <StepPart
                  key={i}
                  selectFromCad={Boolean(activeOperationStep?.selectFromCad)}
                  assemblyTree={assemblyTree}
                  part={part}
                  gltf={gltf}
                  isReadOnly={isReadOnly}
                  onMouseDown={selectable.handlers(part.uuid).onPointerDown}
                  stepIsActive={activeOperationStep?.stepId === step.id}
                  isSelected={selectable.isSelected(part.uuid)}
                  onDelete={(partUUID) => {
                    const documentPageAssemblyReference =
                      step.documentpageassemblygroupids.find(
                        (id) => id.assembly_group_id === partUUID,
                      )
                    if (documentPageAssemblyReference?.id) {
                      deletePartFromOperation({
                        assemblyGroupId: partUUID,
                        documentPageId: step.id as string,
                        documentVersionId: step.document_version as string,
                        documentPageAssemblyReferenceId:
                          documentPageAssemblyReference.id,
                      })
                    }
                  }}
                />
              )
            })}
          {parts.length === 0 && isWorkInstructions && (
            <div className="flex flex-col space-y-2 text-wrap px-2">
              <div>
                <span className="font-semibold">
                  Add a part to this step to start creating operation steps.
                </span>{' '}
                Each operation step will generate an operation page.
              </div>
              <div>
                <span className="font-semibold">You may add parts by.</span>
              </div>
              <div>
                <ul className="list-disc pl-6 flex flex-col space-y-1">
                  <li>
                    Drag a part from the Assembly Tree and drop to add to a step
                  </li>
                  <li>
                    Select a part from CAD and right click to add to a step
                  </li>
                </ul>
              </div>
            </div>
          )}
        </StepCardDropZone>
        {isProjectTracker && (
          <StepCardNote step={step} isReadOnly={isReadOnly} />
        )}
      </div>
    </StepCardContainer>
  )
}
