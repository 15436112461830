import { useMutation } from '@tanstack/react-query'
import queryClient from '@/queryClient'
import { deleteDocumentPageAssemblyGroupID } from '@/lib/api/client'
import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages/queryKeys'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useCADQuery } from '../cads'
import { QUERY_KEYS as VIEWS_QUERY_KEYS } from '../views'

import type { DocumentPage } from '@/lib/api/client'
import { useUpdateStepPartsFromCAD } from '@/pages/CADPage/components/AssemblyOrderPanel/StepCardList/hooks/useUpdateStepPartsFromCAD'

export const useDeletePartFromOperation = () => {
  const updatePartsFromCAD = useUpdateStepPartsFromCAD()
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const { data: cadData } = useCADQuery()
  const assemblyTree = cadData.rawAssemblyTree
  const gltf = cadData.gltf

  return useMutation({
    mutationFn: async ({
      assemblyGroupId,
      documentPageId,
      documentVersionId,
      documentPageAssemblyReferenceId,
    }: {
      assemblyGroupId: string
      documentPageId: string
      documentVersionId: string
      documentPageAssemblyReferenceId: number
    }) => {
      await deleteDocumentPageAssemblyGroupID(documentPageAssemblyReferenceId)
      return {
        assemblyGroupId,
        documentPageId,
        documentVersionId,
        documentPageAssemblyReferenceId,
      }
    },

    onSuccess: ({ assemblyGroupId, documentPageId }) => {
      const isActive = activeOperationStep?.stepId === documentPageId
      if (isActive && assemblyTree && gltf) {
        const node = assemblyTree.nodes.find(
          (node) => node.uuid === assemblyGroupId,
        )
        if (node) {
          if (activeOperationStep.selectFromCad) {
            updatePartsFromCAD()
          } else {
            gltf.setVisibility(node.instance, true)
          }
        }
      }

      queryClient.invalidateQueries({
        queryKey: [VIEWS_QUERY_KEYS.VIEWS],
        refetchType: 'all',
      })
    },

    onMutate: ({ assemblyGroupId, documentPageId, documentVersionId }) => {
      const queryKey = [
        DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
        { documentVersionId },
      ]

      const previousDocumentPages = (queryClient.getQueryData(queryKey) ||
        []) as DocumentPage[]

      queryClient.setQueryData(queryKey, (old: DocumentPage[]) => {
        if (!old) return []

        return old.map((doc) => {
          if (doc.id === documentPageId) {
            return {
              ...doc,
              documentpageassemblygroupids:
                doc.documentpageassemblygroupids.filter(
                  (id) => id.assembly_group_id !== assemblyGroupId,
                ),
            }
          }
          return doc
        })
      })

      return { previousDocumentPages }
    },

    onError: (error, { documentVersionId }, context) => {
      console.error('Failed to remove part from operation', error)

      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        context?.previousDocumentPages || [],
      )
    },
  })
}
