import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'

import { X_AMZ_DATE_FORMAT } from '@/constants'

dayjs.extend(utc)
dayjs.extend(CustomParseFormat)

export function isUrlExpired(url: string) {
  const urlObj = new URL(url)
  const params = urlObj.searchParams

  const xAmzDate = params.get('X-Amz-Date') || ''
  const xAmzExpires = params.get('X-Amz-Expires') || ''

  if (!xAmzDate || !xAmzExpires) {
    return false
  }

  const expirySeconds = parseInt(xAmzExpires, 10)
  const currentUTC = dayjs.utc()
  const amzDate = dayjs.utc(xAmzDate, X_AMZ_DATE_FORMAT)
  const expirationTime = amzDate.add(expirySeconds, 'second')

  if (!expirationTime.isValid()) {
    console.error('Invalid X-Amz-Date or date format.')
    return false
  }

  if (currentUTC.valueOf() > expirationTime.valueOf()) {
    console.warn('URL expired :', expirationTime.toString())
    return true
  } else {
    return false
  }
}
