import { client } from './client'

/**
 * List all organizations for a user
 * @returns
 */
export const listUserOrganizations = async () => {
  const resp = await client.GET('/api/v1/organizations/mine')

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to list user organizations')
  }

  return resp.data
}
