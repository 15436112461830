export enum QUERY_KEYS {
  DOCUMENT = 'DOCUMENT',
  DOCUMENTS = 'DOCUMENTS',
  DOCUMENTS_BY_CAD_ID = 'DOCUMENTS_BY_CAD_ID',
  PDF_DOCUMENT = 'PDF:DOCUMENT',
  DOCUMENT_PAGE = 'DOCUMENT:PAGE',
  DOCUMENT_VIEW = 'DOCUMENT:VIEW',
}

export default QUERY_KEYS
