import { create } from 'zustand'

interface ProjectsState {
  projectCreatorsFilter: Array<number>
  setProjectCreatorFilter: (projectCreatorFilter: Array<number>) => void
}

export const useProjectsState = create<ProjectsState>((set) => ({
  projectCreatorsFilter: [],
  setProjectCreatorFilter: (projectCreatorsFilter) =>
    set({ projectCreatorsFilter }),
}))
