import { useShallow } from 'zustand/react/shallow'

import { PlusCircledIcon } from '@/components/icons/PlusCircledIcon'
import { CrossCircledIcon } from '@/components/icons/CrossCircledIcon'
import { BookmarkIcon } from '@/components/icons/BookmarkIcon'
import { BookmarkFilledIcon } from '@/components/icons/BookmarkFilledIcon'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { ControlButton } from '../ControlButton'

import { useCADPageStore } from '@/pages/CADPage/state'
import { useCADQuery } from '@/services/queries/cads/queries/useCADQuery'

export const SaveExplosionsButton = () => {
  const { isLoading, data: cadData } = useCADQuery()
  const gltf = cadData.gltf
  const cadId = cadData.cad?.id

  const explosions = useCADPageStore((state) => state.explosions)
  const savedExplosions = useCADPageStore((state) => state.savedExplosions)
  const loadedExplosion = useCADPageStore((state) => state.loadedExplosion)
  const saveNewExplosion = useCADPageStore((state) => state.saveNewExplosion)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))

  if (isLoading || !gltf || !cadId) return null

  return (
    <>
      <DropdownMenuItem>
        <ControlButton
          title="Save Explosion"
          icon={<PlusCircledIcon className="w-4 h-4" />}
          onClick={() => {
            saveNewExplosion(cadId)
          }}
        />
      </DropdownMenuItem>
      {savedExplosions
        .filter((exp) => exp?.cadId === cadId)
        .map((exp, i) => (
          <DropdownMenuItem key={i}>
            <ControlButton
              title={`Load Explosion ${i + 1}`}
              icon={
                exp.id === loadedExplosion ? (
                  <BookmarkFilledIcon className="w-4 h-4" />
                ) : (
                  <BookmarkIcon className="w-4 h-4" />
                )
              }
              onClick={() => {
                gltf.resetExplosions(explosions)
                gltf.explodeParts(exp.explosions)
                setCadPageState({
                  explosions: exp.explosions,
                  loadedExplosion: exp.id,
                })
              }}
            />
          </DropdownMenuItem>
        ))}
      {loadedExplosion && (
        <DropdownMenuItem>
          <ControlButton
            title="Delete Current Explosion"
            icon={<CrossCircledIcon className="w-4 h-4" />}
            disabled={!loadedExplosion}
            onClick={() => {
              const { savedExplosions } = getCadPageState()
              setCadPageState({
                savedExplosions: savedExplosions.filter(
                  (exp) => exp.id !== loadedExplosion,
                ),
                loadedExplosion: '',
              })
            }}
          />
        </DropdownMenuItem>
      )}
    </>
  )
}
